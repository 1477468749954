import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";

import {
  api_direccion_by_cp,
  api_save_os_pm_pm,
} from "../../../../../api/api_servicios_rest";
import {
  fechaActualYYYMMDD,
  pmFechaAltaSHCPVsRfc,
  obtenerFechaShcpByRfc,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
  validarRFCTipoPersona,
} from "../../../../../utils/validaciones";
import {
  TIPO_DEPARTAMENTO_APARTAMENTO,
  TIPO_VIVIENDA_EDIFICIO,
  ID_PAIS_MEXICO,
  ID_PM,
  STATUS_EXITO
} from "../../../../../utils/constantes";
import CheckBoxInput from "../../../../basics/checkBox";
import {
  validateValue,
  textToUpperCase,
  validarTextoConPunto,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../../functions/fieldFunctions";

import { mensajeMostrarUsuario } from "../../../../../utils/mensajesServicios";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorActividad from "../../../../basics/SelectWithError/SelectWithErrorActividad";
import SelectWithErrorTooltipCompuesto from "../../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";
import { sweetAlertError } from "../../../../helpers/alertas/alertas";

const ObligadoSolidarioPM_PM = ({
  modeloPm,
  axiosData,
  anios,
  NUM_OS,
  idTipoPersonaOS,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setObligadosSolidariosInformacionBasica,
  setLoading,
  seccionActual,
  datosOs,
  setDatosOs,
  setUltimoPasoSolicitud,
  webView,
}) => {
  const [coloniasCP, setColoniasCP] = useState([]);
  const [rfcCliente, setRfcCliente] = useState("");
  const [startDateFechaSHCP, setStartDateFechaSHCP] = useState("");

  const formik = useFormik({
    initialValues: initialValues(modeloPm, idTipoPersonaOS, NUM_OS, datosOs),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema(rfcCliente)),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosOSPM_PM(formData);
    },
  });

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "principalAccionista.obligadoSolidarioPM.direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidarioPM.direccion.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidarioPM.direccion.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidarioPM.direccion.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );
          formik.setFieldValue(
            "principalAccionista.obligadoSolidarioPM.direccion.colonia",
            response.data.asentamientos[0]
          );

          let newErrors = formik.errors;
          delete newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
            ?.municipioId;
          delete newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
            ?.estadoId;
          delete newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
            ?.colonia;
          formik.setErrors(newErrors);
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue(
      "principalAccionista.obligadoSolidarioPM.direccion.municipioId",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidarioPM.direccion.municipioObj",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidarioPM.direccion.estadoId",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidarioPM.direccion.estadoObj",
      ""
    );
    formik.setFieldValue(
      "principalAccionista.obligadoSolidarioPM.direccion.colonia",
      ""
    );
  };

  const obtenerFechaShcp = (rfc) => {
    let fechaAltaShcpByRfc = obtenerFechaShcpByRfc(rfc);
    setStartDateFechaSHCP(fechaAltaShcpByRfc);
    formik.setFieldValue(
      "principalAccionista.obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp",
      fechaAltaShcpByRfc.split("-").reverse().join("-")
    );
    let newErrors = formik.errors;
    delete newErrors?.principalAccionista?.obligadoSolidarioPM?.datosDelNegocio
      ?.fechaAltaShcp;
    formik.setErrors(newErrors);
  };

  const salvarDatosOSPM_PM = async (datosOS) => {
    try {
      const response = await api_save_os_pm_pm(datosOS);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setDatosOs({
            ...datosOs,
            datosOS: data.datosOS,
            idTipoPersonaOs: data.obligadoSolidarioPmIdTipoPersona,
          });

          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );
          setUltimoPasoSolicitud(data.solicitudUltimoPaso);
          setStyleHr("block");
          setLoading(false);
          handleSeccionPyme(seccionActual);
        } else {
          setLoading(false);
          const mensajeError = mensajeMostrarUsuario(data.mensaje);
          sweetAlertError(mensajeError);
        }
      } else {
        setLoading(false);
        sweetAlertError(
          "Ocurrió un error al guardar obligado solidario PM de PM"
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (datosOs.datosOS && datosOs.datosOS.length !== 0) {
      formik.setFieldValue(
        "principalAccionista.obligadoSolidarioPM.direccion.tipoDomicilioObj",
        axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoDomicilioId
        )
      );

      formik.setFieldValue(
        "principalAccionista.obligadoSolidarioPM.direccion.tipoViviendaObj",
        axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoViviendaId
        )
      );

      if (datosOs?.datosOS?.generales?.paisResidenciaFiscal2Id === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.generales.paisResidenciaFiscal2Id",
          ""
        );
      }

      if (datosOs?.datosOS?.datosDelNegocio?.idDatosDelNegocio !== null) {
        if (datosOs?.datosOS?.datosDelNegocio?.fechaAltaShcp !== null) {
          setStartDateFechaSHCP(
            datosOs?.datosOS?.datosDelNegocio?.fechaAltaShcp
              .split("-")
              .reverse()
              .join("-")
          );
        }

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.importaExportaObj",
          axiosData?.domicilioFiscal?.importaExportaOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.importaExportaId
          )
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.sectorObj",
          axiosData?.domicilioFiscal?.sectorOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.sectorId
          )
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.giroEmpresarialObj",
          axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.giroEmpresarialId
          )
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.actividadObj",
          axiosData?.domicilioFiscal?.actividadOpciones?.filter(
            (e) => e.id === datosOs.datosOS.datosDelNegocio.actividadId
          )
        );
      } else {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.importaExportaId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.sectorId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.giroEmpresarialId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.actividadId",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.numeroSucursales",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.aniosExperienciaSector",
          ""
        );

        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp",
          ""
        );
      }

      obtenerDireccionByCp(datosOs.datosOS.direccion.codigoPostal);

      setRfcCliente(datosOs.datosOS.generales.rfc);

      if (datosOs.datosOS.direccion?.piso === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.direccion.piso",
          ""
        );
      }

      if (datosOs.datosOS.direccion?.numeroInterior === null) {
        formik.setFieldValue(
          "principalAccionista.obligadoSolidarioPM.direccion.numeroInterior",
          ""
        );
      }
    }
  }, []);

  useEffect(() => {
    if (idTipoPersonaOS !== null) {
      formik.setFieldValue(
        "principalAccionista.obligadoSolidarioPM.generales.tipoPersonaId",
        idTipoPersonaOS
      );
    }
  }, [idTipoPersonaOS]);

  return (
    <>
      <form name="form-os-pm-pm" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3">
            Información general
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <InputWithError
              label="Razón social *"
              name="principalAccionista.obligadoSolidarioPM.generales.razonSocial"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM?.generales?.razonSocial
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM?.generales?.razonSocial
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.generales !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.generales?.razonSocial
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.generales?.razonSocial;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConPunto(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.generales.razonSocial",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.razonSocial
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.razonSocial
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidarioPM.generales.rfc"
              label="RFC *"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.rfc
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.generales?.rfc
                  : ""
              }
              onBlur={(e) => {
                if (e.target.value) {
                  obtenerFechaShcp(e.target.value);
                }
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.generales !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.generales
                    ?.rfc
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.generales?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.generales.rfc",
                    newValue
                  );
                  setRfcCliente(e.target.value);
                }
              }}
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.rfc
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.rfc
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidarioPM.generales.correoElectronico"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.correoElectronico
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.generales?.correoElectronico
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.generales !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.generales
                    ?.correoElectronico
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.generales?.correoElectronico;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.generales.correoElectronico",
                  e.target.value
                );
              }}
              type="email"
              label="Correo electrónico *"
              InputProps={{ maxLength: 50 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.correoElectronico
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.generales?.correoElectronico
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp"
              type="date"
              label="Fecha de alta en SHCP *"
              value={startDateFechaSHCP ? startDateFechaSHCP : ""}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.fechaAltaShcp
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.generales?.fechaAltaShcp;
                  formik.setErrors(newErrors);
                }

                let array = e.target.value.split("-").reverse().join("-");
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.fechaAltaShcp",
                  array
                );
                setStartDateFechaSHCP(e.target.value);
              }}
              InputProps={{ max: fechaActualYYYMMDD() }}
              InputLabelProps={{ shrink: true }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.fechaAltaShcp
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.fechaAltaShcp
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="principalAccionista.obligadoSolidarioPM.generales.esAccionistaOtraEmpresa"
                  state=""
                  value={
                    formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.generales?.esAccionistaOtraEmpresa
                  }
                  checked={
                    formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.generales?.esAccionistaOtraEmpresa
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "principalAccionista.obligadoSolidarioPM.generales.esAccionistaOtraEmpresa",
                      !formik.values?.principalAccionista?.obligadoSolidarioPM
                        ?.generales?.esAccionistaOtraEmpresa
                    )
                  }
                  typeOfCheck="normal"
                  id="obligadoSolidarioOtraEmpresa"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  htmlFor="obligadoSolidarioOtraEmpresa"
                >
                  El obligado es accionista u obligado de otra empresa
                </label>
              </div>
              {formik.values?.principalAccionista?.obligadoSolidarioPM
                ?.generales?.esAccionistaOtraEmpresa && (
                <>
                  <InputWithError
                    name="principalAccionista.obligadoSolidarioPM.generales.nombreOtraEmpresa"
                    label="Nombre de la empresa o PFAE"
                    value={
                      formik.values?.principalAccionista?.obligadoSolidarioPM
                        ?.generales?.nombreOtraEmpresa
                        ? formik.values?.principalAccionista
                            ?.obligadoSolidarioPM?.generales?.nombreOtraEmpresa
                        : ""
                    }
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista?.obligadoSolidarioPM !==
                          undefined &&
                        newErrors?.principalAccionista?.obligadoSolidarioPM
                          ?.generales !== undefined &&
                        newErrors?.principalAccionista?.obligadoSolidarioPM
                          ?.generales?.nombreOtraEmpresa
                      ) {
                        delete newErrors?.principalAccionista
                          ?.obligadoSolidarioPM?.generales?.nombreOtraEmpresa;
                        formik.setErrors(newErrors);
                      }

                      if (validarTextoConEnies(e.target.value, "text")) {
                        formik.setFieldValue(
                          "principalAccionista.obligadoSolidarioPM.generales.nombreOtraEmpresa",
                          e.target.value
                        );
                      }
                    }}
                    InputProps={{ maxLength: 180 }}
                  />
                </>
              )}
              <div
                style={{
                  display: window.innerWidth < 700 && "flex",
                }}
              >
                <CheckBoxInput
                  name="principalAccionista.obligadoSolidarioPM.generales.esObligadoSolidarioOtraEmpresaPfae"
                  state=""
                  value={
                    formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.generales?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  checked={
                    formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.generales?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "principalAccionista.obligadoSolidarioPM.generales.esObligadoSolidarioOtraEmpresaPfae",
                      !formik.values?.principalAccionista?.obligadoSolidarioPM
                        ?.generales?.esObligadoSolidarioOtraEmpresaPfae
                    )
                  }
                  type="checkbox"
                  id="obligadoSolidarioOtraEmpresaPfae"
                  typeOfCheck="normal"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  htmlFor="obligadoSolidarioOtraEmpresaPfae"
                >
                  Es obligado o fiador de otra empresa o PFAE
                </label>
              </div>
              {formik.values?.principalAccionista?.obligadoSolidarioPM
                ?.generales?.esObligadoSolidarioOtraEmpresaPfae && (
                <>
                  <InputWithError
                    name="principalAccionista.obligadoSolidarioPM.generales.nombreOtraEmpresaPfae"
                    label="Nombre de la empresa o PFAE"
                    value={
                      formik.values?.principalAccionista?.obligadoSolidarioPM
                        ?.generales?.nombreOtraEmpresaPfae
                        ? formik.values?.principalAccionista
                            ?.obligadoSolidarioPM?.generales
                            ?.nombreOtraEmpresaPfae
                        : ""
                    }
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.principalAccionista !== undefined &&
                        newErrors?.principalAccionista?.obligadoSolidarioPM !==
                          undefined &&
                        newErrors?.principalAccionista?.obligadoSolidarioPM
                          ?.generales !== undefined &&
                        newErrors?.principalAccionista?.obligadoSolidarioPM
                          ?.generales?.nombreOtraEmpresaPfae
                      ) {
                        delete newErrors?.principalAccionista
                          ?.obligadoSolidarioPM?.generales
                          ?.nombreOtraEmpresaPfae;
                        formik.setErrors(newErrors);
                      }

                      if (validarTextoConEnies(e.target.value, "text")) {
                        formik.setFieldValue(
                          "principalAccionista.obligadoSolidarioPM.generales.nombreOtraEmpresaPfae",
                          e.target.value
                        );
                      }
                    }}
                    InputProps={{ maxLength: 180 }}
                  />
                </>
              )}
            </div>
            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Representante legal
            </h2>
            <InputWithError
              label="Nombre completo *"
              name="principalAccionista.obligadoSolidarioPM.representanteLegal.nombre"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.nombre
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.representanteLegal?.nombre
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal?.nombre
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal?.nombre;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.representanteLegal.nombre",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.nombre
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.nombre
              }
            />
            <InputWithError
              label="Cargo *"
              name="principalAccionista.obligadoSolidarioPM.representanteLegal.cargo"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.cargo
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.representanteLegal?.cargo
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal?.cargo
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal?.cargo;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.representanteLegal.cargo",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.cargo
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.cargo
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidarioPM.representanteLegal.telefono"
              label="Teléfono *"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.telefono
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.representanteLegal?.telefono
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal?.telefono
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal?.telefono;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.principalAccionista?.obligadoSolidarioPM
                    ?.representanteLegal?.telefono
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.representanteLegal.telefono",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.telefono
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.representanteLegal?.telefono
              }
            />
            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Información de domicilio
            </h2>
            <InputWithError
              label="Calle *"
              name="principalAccionista.obligadoSolidarioPM.direccion.calle"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.calle
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.calle
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.calle
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.calle;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.direccion.calle",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 24 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.calle
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.calle
              }
            />
            <InputWithError
              label="Número exterior *"
              name="principalAccionista.obligadoSolidarioPM.direccion.numeroExterior"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.numeroExterior
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.numeroExterior
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.numeroExterior
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.numeroExterior;
                  formik.setErrors(newErrors);
                }

                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.direccion.numeroExterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 7 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.numeroExterior
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.numeroExterior
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidarioPM.direccion.numeroInterior"
              label="Número interior"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.numeroInterior
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.numeroInterior
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.numeroInterior
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.numeroInterior;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.direccion.numeroInterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu “Número exterior” separados por un guion Ejemplo: 318-123B"
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.numeroInterior
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.numeroInterior
              }
              required={
                formik.values?.principalAccionista.obligadoSolidarioPM.direccion
                  .tipoViviendaId === parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values?.principalAccionista.obligadoSolidarioPM.direccion
                  .tipoViviendaId === parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
            />
            <InputWithError
              name="principalAccionista.obligadoSolidarioPM.direccion.piso"
              label="Piso"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.piso
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.piso
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.piso
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.piso;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.direccion.piso",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.piso
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.piso
              }
              required={
                formik.values?.principalAccionista.obligadoSolidarioPM.direccion
                  .tipoViviendaId === parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values?.principalAccionista.obligadoSolidarioPM.direccion
                  .tipoViviendaId === parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
            />
            <InputWithError
              label="CP *"
              name="principalAccionista.obligadoSolidarioPM.direccion.codigoPostal"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.codigoPostal
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.codigoPostal
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.codigoPostal
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.codigoPostal;
                  formik.setErrors(newErrors);
                }

                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.direccion.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              InputProps={{ maxLength: 5 }}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.codigoPostal
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.codigoPostal
              }
            />
            <SelectWithError
              label="Colonia *"
              name="principalAccionista.obligadoSolidarioPM.direccion.colonia"
              value={{
                label: formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.colonia
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.colonia
                  : "",
                value: formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.colonia
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.colonia
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.colonia
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.colonia;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.colonia",
                  e.label
                );
              }}
              options={coloniasCP?.map((e) => {
                return { ...e, label: e, value: e };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.colonia
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.colonia
              }
            />
            <SelectWithError
              placeholder="Alcaldía / Municipio *"
              label="Alcaldía / Municipio *"
              name="principalAccionista.obligadoSolidarioPM.direccion.municipioId"
              isDisabled={true}
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.municipioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.municipioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.municipioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.municipioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.municipioId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.municipioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.municipioId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.municipioObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.municipioId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.municipioId
              }
            />
            <SelectWithError
              placeholder="Estado *"
              label="Estado *"
              name="principalAccionista.obligadoSolidarioPM.direccion.estadoId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.estadoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.estadoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.estadoObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.estadoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.estadoId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.estadoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.estadoId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.estadoObj",
                  [e]
                );
              }}
              isDisabled={true}
              options={axiosData?.generales?.estados?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.estadoId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.estadoId
              }
            />
            <SelectWithError
              label="Tipo de domicilio *"
              name="principalAccionista.obligadoSolidarioPM.direccion.tipoDomicilioId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.tipoDomicilioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.tipoDomicilioId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.tipoDomicilioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.tipoDomicilioId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.tipoDomicilioObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoDomicilioId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoDomicilioId
              }
            />
            <SelectWithError
              label="Tipo de vivienda *"
              name="principalAccionista.obligadoSolidarioPM.direccion.tipoViviendaId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.direccion?.tipoViviendaObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM?.direccion
                    ?.tipoViviendaId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.direccion?.tipoViviendaId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.tipoViviendaId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.direccion.tipoViviendaObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeViviendaOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoViviendaId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.direccion?.tipoViviendaId
              }
            />
            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Información del negocio
            </h2>
            <SelectWithError
              label="¿Realizas actividades de importación o exportación? *"
              name="principalAccionista.obligadoSolidarioPM.datosDelNegocio.importaExportaId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.importaExportaObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.importaExportaId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.importaExportaId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.importaExportaId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.importaExportaObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.importaExportaOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.importaExportaId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.importaExportaId
              }
            />
            <SelectWithErrorTooltipCompuesto
              name="principalAccionista.obligadoSolidarioPM.datosDelNegocio.giroEmpresarialId"
              label="Giro *"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.giroEmpresarialObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.giroEmpresarialId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.giroEmpresarialId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.giroEmpresarialId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.giroEmpresarialObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.giroEmpresarialOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
              tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
              tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.giroEmpresarialId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.giroEmpresarialId
              }
            />
            <SelectWithError
              label="Sector *"
              name="principalAccionista.obligadoSolidarioPM.datosDelNegocio.sectorId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.sectorObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.sectorId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.sectorId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.sectorId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.sectorObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.sectorOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.sectorId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.sectorId
              }
            />
            <SelectWithErrorActividad
              menuPlacement="top"
              label="Actividad *"
              name="principalAccionista.obligadoSolidarioPM.datosDelNegocio.actividadId"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.actividadObj?.[0]?.detalleClave
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.actividadId
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.actividadId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.actividadId",
                  e.id
                );
                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.actividadObj",
                  [e]
                );
              }}
              options={axiosData?.domicilioFiscal?.actividadOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.detalleClave,
                    value: item.detalleClave,
                  };
                }
              )}
              tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.actividadId
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.actividadId
              }
            />
            <InputWithError
              label="Número de sucursales *"
              name="principalAccionista.obligadoSolidarioPM.datosDelNegocio.numeroSucursales"
              value={
                formik.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.numeroSucursales
                  ? formik.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.numeroSucursales
                  : ""
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.numeroSucursales
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.numeroSucursales;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "number")) {
                  formik.setFieldValue(
                    "principalAccionista.obligadoSolidarioPM.datosDelNegocio.numeroSucursales",
                    e.target.value
                  );
                }
              }}
              InputProps={{maxLength: 15}}
              tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.numeroSucursales
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.numeroSucursales
              }
            />
            <SelectWithError
              menuPlacement="top"
              label="Años de experiencia en el sector *"
              name="principalAccionista.obligadoSolidarioPM.datosDelNegocio.aniosExperienciaSector"
              value={{
                label: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.aniosExperienciaSector
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.aniosExperienciaSector
                  : "",
                value: formik?.values?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.aniosExperienciaSector
                  ? formik?.values?.principalAccionista?.obligadoSolidarioPM
                      ?.datosDelNegocio?.aniosExperienciaSector
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.principalAccionista !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM !==
                    undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio !== undefined &&
                  newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.aniosExperienciaSector
                ) {
                  delete newErrors?.principalAccionista?.obligadoSolidarioPM
                    ?.datosDelNegocio?.aniosExperienciaSector;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "principalAccionista.obligadoSolidarioPM.datosDelNegocio.aniosExperienciaSector",
                  e.value
                );
              }}
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
              showErrorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.aniosExperienciaSector
              }
              errorMessage={
                formik.errors?.principalAccionista?.obligadoSolidarioPM
                  ?.datosDelNegocio?.aniosExperienciaSector
              }
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            margin: "1rem 0",
          }}
        >
          <button
            className="button-secondary"
            onClick={(e) => {
              handleRegresarSeccionPyme(seccionActual);
            }}
          >
            Atrás
          </button>

          <button
            className="button-primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            <span>Siguiente</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default ObligadoSolidarioPM_PM;

function initialValues(modeloPm, idTipoPersonaOS, NUM_OS, datosOs) {
  if (datosOs.datosOS == undefined || datosOs.datosOS.length == 0) {
    return {
      idCliente: modeloPm.idCliente,
      idSolicitud: modeloPm.idSolicitud,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        obligadoSolidarioPM: {
          generales: {
            tipoPersonaId: idTipoPersonaOS,
            numero: NUM_OS,
            relacionRamaGobierno: false,
            paisResidenciaFiscal2: false,
            residenciaUsa: false,
            esAccionistaOtraEmpresa: false,
            esObligadoSolidarioOtraEmpresaPfae: false,
            razonSocial: "",
            rfc: "",
            correoElectronico: "",
          },
          representanteLegal: {
            nombre: "",
            cargo: "",
            telefono: "",
          },
          direccion: {
            calle: "",
            numeroExterior: "",
            numeroInterior: "",
            paisId: ID_PAIS_MEXICO,
            piso: "",
            codigoPostal: "",
            colonia: "",
            municipioId: "",
            estadoId: "",
            tipoDomicilioId: "",
            tipoViviendaId: "",
            calleUno: "",
            calleDos: "",
          },
          datosDelNegocio: {
            importaExportaId: "",
            sectorId: "",
            giroEmpresarialId: "",
            actividadId: "",
            aniosExperienciaSector: "",
            numeroSucursales: "",
            fechaAltaShcp: "",
          },
        },
      },
    };
  } else {
    return {
      idCliente: modeloPm.idCliente,
      idSolicitud: modeloPm.idSolicitud,
      principalAccionista: {
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        obligadoSolidarioPM: datosOs.datosOS,
      },
    };
  }
}

function validationSchema(rfcCliente) {
  return {
    principalAccionista: Yup.object().shape({
      obligadoSolidarioPM: Yup.object().shape({
        generales: Yup.object().shape({
          razonSocial: Yup.string().required("Dato requerido."),
          rfc: Yup.string()
            .required("Dato requerido.")
            .test("rfc", "RFC inválido.", (item, testContext) => {
              return testContext.parent.rfc !== undefined
                ? testContext.parent.rfc.trim().length > 0
                  ? validarRFCTipoPersona(testContext.parent.rfc.trim(), ID_PM)
                  : false
                : false;
            }),
          correoElectronico: Yup.string()
            .email("Correo eléctronico inválido")
            .required("Dato requerido."),
        }),
        representanteLegal: Yup.object().shape({
          nombre: Yup.string().required("Dato requerido."),
          cargo: Yup.string().required("Dato requerido."),
          telefono: Yup.string()
            .required("Dato requerido.")
            .test(
              "telefono",
              "Teléfono capturado de forma incorrecta.",
              (item, testContext) => {
                return validarNumeroTelefono(testContext.parent.telefono);
              }
            ),
        }),
        direccion: Yup.object().shape({
          calle: Yup.string().required("Dato requerido."),
          numeroExterior: Yup.string().required("Dato requerido."),
          numeroInterior: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          piso: Yup.string().when("tipoViviendaId", {
            is: TIPO_VIVIENDA_EDIFICIO,
            then: Yup.string().required("Dato requerido."),
            otherwise: Yup.string().when("tipoViviendaId", {
              is: TIPO_DEPARTAMENTO_APARTAMENTO,
              then: Yup.string().required("Dato requerido."),
            }),
          }),
          codigoPostal: Yup.string().required("Dato requerido."),
          colonia: Yup.string().required("Dato requerido."),
          municipioId: Yup.string().required("Dato requerido."),
          estadoId: Yup.string().required("Dato requerido."),
          tipoDomicilioId: Yup.string().required("Dato requerido."),
          tipoViviendaId: Yup.string().required("Dato requerido."),
        }),
        datosDelNegocio: Yup.object().shape({
          importaExportaId: Yup.number().required("Dato requerido."),
          sectorId: Yup.string().required("Dato requerido."),
          giroEmpresarialId: Yup.string().required("Dato requerido."),
          actividadId: Yup.string().required("Dato requerido."),
          aniosExperienciaSector: Yup.string().required("Dato requerido."),
          numeroSucursales: Yup.number()
            .required("Dato requerido.")
            .min(0, "Por favor ingrese un valor mayor igual a 0.")
            .max(9999, "Por favor no ingrese más de 9,999 sucursales."),
          fechaAltaShcp: Yup.string()
            .required("Dato requerido.")
            .test(
              "fechaAltaShcp",
              "La fecha no coincide con el RFC",
              (item, testContext) => {
                return pmFechaAltaSHCPVsRfc(
                  rfcCliente,
                  testContext.parent.fechaAltaShcp
                );
              }
            ),
        }),
      }),
    }),
  };
}
