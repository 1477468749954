import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputDocument from "../../../../basics/InputDocument/InputDocument";
import {
  api_consulta_tradicional_PFAE,
  api_eliminar_documentos,
  api_obtener_documentos_cargados,
  api_uploadDocumento,
} from "../../../../../api/api_servicios_rest";
import {
  TIPO_DOCUMENTO_AUTORIZACION_BURO,
  TIPO_DOCUMENTO_AVISO_DE_PRIVACIDAD_REPRESENTANTE_LEGAL,
  TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL,
  TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_FRENTE,
  TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_REVERSO,
  SECCION_HISTORIAL_SOLICITANTE,
  FORMATOS_PERMITIDOS,
  MENSAJE_FORMATOS_PERMITIDOS,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  TIPO_DOCUMENTO_NOMBRE_AUTORIZACION_BURO,
  STATUS_EXITO
} from "../../../../../utils/constantes";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";
import HistorialCrediticioLinkFiles from "../../HistorialCrediticioLinkFiles";
import { obtenerNombreParticipante } from "../../../../../utils/nombreParticipante";
import {
  sweetAlertDocuments,
  sweetAlertError,
  sweetAlertWarning,
  sweetAlertWithConfirmed,
} from "../../../../helpers/alertas/alertas";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import { obtenerDocumentosCargados } from "./helpers/consultaTradicionalHelper";

const ConsultaTradicionalSolicitanteConyugePFAE = ({
  modeloDatos,
  setLoading,
  idCliente,
  numOS,
  setContadorOS,
  setStyleHr,
  setShowResult,
  setSelectedConsultaTradicionalPFAE,
  selectedConsultaTradicionalPFAE,
  setResultadoEvaluacionRiesgos,
  docPendientesSolicitante,
  redireccionBtnAtrasHistorialPFAE,
  webView,
}) => {
  console.log("MODELO",modeloDatos);
  const [nombreCoyuge, setNombreCoyuge] = useState("");
  const [mostrarDrop, setMostrarDrop] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(idCliente, modeloDatos, numOS),
    validateOnBlur: false,

    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      setLoading(true);
      enviarDocumentosEvaluarCliente(formData)
        .then((r) => setLoading(false))
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    obtenerDocumentosBySolicitud(selectedConsultaTradicionalPFAE);
  }, []);

  useEffect(() => {
    let nombreParticipante;
    nombreParticipante = obtenerNombreParticipante(
      modeloDatos?.conyuge?.generales?.nombre +
        " " +
        modeloDatos?.conyuge?.generales?.apellidoPaterno
    );
    setNombreCoyuge(nombreParticipante);
  }, []);

  const obtenerDocumentosBySolicitud = async (idCliente) => {
    const datosCliente = {
      idCliente: idCliente,
    };

    await obtenerDocumentosCargados(
      datosCliente,
      setLoading,
      setMostrarDrop,
      formik
    );
  };

  const eliminarDocumentos = async (idCliente, nombreMapeo) => {
    setLoading(true);
    const datosDocumento = {
      idCliente: idCliente,
      tipoNombreMapeo: nombreMapeo,
    };
    try {
      const response = await api_eliminar_documentos(datosDocumento);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data?.success && data?.tipoDeDocumento !== false) {
          switch (data?.tipoDeDocumento) {
            case "autorizacionBC":
              delete formik.values.documents.autorizacionBC;
              break;
            case "identificacionOficial":
              delete formik.values.documents.identificacionOficial;
              break;
            case "identificacionOficialReverso":
              delete formik.values.documents.identificacionOficialReverso;
              break;
            case "avisoPrivacidad":
              delete formik.values.documents.avisoPrivacidad;
              break;

            default:
              break;
          }
          setLoading(false);
          obtenerDocumentosBySolicitud(selectedConsultaTradicionalPFAE);
        } else {
          setLoading(false);
          if (data?.success === false) {
            if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
              sweetAlertWithConfirmed(data?.mensaje, webView);
            } else {
              sweetAlertError(data?.mensaje);
            }
          }
        }
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const enviarDocumentosEvaluarCliente = async (formData) => {
    try {
      const response = await api_consulta_tradicional_PFAE(formData);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setContadorOS(data?.cantidadMaximaOS - 1);
          if (data?.evaluacionRiesgos) {
            setLoading(false);
            setStyleHr("block");
            setShowResult(true);
            setResultadoEvaluacionRiesgos({
              evaluacionRiesgos: data?.evaluacionRiesgos,
              folioSolicitud: data?.folioSolicitud,
              mensajeEvaluacion: data?.mensajeEvaluacion
            });
          } else {
            setLoading(false);
            if (data?.obligadoSolidarioSiguiente) {
              setSelectedConsultaTradicionalPFAE(
                data?.obligadoSolidarioSiguiente
              );
            } else {
              if (
                data.solicitanteCargados === false ||
                data.osCargados === false ||
                data.osConyugeCargados === false
              ) {
                sweetAlertWarning(data?.mensaje);
              } else {
                if (data?.evaluacionRiesgos === false) {
                  sweetAlertWarning(data?.mensaje);
                }
              }
            }
          }
        } else {
          setLoading(false);
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
      } else {
        setLoading(false);
        sweetAlertWarning("Ocurrió un error al procesar los datos.");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleFiles = (
    files,
    idSolicitud,
    idCliente,
    tipoDeParticipante,
    tipoDocumento
  ) => {
    let newArray = [];
    const maxFile = 31457280;
    setLoading(true);
    for (let x = 0; x < files.length; x++) {
      URL.createObjectURL(files[0]);
      newArray.push(URL.createObjectURL(files[x]));
    }
    if (files[0].size < maxFile) {
      const extensionFile = files[0]?.path.substr(
        files[0]?.path?.lastIndexOf(".") + 1 - files[0]?.path?.length
      );
      if (FORMATOS_PERMITIDOS.indexOf(extensionFile) > -1) {
        uploadDocumento(
          files[0],
          idSolicitud,
          idCliente,
          tipoDeParticipante,
          tipoDocumento
        )
          .then((r) => setLoading(false))
          .catch((e) => console.error(e));
      } else {
        setLoading(false);
        sweetAlertWarning(MENSAJE_FORMATOS_PERMITIDOS);
      }
    } else {
      setLoading(false);
      sweetAlertWarning(
        "El archivo es demasiado grande " +
          convertToMb(files[0]?.size) +
          ". Tamaño máximo de archivo 30MB."
      );
    }
  };

  const convertToMb = (bytes) => {
    var mb = bytes / 1000000;
    return mb.toFixed(2) + "MB";
  };

  const uploadDocumento = async (
    e,
    idSolicitud,
    idCliente,
    tipoDeParticipante,
    tipoDocumento
  ) => {
    try {
      let formdata = new FormData();
      formdata.append("archivo", e, e.name);
      formdata.append("tipoDocumento", tipoDocumento);
      formdata.append("tipoDeParticipante", tipoDeParticipante);
      formdata.append("idSolicitud", idSolicitud);
      formdata.append("idCliente", idCliente);
      const response = await api_uploadDocumento(formdata);
      if (response?.data?.success) {
        if (tipoDocumento === TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL) {
          if (
            response?.data?.successIne === false &&
            response?.data?.validacionOCR === false
          ) {
            if (
              response?.data?.servicio?.noIne === false ||
              response?.data?.servicio?.errorOCR ||
              response?.data?.servicio?.errorClasificador ||
              response?.data?.servicio?.errorIne
            ) {
              setLoading(false);
              sweetAlertWarning(response?.data?.mensaje);
            } else {
              setLoading(false);
              sweetAlertDocuments(
                "/img/ine-no.png",
                "Este documento no es una INE/IFE",
                "Por favor sigue estas recomendaciones para que podamos validar tu identificación <br>" +
                  '<ul style="font-size: 14px; text-align: justify;">' +
                  '<li style="margin-top: 2%;">No colocar dos INES dentro de un documento pdf.</li>' +
                  '<li style="margin-top: 2%;">Coloca la credencial lo más centrada posible en la imagen.</li>' +
                  '<li style="margin-top: 2%;">No uses fondos del mismo color que la identificación.</li>' +
                  '<li style="margin-top: 2%;">De preferencia no uses flash. En caso de usarlo no deberá reflejarse en la imagen.</li>' +
                  "</ul>",
                response?.data?.mensaje
              );
            }
          } else {
            if (response?.data?.validacionOCR.ok == false) {
              setLoading(false);
              sweetAlertWarning(response?.data?.validacionOCR.message);
            } else {
              pintarDocumentosDropzone(tipoDocumento, e);
            }
          }
        } else {
          if (tipoDocumento === TIPO_DOCUMENTO_NOMBRE_AUTORIZACION_BURO) {
            if(response?.data?.successBc === true){
              pintarDocumentosDropzone(tipoDocumento, e);
            } else{
              setLoading(false);
              sweetAlertWarning(response?.data?.mensaje);
            }
          } else{
            pintarDocumentosDropzone(tipoDocumento, e);
          }                
        }
      } else {
        if (MENSAJE_ERROR_TOKEN_INVALIDO === response?.data?.mensaje) {
          sweetAlertWithConfirmed(response?.data?.mensaje);
        } else {
          sweetAlertError(response?.data?.mensaje);
        }
      }
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };

  const pintarDocumentosDropzone = (tipoDocumento, e) => {    
      formik.setFieldValue(`documents.${tipoDocumento}`, [
        {
          name: e.name,
        },
      ]);
  };

  const documentsType = [
    {
      tipoDeParticipante: "autorizacionBC",
      tipoDeDocumento: TIPO_DOCUMENTO_AUTORIZACION_BURO,
      title: "Autorización consulta buró de crédito *",
      documentList: formik?.values?.documents?.autorizacionBC,
    },
    {
      tipoDeParticipante: "identificacionOficial",
      tipoDeDocumento: TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_FRENTE,
      title: "Identificación oficial representante legal frente *",
      documentList: formik?.values?.documents?.identificacionOficial,
    },
    {
      tipoDeParticipante: "identificacionOficialReverso",
      tipoDeDocumento: TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_REVERSO,
      title: "Identificación oficial representante legal reverso *",
      documentList: formik?.values?.documents?.identificacionOficialReverso,
    },
    {
      tipoDeParticipante: "avisoPrivacidad",
      tipoDeDocumento: TIPO_DOCUMENTO_AVISO_DE_PRIVACIDAD_REPRESENTANTE_LEGAL,
      title: "Aviso de privacidad representante legal *",
      documentList: formik?.values?.documents?.avisoPrivacidad,
    },
  ];


  return (
    <>
    {mostrarDrop && (
        <form
          name="form-solicitante-consulta-tradicional-pfae"
          onSubmit={formik.handleSubmit}
        >
          <h1 style={{ margin: "2rem 0" }} className="f-s-3">
            Documentos cónyuge del solicitante - {nombreCoyuge}
          </h1>
          <HistorialCrediticioLinkFiles modeloDatos={modeloDatos} />
          <div className="d-flex flex-column justify-content-center align-items-center gap-2">
            {documentsType.map((document, index) => {
              return (
                (document.documentList == undefined ||  document.documentList[0].valido !== true) &&  
                  <InputDocument
                    key={index}
                    onDrop={(acceptedFiles) => {
                    handleFiles(
                      acceptedFiles,
                      formik?.values?.idSolicitud,
                      formik?.values?.idCliente,
                      formik?.values?.tipoDeParticipante,
                      document.tipoDeParticipante,
                      document.tipoDeDocumento
                    );
                  }}
                  className={`${
                    document.documentList ? "documentUploaded" : "documentEmpty"
                  } cargaInput ${document.documentList && "loader-carga"}`}
                  label={document.title}
                  documentList={
                    document.documentList ? document.documentList : []
                  }
                  formik={formik}
                  eliminarDocumentos={eliminarDocumentos}
                  nombreMapeo={document.tipoDeParticipante}
                 />
              );
            })}
            {formik.errors?.autorizacion && (
              <ErrorMessage message={formik.errors?.autorizacion} width={55} />
            )}
          </div>
          <div
            className="div-button-cargar"
            style={{
              margin: "2rem 0",
            }}
          >
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                if(docPendientesSolicitante){
                  setSelectedConsultaTradicionalPFAE(SECCION_HISTORIAL_SOLICITANTE);
                }else{
                  redireccionBtnAtrasHistorialPFAE(null);
                }                
              }}
            >
              Atrás
            </SecondaryButton>
            <PrimaryButton type="submit">Enviar documentos</PrimaryButton>
          </div>
        </form>
      )}

    </>
  );
};

export default ConsultaTradicionalSolicitanteConyugePFAE;

function initialValues(idCliente, modeloDatos, numOS) {
  return {
    participanteSolicitanteId: modeloDatos.idCliente,
    idSolicitud: modeloDatos.idSolicitud,
    idCliente: idCliente,
    tipoDeParticipante: "obligadoSolidario_" + numOS,
    autorizacion: true,
    contadorOS: 0,
    numParticipante: 4,
    files: [],
    documents: {},
  };
}

function validationSchema() {
  return {
    autorizacion: Yup.boolean().oneOf([true], "Debe autorizar consulta"),
  };
}
