import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  NAME_INPUT_DATO_DOMICILIO_TIPO_DOMICILIO,
  SECCION_DOMICILIO,
  TIPO_VIVIENDA_EDIFICIO,
  INE_CERTIFICADA,
  APLICA_INE_COMPROBANTE_OS_NO,
  TIPO_DEPARTAMENTO_APARTAMENTO,
  ID_PAIS_MEXICO,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  STATUS_EXITO
} from "../../../../utils/constantes";
import {
  api_save_datos_domicilio_PFAE,
  api_direccion_by_cp,
} from "../../../../api/api_servicios_rest";
import {
  tiempoViviendaAnterior,
  tiempoViviendaActual,
} from "../../../../utils/validaciones";
import { mensajeMostrarUsuario } from "../../../../utils/mensajesServicios";
import {
  validateValue,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../functions/fieldFunctions";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import NumberWithError from "../../../basics/NumberWithError/NumberWithError";
import SelectDoubleWithError from "../../../basics/SelectDoubleWithError/SelectDoubleWithError";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../helpers/alertas/alertas";

const DatosGeneralesDomicilioPFAE = ({
  modeloPfae,
  setModeloPfae,
  axiosData,
  aniosDomicilio,
  showInputImporteRenta,
  mostrarInputImporteRenta,
  mesesDomicilo,
  setLoading,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  webView,
}) => {
  const [coloniasCP, setColoniasCP] = useState([]);

  const formik = useFormik({
    initialValues: initialValues(modeloPfae, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosDomicilioPFAE(formData);
    },
  });

  useEffect(() => {
    showInputImporteRenta &&
      formik.setFieldValue("direccion.showImporteRenta", showInputImporteRenta);
  }, [showInputImporteRenta]);

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue("direccion.estadoId", response.data.estado.id);
          formik.setFieldValue(
            "direccion.colonia",
            response.data.asentamientos[0]
          );
          formik.setFieldValue(
            "direccion.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "direccion.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );

          let newErrors = formik.errors;
          delete newErrors?.direccion?.municipioId;
          delete newErrors?.direccion?.estadoId;
          delete newErrors?.direccion?.colonia;
          formik.setErrors(newErrors);
        } else {
          limpiarDireccionByCp();
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const limpiarDireccionByCp = () => {
    formik.setFieldValue("direccion.municipioId", "");
    formik.setFieldValue("direccion.municipioObj", "");
    formik.setFieldValue("direccion.estadoId", "");
    formik.setFieldValue("direccion.estadoObj", "");
    formik.setFieldValue("direccion.colonia", "");
  };

  const salvarDatosDomicilioPFAE = async (formData) => {
    try {
      const response = await api_save_datos_domicilio_PFAE(formData);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idCliente: data.clienteId,
            direccion: data.direccion,
          });
          handleSeccionPyme(SECCION_DOMICILIO);

          formik.setFieldValue("idCliente", data.clienteId);
          formik.setFieldValue(
            "direccion.idDireccion",
            data.direccionClienteId
          );
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);
            sweetAlertError(mensajeError);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modeloPfae.direccion.codigoPostal !== null) {
      obtenerDireccionByCp(modeloPfae.direccion.codigoPostal);
    }
  }, []);

  return (
    <>
      <form name="form-domicilio" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Información de domicilio</h2>
          <FormLayout>
            <InputWithError
              name="direccion.calle"
              label="Calle *"
              value={formik.values?.direccion?.calle}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.calle
                ) {
                  delete newErrors?.direccion?.calle;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue("direccion.calle", e.target.value);
                }
              }}
              InputProps={{ maxLength: 24 }}
              showErrorMessage={formik.errors?.direccion?.calle}
              errorMessage={formik.errors?.direccion?.calle}
            />
            <InputWithError
              name="direccion.numeroExterior"
              label="Número exterior *"
              value={formik.values?.direccion?.numeroExterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.numeroExterior
                ) {
                  delete newErrors?.direccion?.numeroExterior;
                  formik.setErrors(newErrors);
                }

                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "direccion.numeroExterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 7 }}
              showErrorMessage={formik.errors?.direccion?.numeroExterior}
              errorMessage={formik.errors?.direccion?.numeroExterior}
            />
            <InputWithError
              name="direccion.numeroInterior"
              label="Número interior"
              value={formik.values?.direccion?.numeroInterior}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.numeroInterior
                ) {
                  delete newErrors?.direccion?.numeroInterior;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "direccion.numeroInterior",
                    e.target.value
                  );
                }
              }}
              required={
                formik.values.direccion.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.direccion.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu  “Número exterior” separados por un guion Ejemplo: 318-123B"
              showErrorMessage={formik.errors?.direccion?.numeroInterior}
              errorMessage={formik.errors?.direccion?.numeroInterior}
            />
            <InputWithError
              label={
                formik.values.direccion.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.direccion.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
                  ? "Piso *"
                  : "Piso"
              }
              value={formik.values?.direccion?.piso}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.piso
                ) {
                  delete newErrors?.direccion?.piso;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue("direccion.piso", e.target.value);
                }
              }}
              InputProps={{ maxLength: 2 }}
              showErrorMessage={formik.errors?.direccion?.piso}
              errorMessage={formik.errors?.direccion?.piso}
            />
            <InputWithError
              label="CP *"
              name="direccion.codigoPostal"
              value={formik.values?.direccion?.codigoPostal}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.codigoPostal
                ) {
                  delete newErrors?.direccion?.codigoPostal;
                  formik.setErrors(newErrors);
                }
                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "direccion.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  let newErrors = formik.errors;
                  if (newErrors?.direccion !== undefined) {
                    if (newErrors?.direccion?.colonia) {
                      delete newErrors?.direccion?.colonia;
                    }
                    if (newErrors?.direccion?.municipioId) {
                      delete newErrors?.direccion?.municipioId;
                    }
                    if (newErrors?.direccion?.estadoId) {
                      delete newErrors?.direccion?.estadoId;
                    }
                    formik.setErrors(newErrors);
                  }
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp();
                }
              }}
              showErrorMessage={formik.errors?.direccion?.codigoPostal}
              errorMessage={formik.errors?.direccion?.codigoPostal}
            />
            <SelectWithError
              label="Colonia *"
              name="direccion.colonia"
              value={{
                label: formik?.values?.direccion?.colonia,
                value: formik?.values?.direccion?.colonia,
              }}
              onChange={(e) => {
                formik.setFieldValue("direccion.colonia", e.label);
              }}
              options={coloniasCP?.map((e) => {
                return { ...e, label: e, value: e };
              })}
              showErrorMessage={formik.errors?.direccion?.colonia}
              errorMessage={formik.errors?.direccion?.colonia}
            />
            <SelectWithError
              label="Alcaldía / Municipio *"
              name="direccion.municipioId"
              placeholder="Delegación/Municipio *"
              value={{
                label: formik?.values?.direccion?.municipioObj[0]?.nombre,
                value: formik?.values?.direccion?.municipioObj[0]?.nombre,
              }}
              onChange={(e) => {
                formik.setFieldValue("direccion.municipioId", e.id);
                formik.setFieldValue("direccion.municipioObj", [e]);
              }}
              isDisabled={true}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.direccion?.municipioId}
              errorMessage={formik.errors?.direccion?.municipioId}
            />
            <SelectWithError
              label="Estado *"
              name="direccion.estadoId"
              isDisabled={true}
              value={{
                label: formik?.values?.direccion?.estadoObj[0]?.nombre,
                value: formik?.values?.direccion?.estadoObj[0]?.nombre,
              }}
              onChange={(e) => {
                formik.setFieldValue("direccion.estadoId", e.id);
                formik.setFieldValue("direccion.estadoObj", [e]);
              }}
              options={axiosData.generales.estados.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.direccion?.estadoId}
              errorMessage={formik.errors?.direccion?.estadoId}
            />
            <SelectWithError
              label="Tipo de domicilio *"
              name="direccion.tipoDomicilioId"
              value={{
                label: formik?.values?.direccion?.tipoDomicilioObj[0]?.nombre,
                value: formik?.values?.direccion?.tipoDomicilioObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.tipoDomicilioId
                ) {
                  delete newErrors?.direccion?.tipoDomicilioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("direccion.tipoDomicilioId", e.id);
                formik.setFieldValue("direccion.tipoDomicilioObj", [e]);
                mostrarInputImporteRenta(
                  e.id,
                  NAME_INPUT_DATO_DOMICILIO_TIPO_DOMICILIO
                );
              }}
              options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={formik.errors?.direccion?.tipoDomicilioId}
              errorMessage={formik.errors?.direccion?.tipoDomicilioId}
            />
            {showInputImporteRenta && (
              <NumberWithError
                label="Importe de la renta *"
                name="direccion.importeRenta"
                value={formik.values?.direccion?.importeRenta}
                onValueChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.direccion !== undefined &&
                    newErrors?.direccion?.importeRenta
                  ) {
                    delete newErrors?.direccion?.importeRenta;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue("direccion.importeRenta", e.value);
                }}
                showErrorMessage={formik.errors?.direccion?.importeRenta}
                errorMessage={formik.errors?.direccion?.importeRenta}
              />
            )}
            <SelectWithError
              label="Tipo de vivienda *"
              name="direccion.tipoViviendaId"
              value={{
                label: formik?.values?.direccion?.tipoViviendaObj[0]?.nombre,
                value: formik?.values?.direccion?.tipoViviendaObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.tipoViviendaId
                ) {
                  delete newErrors?.direccion?.tipoViviendaId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("direccion.tipoViviendaId", e.id);
                formik.setFieldValue("direccion.tipoViviendaObj", [e]);
              }}
              options={axiosData.domicilio.tipoDeViviendaOpciones.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={formik.errors?.direccion?.tipoViviendaId}
              errorMessage={formik.errors?.direccion?.tipoViviendaId}
            />
            <SelectDoubleWithError
              label="Antigüedad en el domicilio actual *"
              menuPlacement="top"
              showErrorMessage={formik.errors?.direccion?.tiempoViviendaMeses}
              errorMessage={formik.errors?.direccion?.tiempoViviendaMeses}
              nameAnios="direccion.tiempoViviendaAnios"
              valueAnios={{
                label: formik?.values?.direccion?.tiempoViviendaAnios,
                value: formik?.values?.direccion?.tiempoViviendaAnios,
              }}
              optionsAnios={aniosDomicilio?.map((el, index) => {
                return { ...el, label: index, value: index };
              })}
              errorAnios={formik.errors?.direccion?.tiempoViviendaMeses}
              onChangeAnios={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.tiempoViviendaAnios
                ) {
                  delete newErrors?.direccion?.tiempoViviendaAnios;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("direccion.tiempoViviendaAnios", e.label);
              }}
              nameMeses="direccion.tiempoViviendaMeses"
              valueMeses={{
                label: formik?.values?.direccion?.tiempoViviendaMeses,
                value: formik?.values?.direccion?.tiempoViviendaMeses,
              }}
              optionsMeses={mesesDomicilo?.map((el, index) => {
                return { ...el, label: index, value: index };
              })}
              errorMeses={formik.errors?.direccion?.tiempoViviendaMeses}
              onChangeMeses={(e) => {
                let newErrors = formik.errors;
                delete newErrors?.direccion?.tiempoViviendaMeses;
                formik.setErrors(newErrors);
                formik.setFieldValue("direccion.tiempoViviendaMeses", e.label);
              }}
            />
            <SelectDoubleWithError
              label="Antigüedad en el domicilio anterior *"
              menuPlacement="top"
              showErrorMessage={
                formik.errors?.direccion?.tiempoViviendaAntMeses
              }
              errorMessage={formik.errors?.direccion?.tiempoViviendaAntMeses}
              nameAnios="direccion.tiempoViviendaAntAnios"
              valueAnios={{
                label: formik?.values?.direccion?.tiempoViviendaAntAnios,
                value: formik?.values?.direccion?.tiempoViviendaAntAnios,
              }}
              optionsAnios={aniosDomicilio?.map((el, index) => {
                return { ...el, label: index, value: index };
              })}
              errorAnios={formik.errors?.direccion?.tiempoViviendaAntMeses}
              onChangeAnios={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.direccion !== undefined &&
                  newErrors?.direccion?.tiempoViviendaAntAnios
                ) {
                  delete newErrors?.direccion?.tiempoViviendaAntAnios;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "direccion.tiempoViviendaAntAnios",
                  e.label
                );
              }}
              nameMeses="direccion.tiempoViviendaAntMeses"
              valueMeses={{
                label: formik?.values?.direccion?.tiempoViviendaAntMeses,
                value: formik?.values?.direccion?.tiempoViviendaAntMeses,
              }}
              optionsMeses={mesesDomicilo?.map((el, index) => {
                return { ...el, label: index, value: index };
              })}
              errorMeses={formik.errors?.direccion?.tiempoViviendaAntMeses}
              onChangeMeses={(e) => {
                let newErrors = formik.errors;
                delete newErrors?.direccion?.tiempoViviendaAntMeses;
                formik.setErrors(newErrors);
                formik.setFieldValue(
                  "direccion.tiempoViviendaAntMeses",
                  e.label
                );
              }}
            />
            <div className="d-flex gap-2">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_DOMICILIO);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesDomicilioPFAE;

function initialValues(modeloPfae, axiosData) {
  let direccion = modeloPfae.direccion;
  return {
    idCliente: modeloPfae.idCliente,
    direccion: {
      idDireccion: direccion.idDireccion,
      calle: direccion.calle ? direccion.calle : "",
      numeroExterior: direccion.numeroExterior ? direccion.numeroExterior : "",
      numeroInterior: direccion.numeroInterior ? direccion.numeroInterior : "",
      piso: direccion.piso ? direccion.piso : "",
      ineCertificada: INE_CERTIFICADA,
      aplicaIneComprobante: APLICA_INE_COMPROBANTE_OS_NO,
      paisId: direccion.paisId ? direccion.paisId : ID_PAIS_MEXICO,
      codigoPostal: direccion.codigoPostal ? direccion.codigoPostal : "",
      colonia: direccion.colonia ? direccion.colonia : "",
      municipioId: direccion.municipioId ? direccion.municipioId : "",
      municipioObj: direccion.municipioId
        ? axiosData?.generales?.municipios?.filter(
            (e) => e.id === direccion?.municipioId
          )
        : [{ nombre: undefined }],
      estadoId: direccion.estadoId ? direccion.estadoId : "",
      estadoObj: direccion.estadoId
        ? axiosData?.generales?.estados?.filter(
            (e) => e.id === direccion.estadoId
          )
        : [{ nombre: undefined }],
      tipoDomicilioId: direccion.tipoDomicilioId
        ? direccion.tipoDomicilioId
        : "",
      tipoDomicilioObj: direccion.tipoDomicilioId
        ? axiosData.domicilio.tipoDeDomicilioOpciones.filter(
            (e) => e.id === direccion?.tipoDomicilioId
          )
        : [{ nombre: undefined }],
      tipoViviendaId: direccion.tipoViviendaId ? direccion.tipoViviendaId : "",
      tipoViviendaObj: direccion.tipoViviendaId
        ? axiosData.domicilio.tipoDeViviendaOpciones.filter(
            (e) => e.id === direccion.tipoViviendaId
          )
        : [{ nombre: undefined }],
      showImporteRenta: false,
      tiempoViviendaAnios: direccion.tiempoViviendaAnios
        ? direccion.tiempoViviendaAnios
        : "",
      tiempoViviendaMeses: direccion.tiempoViviendaMeses
        ? direccion.tiempoViviendaMeses
        : "",
      tiempoViviendaAntAnios: direccion.tiempoViviendaAntAnios
        ? direccion.tiempoViviendaAntAnios
        : "",
      tiempoViviendaAntMeses: direccion.tiempoViviendaAntMeses
        ? direccion.tiempoViviendaAntMeses
        : "",
      calleUno: direccion.calleUno ? direccion.calleUno : "",
      calleDos: direccion.calleDos ? direccion.calleDos : "",
    },
  };
}

function validationSchema() {
  return {
    direccion: Yup.object().shape(
      {
        calle: Yup.string().required("Dato requerido."),
        numeroExterior: Yup.string().required("Dato requerido."),
        numeroInterior: Yup.string().when("tipoViviendaId", {
          is: TIPO_VIVIENDA_EDIFICIO,
          then: Yup.string().required("Dato requerido."),
          otherwise: Yup.string().when("tipoViviendaId", {
            is: TIPO_DEPARTAMENTO_APARTAMENTO,
            then: Yup.string().required("Dato requerido."),
          }),
        }),
        piso: Yup.string().when("tipoViviendaId", {
          is: TIPO_VIVIENDA_EDIFICIO,
          then: Yup.string().required("Dato requerido."),
          otherwise: Yup.string().when("tipoViviendaId", {
            is: TIPO_DEPARTAMENTO_APARTAMENTO,
            then: Yup.string().required("Dato requerido."),
          }),
        }),
        codigoPostal: Yup.string().required("Dato requerido."),
        colonia: Yup.string().required("Dato requerido."),
        municipioId: Yup.string().required("Dato requerido."),
        estadoId: Yup.string().required("Dato requerido."),
        tipoDomicilioId: Yup.string().required("Dato requerido."),
        showImporteRenta: Yup.boolean(),
        importeRenta: Yup.string().when("showImporteRenta", {
          is: true,
          then: Yup.string().required("Dato requerido."),
        }),
        tipoViviendaId: Yup.string().required("Dato requerido."),
        tiempoViviendaAnios: Yup.number().required("Dato requerido."),
        tiempoViviendaMeses: Yup.number()
          .required("Dato requerido.")
          .test(
            "tiempoViviendaMeses",
            "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
            (item, testContext) => {
              return tiempoViviendaActual(
                testContext.parent.tiempoViviendaMeses,
                testContext.parent.tiempoViviendaAnios
              );
            }
          ),
        tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
        tiempoViviendaAntMeses: Yup.number()
          .required("Dato requerido.")
          .test(
            "tiempoViviendaAntMeses",
            "Dato requerido.",
            (item, testContext) => {
              return tiempoViviendaAnterior(
                testContext.parent.tiempoViviendaAntMeses,
                testContext.parent.tiempoViviendaAntAnios
              );
            }
          ),
      },
      [["importeRenta"]]
    ),
  };
}
