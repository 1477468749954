import React, { useEffect, useState } from "react";
import CargarDocumentoSolicitantePFAE from "./CargarDocumentoSolicitantePFAE";
import CargarDocumentoSolicitanteConyugePFAE from "./CargarDocumentoSolicitanteConyugePFAE";
import CargarDocumentoOSPMPFAE from "./CargarDocumentoOSPMPFAE";
import CargarDocumentoOSPFAEPFAE from "./CargarDocumentoOSPFAEPFAE";
import CargarDocumentoOSPFAEPFAEPFAEConyuge from "./CargarDocumentoOSPFAEPFAEConyuge";

import {
  api_obtener_os_solicitud,
  api_obtener_documentos_solicitud_pfae,
  api_validar_etapa_uno,
} from "../../../../../api/api_servicios_rest";

import {
  ID_PM,
  KEY_PM,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  OS_CERO,
  STATUS_EXITO
} from "../../../../../utils/constantes";
import { sweetAlertError } from "../../../../helpers/alertas/alertas";

const CargaDocumentosPFAE = ({
  setShowResult,
  tipoPersona,
  cantidadObligadosSolidarios,
  modeloPfae,
  setLoading,
}) => {
  const [selectedDocumentos, setSelectedDocumentos] = useState(1);
  const [options, setOptions] = useState([]);
  const [documentosSolicud, setDocumentosSolicud] = useState([]);
  const [obligadosSolidarios, setObligadosSolidarios] = useState([]);
  const [idEstadoCivilSolicitante, setIdEstadoCivilSolicitante] = useState();

  useEffect(() => {
    const obtenerDocumentos = async () => {
      setLoading(true);
      let letOptions = [];

      for (let i = 0; i < 4; i++) {
        letOptions.push(i);
      }
      setOptions(letOptions);

      if (modeloPfae?.idCliente !== null) {
        await obtenerDocumentosSolicitud();
        await obtenerObligadosSolidariosSolicitud();
      }

      setLoading(false);
    };

    obtenerDocumentos();
  }, [cantidadObligadosSolidarios]);

  const obtenerDocumentosSolicitud = async () => {
    try {
      const response = await api_obtener_documentos_solicitud_pfae();
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        setDocumentosSolicud(data);
      } else {
        sweetAlertError("Ocurrió un error al obtener documentos solicitud");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const obtenerObligadosSolidariosSolicitud = async () => {
    try {
      const datosCliente = {
        idCliente: modeloPfae.idCliente,
      };
      const response = await api_obtener_os_solicitud(datosCliente);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          modeloPfae.obligadosSolidarios = data.obligadosSolidarios;
          setObligadosSolidarios(data.obligadosSolidarios);
          setIdEstadoCivilSolicitante(data.idEstadoCivilSolicitante);
        } else {
          sweetAlertError("Ocurrió un error al obtener obligados solidarios");
        }
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const obtenerPestaniaSgteCargaDocumentos = (idCliente, numOS) => {
    let obligadoSolidarioSgte = null;
    let avanzarCargaDoc = false;
    let cambioStatus = false;
    let descartaOS = false;

    setLoading(true);

    if (numOS === OS_CERO) {
      if (
        modeloPfae?.idCliente === idCliente &&
        idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
        modeloPfae?.conyuge?.generales?.esObligadoSolidario
      ) {
        setSelectedDocumentos(2);
        descartaOS = true;
      } else {
        obligadoSolidarioSgte = obligadosSolidarios?.filter(
          (e) => e.numero === numOS + 1
        );
      }
    } else {
      if (obligadosSolidarios.length > 0) {
        let obligadoSolidarioActual = obligadosSolidarios?.filter(
          (e) => e.numero === numOS
        );

        if (parseInt(obligadoSolidarioActual[0].tipoPersonaKey) !== ID_PM) {
          if (
            obligadoSolidarioActual[0]?.obligadoSolidario
              ?.idObligadoSolidario == idCliente &&
            obligadoSolidarioActual[0]?.obligadoSolidario?.generales
              ?.estadoCivilId == ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
            obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge.generales
              ?.esObligadoSolidario
          ) {
            setSelectedDocumentos(
              obligadoSolidarioActual[0]?.obligadoSolidario?.conyuge?.idConyuge
            );
            avanzarCargaDoc = true;
            descartaOS = true;
          }
        }
        if (avanzarCargaDoc == false) {
          obligadoSolidarioSgte = obligadosSolidarios?.filter(
            (e) => e.numero === numOS + 1
          );
        }
      } else {
        cambioStatus = true;
      }
    }

    if (obligadoSolidarioSgte != null && obligadoSolidarioSgte.length != 0) {
      if (parseInt(obligadoSolidarioSgte[0].tipoPersonaKey) !== ID_PM) {
        setSelectedDocumentos(
          obligadoSolidarioSgte[0]?.obligadoSolidario?.idObligadoSolidario
        );
      } else {
        setSelectedDocumentos(
          obligadoSolidarioSgte[0]?.obligadoSolidarioPM?.idObligadoSolidario
        );
      }
    } else {
      cambioStatus = true;
    }

    if (cambioStatus == true && descartaOS == false) {
      avanzarValidacionDocumental(modeloPfae?.idCliente);
    } else {
      setLoading(false);
    }
  };

  const avanzarValidacionDocumental = async (idCliente) => {
    try {
      const response = await api_validar_etapa_uno(idCliente);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        if (response.data.success) {
          if (response.data.validacionDocumentos.success) {
            setShowResult(true);
          } else {
            sweetAlertError(response.data.validacionDocumentos.mensaje);
          }
        } else {
          sweetAlertError(response?.data?.mensaje);
        }
      } else {
        sweetAlertError("Ocurrió un error al avanzar a validación etapa uno");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="container navigation"
        style={{
          overflow: "scroll",
          display: "flex",
          alignItems: "center",
          marginTop: "24px",
          gap: "24px",
        }}
      >
        {tipoPersona === "PFAE" && (
          <>
            <p
              className={`${
                selectedDocumentos === 1 ? "bgc-red radius strong-shadow" : ""
              } pointer`}
              onClick={() => setSelectedDocumentos(1)}
              style={{ padding: "8px 16px" }}
            >
              Solicitante
            </p>
            {idEstadoCivilSolicitante === ESTADO_CIVIL_SOCIEDAD_CONYUGAL &&
              modeloPfae?.conyuge?.generales?.esObligadoSolidario && (
                <p
                  className={`${
                    selectedDocumentos === 2
                      ? "bgc-red radius strong-shadow"
                      : ""
                  } pointer`}
                  onClick={() => setSelectedDocumentos(2)}
                  style={{ padding: "8px 16px" }}
                >
                  Cónyuge Solicitante
                </p>
              )}
            {obligadosSolidarios.map((item, index) => {
              return (
                <>
                  {item?.obligadoSolidario?.idObligadoSolidario !==
                    undefined && (
                    <p
                      className={`${
                        selectedDocumentos ===
                        item?.obligadoSolidario?.idObligadoSolidario
                          ? "bgc-red radius strong-shadow"
                          : ""
                      } pointer`}
                      onClick={() =>
                        setSelectedDocumentos(
                          item?.obligadoSolidario?.idObligadoSolidario
                        )
                      }
                      style={{ padding: "8px 16px" }}
                    >
                      OS{index + 1}
                    </p>
                  )}
                  {item?.obligadoSolidarioPM?.idObligadoSolidario !==
                    undefined && (
                    <p
                      className={`${
                        selectedDocumentos ===
                        item?.obligadoSolidarioPM?.idObligadoSolidario
                          ? "bgc-red radius strong-shadow"
                          : ""
                      } pointer`}
                      onClick={() =>
                        setSelectedDocumentos(
                          item?.obligadoSolidarioPM?.idObligadoSolidario
                        )
                      }
                      style={{ padding: "8px 16px" }}
                    >
                      OS{index + 1}
                    </p>
                  )}

                  {item?.obligadoSolidario?.conyuge.idConyuge !== undefined &&
                    item?.obligadoSolidario?.conyuge.generales
                      ?.esObligadoSolidario && (
                      <p
                        className={`${
                          selectedDocumentos ===
                          item?.obligadoSolidario?.conyuge?.idConyuge
                            ? "bgc-red radius strong-shadow"
                            : ""
                        } pointer`}
                        onClick={() =>
                          setSelectedDocumentos(
                            item?.obligadoSolidario?.conyuge?.idConyuge
                          )
                        }
                        style={{ padding: "8px 16px" }}
                      >
                        Cónyuge OS{index + 1}
                      </p>
                    )}
                </>
              );
            })}
          </>
        )}
      </div>

      {selectedDocumentos === 1 && (
        <CargarDocumentoSolicitantePFAE
          idSolicitud={modeloPfae?.idSolicitud}
          documentosSolicitante={documentosSolicud.documentosSolicitantePFAE}
          setLoading={setLoading}
          obtenerPestaniaSgteCargaDocumentos={
            obtenerPestaniaSgteCargaDocumentos
          }
          idCliente={modeloPfae?.idCliente}
        />
      )}

      {selectedDocumentos === 2 && (
        <CargarDocumentoSolicitanteConyugePFAE
          idSolicitud={modeloPfae?.idSolicitud}
          documentosSolicitanteConyuge={
            documentosSolicud.documentosObligadoSolidarioPFAE
          }
          setLoading={setLoading}
          obtenerPestaniaSgteCargaDocumentos={
            obtenerPestaniaSgteCargaDocumentos
          }
          idCliente={modeloPfae?.conyuge?.idConyuge}
        />
      )}

      {obligadosSolidarios !== undefined &&
        obligadosSolidarios.map((item, index) => {
          return (
            <>
              {selectedDocumentos ===
                item?.obligadoSolidario?.idObligadoSolidario && (
                <CargarDocumentoOSPFAEPFAE
                  documentosOS={
                    documentosSolicud.documentosObligadoSolidarioPFAE
                  }
                  numOS={item?.numero}
                  idSolicitud={modeloPfae?.idSolicitud}
                  setLoading={setLoading}
                  obtenerPestaniaSgteCargaDocumentos={
                    obtenerPestaniaSgteCargaDocumentos
                  }
                  idCliente={item?.obligadoSolidario?.idObligadoSolidario}
                />
              )}
              {selectedDocumentos ===
                item?.obligadoSolidarioPM?.idObligadoSolidario && (
                <CargarDocumentoOSPMPFAE
                  documentosOS={documentosSolicud.documentosObligadoSolidarioPM}
                  numOS={item?.numero}
                  idSolicitud={modeloPfae?.idSolicitud}
                  setLoading={setLoading}
                  obtenerPestaniaSgteCargaDocumentos={
                    obtenerPestaniaSgteCargaDocumentos
                  }
                  idCliente={item?.obligadoSolidarioPM?.idObligadoSolidario}
                />
              )}

              {selectedDocumentos ===
                item?.obligadoSolidario?.conyuge.idConyuge && (
                <CargarDocumentoOSPFAEPFAEPFAEConyuge
                  documentosConyugeOS={
                    documentosSolicud.documentosObligadoSolidarioPFAE
                  }
                  numOS={item?.numero}
                  idSolicitud={modeloPfae?.idSolicitud}
                  setLoading={setLoading}
                  obtenerPestaniaSgteCargaDocumentos={
                    obtenerPestaniaSgteCargaDocumentos
                  }
                  idCliente={item?.obligadoSolidario?.conyuge?.idConyuge}
                />
              )}
            </>
          );
        })}
    </>
  );
};

export default CargaDocumentosPFAE;
