import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TIPO_LOCAL_RENTA,
  SECCION_INFORMACION_NEGOCIO,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  STATUS_EXITO
} from "../../../../utils/constantes";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import { api_save_datos_negocio_PM } from "../../../../api/api_servicios_rest";
import { valorMinimo, rangoValorValido } from "../../../../utils/validaciones";
import { mensajeMostrarUsuario } from "../../../../utils/mensajesServicios";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import SelectWithErrorActividad from "../../../basics/SelectWithError/SelectWithErrorActividad";
import NumberWithError from "../../../basics/NumberWithError/NumberWithError";
import SelectWithErrorTooltipCompuesto from "../../../basics/SelectWithError/SelectWithErrorTooltipCompuesto";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../helpers/alertas/alertas";

const DatosGeneralesInfoNegocioPM = ({
  modeloPm,
  setModeloPm,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  axiosData,
  anios,
  setStyleHr,
  Swal,
  setLoading,
  webView,
}) => {
  const MAX_VAL_TOTAL = 100000;
  const MAX_VAL = 9999;
  const MIN_VAL = 0;

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value >= MIN_VAL && value <= MAX_VAL) return true;
    return false;
  };

  const withValueCapMax = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL_TOTAL) return true;
    return false;
  };

  const formik = useFormik({
    initialValues: initialValues(modeloPm, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosNegocioPM(formData);
    },
  });

  const salvarDatosNegocioPM = async (formData) => {
    try {
      const response = await api_save_datos_negocio_PM(formData);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idCliente: data.clienteId,
            datosDelNegocio: data.datosDelNegocio,
          });
          setStyleHr("block");
          handleSeccionPyme(SECCION_INFORMACION_NEGOCIO);
          formik.setFieldValue(
            "datosDelNegocio.idContactoDeLaEmpresa",
            data.datosNegocioId
          );
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);
            sweetAlertError(mensajeError);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar datos contacto PM");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form name="form-info-negocio" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Información del negocio</h2>
          <FormLayout>
            <SelectWithError
              label="¿Realizas actividades de importación o exportación? *"
              name="datosDelNegocio.importaExportaId"
              value={{
                label:
                  formik?.values?.datosDelNegocio?.importarExportarObj[0]
                    .nombre,
                value:
                  formik?.values?.datosDelNegocio?.importarExportarObj[0]
                    .nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.importaExportaId
                ) {
                  delete newErrors?.datosDelNegocio?.importaExportaId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("datosDelNegocio.importaExportaId", e.id);
                formik.setFieldValue("datosDelNegocio.importarExportarObj", [
                  e,
                ]);
              }}
              options={axiosData.domicilioFiscal.importaExportaOpciones.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.datosDelNegocio?.importaExportaId
              }
              errorMessage={formik.errors?.datosDelNegocio?.importaExportaId}
            />
            <SelectWithErrorTooltipCompuesto
              name="datosDelNegocio.giroEmpresarialId"
              value={{
                label:
                  formik.values.datosDelNegocio.giroEmpresarialObj[0].nombre,
                value:
                  formik.values.datosDelNegocio.giroEmpresarialObj[0].nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.giroEmpresarialId
                ) {
                  delete newErrors?.datosDelNegocio?.giroEmpresarialId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("datosDelNegocio.giroEmpresarialId", e.id);
                formik.setFieldValue("datosDelNegocio.giroEmpresarialObj", [e]);
              }}
              label="Giro *"
              options={axiosData.domicilioFiscal.giroEmpresarialOpciones.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              tooltipA="Comercio: Supermercados, tiendas, farmacias, ferreterías, etc."
              tooltipB="Industria: Manufacturera, constructoras, textil, alimenticia, etc."
              tooltipC="Servicios: Escuelas, hospitales, despachos, transporte, médicos, dentista, restaurantes, etc."
              showErrorMessage={
                formik.errors?.datosDelNegocio?.giroEmpresarialId
              }
              errorMessage={formik.errors?.datosDelNegocio?.giroEmpresarialId}
            />
            <SelectWithError
              label="Sector *"
              name="datosDelNegocio.sectorId"
              value={{
                label: formik?.values?.datosDelNegocio?.sectorObj[0]?.nombre,
                value: formik?.values?.datosDelNegocio?.sectorObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.sectorId
                ) {
                  delete newErrors?.datosDelNegocio?.sectorId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("datosDelNegocio.sectorId", e.id);
                formik.setFieldValue("datosDelNegocio.sectorObj", [e]);
              }}
              options={axiosData.domicilioFiscal.sectorOpciones.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={formik.errors?.datosDelNegocio?.sectorId}
              errorMessage={formik.errors?.datosDelNegocio?.sectorId}
            />
            <SelectWithErrorActividad
              name="datosDelNegocio.actividadRiesgoApoloId"
              value={{
                label:
                  formik.values.datosDelNegocio.actividadRiesgoObj[0]
                    .detalleClave,
                value:
                  formik.values.datosDelNegocio.actividadRiesgoObj[0]
                    .detalleClave,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.actividadRiesgoApoloId
                ) {
                  delete newErrors?.datosDelNegocio?.actividadRiesgoApoloId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "datosDelNegocio.actividadRiesgoApoloId",
                  e.id
                );
                formik.setFieldValue("datosDelNegocio.actividadRiesgoObj", [e]);
              }}
              label="Actividad *"
              options={axiosData?.domicilioFiscal?.actividadRiesgoApoloOpciones.map(
                (item) => {
                  return {
                    ...item,
                    label: item.detalleClave,
                    value: item.detalleClave,
                  };
                }
              )}
              tooltip="Selecciona la actividad que mas se asemeje a tu alta en hacienda. En caso de que tengas varias actividades, selecciona la preponderante."
              showErrorMessage={
                formik.errors?.datosDelNegocio?.actividadRiesgoApoloId
              }
              errorMessage={
                formik.errors?.datosDelNegocio?.actividadRiesgoApoloId
              }
            />
            <SelectWithError
              name="datosDelNegocio.aniosExperienciaSector"
              value={{
                label: formik?.values?.datosDelNegocio?.aniosExperienciaSector,
                value: formik?.values?.datosDelNegocio?.aniosExperienciaSector,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.aniosExperienciaSector
                ) {
                  delete newErrors?.datosDelNegocio?.aniosExperienciaSector;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "datosDelNegocio.aniosExperienciaSector",
                  e.value
                );
              }}
              label="Años de experiencia en el sector *"
              options={anios?.map((item, index) => {
                return {
                  ...item,
                  label: index + 1,
                  value: index + 1,
                };
              })}
              showErrorMessage={
                formik.errors?.datosDelNegocio?.aniosExperienciaSector
              }
              errorMessage={
                formik.errors?.datosDelNegocio?.aniosExperienciaSector
              }
            />
            <SelectWithError
              label="Años de antigüedad en tu actividad *"
              name="datosDelNegocio.antiguedadActividad"
              value={{
                label: formik?.values?.datosDelNegocio?.antiguedadActividad,
                value: formik?.values?.datosDelNegocio?.antiguedadActividad,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.antiguedadActividad
                ) {
                  delete newErrors?.datosDelNegocio?.antiguedadActividad;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "datosDelNegocio.antiguedadActividad",
                  e.value
                );
              }}
              options={anios?.map((el, index) => {
                return { label: index + 1, value: index + 1 };
              })}
              showErrorMessage={
                formik.errors?.datosDelNegocio?.antiguedadActividad
              }
              errorMessage={formik.errors?.datosDelNegocio?.antiguedadActividad}
            />
            <NumberWithError
              label="Total de empleados *"
              name="datosDelNegocio.totalEmpleados"
              value={formik.values?.datosDelNegocio?.totalEmpleados}
              onValueChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.totalEmpleados
                ) {
                  delete newErrors?.datosDelNegocio?.totalEmpleados;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("datosDelNegocio.totalEmpleados", e.value);
              }}
              type="text"
              prefix=""
              decimalScale={0}
              isAllowed={withValueCapMax}
              InputProps={{ maxLength: 3 }}
              showErrorMessage={formik.errors?.datosDelNegocio?.totalEmpleados}
              errorMessage={formik.errors?.datosDelNegocio?.totalEmpleados}
            />
            <NumberWithError
              label="Número de sucursales *"
              name="datosDelNegocio.numeroSucursales"
              prefix=""
              value={formik.values?.datosDelNegocio?.numeroSucursales}
              onValueChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.numeroSucursales
                ) {
                  delete newErrors?.datosDelNegocio?.numeroSucursales;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "datosDelNegocio.numeroSucursales",
                  e.value
                );
              }}
              type="text"
              decimalScale={0}
              isAllowed={withValueCap}
              showErrorMessage={
                formik.errors?.datosDelNegocio?.numeroSucursales
              }
              errorMessage={formik.errors?.datosDelNegocio?.numeroSucursales}
            />
            <SelectWithError
              label="Propiedad de local *"
              menuPlacement="top"
              name="datosDelNegocio.tipoLocalId"
              value={{
                label: formik?.values?.datosDelNegocio?.tipoLocalObj[0]?.nombre,
                value: formik?.values?.datosDelNegocio?.tipoLocalObj[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.tipoLocalId
                ) {
                  delete newErrors?.datosDelNegocio?.tipoLocalId;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("datosDelNegocio.tipoLocalId", e.id);
                formik.setFieldValue("datosDelNegocio.tipoLocalObj", [e]);
              }}
              options={axiosData.domicilioFiscal.tipoLocalOpciones.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={formik.errors?.datosDelNegocio?.tipoLocalId}
              errorMessage={formik.errors?.datosDelNegocio?.tipoLocalId}
            />
            {parseInt(formik.values?.datosDelNegocio?.tipoLocalId) ===
              TIPO_LOCAL_RENTA && (
              <>
                <SelectWithError
                  label="Años que lo ha rentado *"
                  name="datosDelNegocio.aniosRentado"
                  menuPlacement="top"
                  value={{
                    label: formik?.values?.datosDelNegocio?.aniosRentado,
                    value: formik?.values?.datosDelNegocio?.aniosRentado,
                  }}
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.datosDelNegocio !== undefined &&
                      newErrors?.datosDelNegocio?.aniosRentado
                    ) {
                      delete newErrors?.datosDelNegocio?.aniosRentado;
                      formik.setErrors(newErrors);
                    }
                    formik.setFieldValue(
                      "datosDelNegocio.aniosRentado",
                      e.label
                    );
                  }}
                  options={anios?.map((el, index) => {
                    return { label: index + 1, value: index + 1 };
                  })}
                  showErrorMessage={
                    formik.errors?.datosDelNegocio?.aniosRentado
                  }
                  errorMessage={formik.errors?.datosDelNegocio?.aniosRentado}
                />
                <NumberWithError
                  label="Importe renta mensual *"
                  name="datosDelNegocio.rentaMensual"
                  value={formik.values?.datosDelNegocio?.rentaMensual}
                  onValueChange={(values) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.datosDelNegocio !== undefined &&
                      newErrors?.datosDelNegocio?.rentaMensual
                    ) {
                      delete newErrors?.datosDelNegocio?.rentaMensual;
                      formik.setErrors(newErrors);
                    }
                    formik.setFieldValue(
                      "datosDelNegocio.rentaMensual",
                      values.value
                    );
                  }}
                  showErrorMessage={
                    formik.errors?.datosDelNegocio?.rentaMensual
                  }
                  errorMessage={formik.errors?.datosDelNegocio?.rentaMensual}
                />
              </>
            )}
            <NumberWithError
              name="datosDelNegocio.totalNominaMensual"
              value={formik.values?.datosDelNegocio?.totalNominaMensual}
              onValueChange={(values) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.totalNominaMensual
                ) {
                  delete newErrors?.datosDelNegocio?.totalNominaMensual;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue(
                  "datosDelNegocio.totalNominaMensual",
                  values.value
                );
              }}
              type="text"
              label="Pago mensual estimado de tu nómina *"
              allowNegative={false}
              showErrorMessage={
                formik.errors?.datosDelNegocio?.totalNominaMensual
              }
              errorMessage={formik.errors?.datosDelNegocio?.totalNominaMensual}
            />
            <div className="d-flex gap-2">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(SECCION_INFORMACION_NEGOCIO);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={formik.isSubmitting}>
                Siguiente
              </PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesInfoNegocioPM;

function initialValues(modeloPm, axiosData) {
  let datosDelNegocio = modeloPm.datosDelNegocio;
  return {
    idCliente: modeloPm.idCliente,
    direccion: {
      idDireccion: modeloPm.direccion.idDireccion,
    },
    datosDelNegocio: {
      idDatosDelNegocio: datosDelNegocio.idDatosDelNegocio,
      importaExportaId: datosDelNegocio.importaExportaId
        ? datosDelNegocio.importaExportaId
        : "",
      importarExportarObj: datosDelNegocio.importaExportaId
        ? axiosData.domicilioFiscal.importaExportaOpciones.filter(
            (e) => e.id === datosDelNegocio.importaExportaId
          )
        : [{ nombre: "" }],
      sectorId: datosDelNegocio.sectorId ? datosDelNegocio.sectorId : "",
      sectorObj: datosDelNegocio.sectorId
        ? axiosData.domicilioFiscal.sectorOpciones.filter(
            (e) => e.id === datosDelNegocio.sectorId
          )
        : [{ nombre: "" }],
      giroEmpresarialId: datosDelNegocio.giroEmpresarialId
        ? datosDelNegocio.giroEmpresarialId
        : "",
      giroEmpresarialObj: datosDelNegocio.giroEmpresarialId
        ? axiosData?.domicilioFiscal?.giroEmpresarialOpciones.filter(
            (e) => e.id === datosDelNegocio.giroEmpresarialId
          )
        : [{ nombre: "" }],
      actividadId: datosDelNegocio.actividadId
        ? datosDelNegocio.actividadId
        : "",
      actividadRiesgoApoloId: datosDelNegocio.actividadRiesgoApoloId
        ? datosDelNegocio.actividadRiesgoApoloId
        : "",
      actividadObj: datosDelNegocio.actividadId
        ? axiosData.domicilioFiscal.actividadOpciones.filter(
            (e) => e.id === datosDelNegocio.actividadId
          )
        : [{ detalleClave: "" }],
      actividadRiesgoObj: datosDelNegocio.actividadRiesgoApoloId
        ? axiosData.domicilioFiscal.actividadRiesgoApoloOpciones.filter(
            (e) => e.id === datosDelNegocio.actividadRiesgoApoloId
          )
        : [{ detalleClave: "" }],
      aniosExperienciaSector: datosDelNegocio.aniosExperienciaSector
        ? datosDelNegocio.aniosExperienciaSector
        : "",
      antiguedadActividad: datosDelNegocio.antiguedadActividad
        ? datosDelNegocio.antiguedadActividad
        : "",
      totalEmpleados: datosDelNegocio.totalEmpleados
        ? datosDelNegocio.totalEmpleados
        : "",
      numeroSucursales: datosDelNegocio.numeroSucursales
        ? datosDelNegocio.numeroSucursales
        : "",
      rentaMensual: datosDelNegocio.rentaMensual
        ? datosDelNegocio.rentaMensual
        : "",
      totalNominaMensual: datosDelNegocio.totalNominaMensual
        ? datosDelNegocio.totalNominaMensual
        : "",
      aniosRentado: datosDelNegocio.aniosRentado
        ? datosDelNegocio.aniosRentado
        : "",
      tipoLocalId: datosDelNegocio.tipoLocalId
        ? datosDelNegocio.tipoLocalId
        : "",
      tipoLocalObj: datosDelNegocio.tipoLocalId
        ? axiosData?.domicilioFiscal?.tipoLocalOpciones?.filter(
            (e) => e.id === datosDelNegocio.tipoLocalId
          )
        : [{ nombre: "" }],
    },
  };
}

function validationSchema() {
  return {
    datosDelNegocio: Yup.object().shape({
      importaExportaId: Yup.number().required("Dato requerido."),
      sectorId: Yup.string().required("Dato requerido."),
      giroEmpresarialId: Yup.string().required("Dato requerido."),
      actividadId: Yup.string().required("Dato requerido."),
      actividadRiesgoApoloId: Yup.string().required("Dato requerido."),
      aniosExperienciaSector: Yup.string().required("Dato requerido."),
      antiguedadActividad: Yup.string().required("Dato requerido."),
      totalEmpleados: Yup.string()
        .required("Dato requerido.")
        .test(
          "totalEmpleados",
          "Por favor ingrese un valor entre 1 y 100,000",
          (item, testContext) => {
            return rangoValorValido(
              testContext.parent.totalEmpleados,
              100000,
              1
            );
          }
        ),
      numeroSucursales: Yup.string()
        .required("Dato requerido.")
        .test(
          "numeroSucursales",
          "Por favor ingrese un valor entre 0 y 9999",
          (item, testContext) => {
            return rangoValorValido(
              testContext.parent.numeroSucursales,
              9999,
              0
            );
          }
        ),
      tipoLocalId: Yup.string().required("Dato requerido."),
      aniosRentado: Yup.string().when("tipoLocalId", {
        is: TIPO_LOCAL_RENTA.toString(),
        then: Yup.string().required("Dato requerido."),
      }),
      rentaMensual: Yup.string().when("tipoLocalId", {
        is: TIPO_LOCAL_RENTA.toString(),
        then: Yup.string().test(
          "rentaMensual",
          "Por favor ingrese un valor mayor o igual a $5,000.00..",
          (item, testContext) => {
            return valorMinimo(testContext.parent.rentaMensual, 5000);
          }
        ),
      }),
      totalNominaMensual: Yup.string()
        .required("Dato requerido.")
        .test(
          "totalNominaMensual",
          "Por favor ingrese un valor mayor o igual a 5,000.00..",
          (item, testContext) => {
            return valorMinimo(testContext.parent.totalNominaMensual, 5000);
          }
        ),
    }),
  };
}
