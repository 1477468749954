import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";
import { api_save_datos_generales_PFAE } from "../../../../api/api_servicios_rest";
import {
  GENERO_FEMENINO,
  SECCION_GENERALES,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  ID_PAIS_MEXICO,
  STATUS_EXITO
} from "../../../../utils/constantes";
import {
  compareDatesOrigin,
  dependeintesMajor23,
  fechaMaxMayorEdadYYYMMDD,
  fechaMinEdadYYYMMDD,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
} from "../../../../utils/validaciones";
import {
  textToUpperCase,
  validDate,
  validateValue,
  validarTextoConEnies,
} from "../../../../functions/fieldFunctions";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import {
  sessionStorageContinuarSolicitud,
  sessionStorageIdSolicitud,
  sessionStorageAutoServicio,
} from "../../../../config/Variables";

import { mensajeMostrarUsuario } from "../../../../utils/mensajesServicios";
import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import { PrimaryButton } from "../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../helpers/alertas/alertas";

const DatosGeneralesPFAE = ({
  modeloPfae,
  axiosData,
  setModeloPfae,
  setFolioSolicitud,
  setStyleHr,
  setLoading,
  handleSeccionPyme,
  webView,
}) => {
  const [totalDependientes, setTotalDependientes] = useState("");
  const [totalDependientesMenores23, setTotalDependientesMenores23] =
    useState("");

  const formik = useFormik({
    initialValues: initialValues(modeloPfae, axiosData),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(
      validationSchema(totalDependientesMenores23, totalDependientes)
    ),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      if (modeloPfae.idCliente !== null && formik.values.idCliente == null) {
        formik.setFieldValue("idCliente", modeloPfae.idCliente);
      }
      salvarDatosGeneralesPFAE(formData);
    },
  });

  const salvarDatosGeneralesPFAE = async (datosGeneralesPFAE) => {
    try {
      const response = await api_save_datos_generales_PFAE(datosGeneralesPFAE);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idSolicitud: data.solicitudId,
            tranNumeroFolio: data.solicitudFolio,
            idCliente: data.clienteId,
            direccion: {
              ...modeloPfae.direccion,
              idDireccion: data.direccionClienteId,
            },
            domicilioFiscal: {
              ...modeloPfae.domicilioFiscal,
              idDatosDelNegocio: data.domicilioFiscalId,
              actividadId: data.actividadId,
            },
            generales: data.generales,
          });
          setFolioSolicitud(data.solicitudFolio);
          setStyleHr("block");
          handleSeccionPyme(SECCION_GENERALES);

          const continuarSolicitud = sessionStorage.getItem(
            sessionStorageContinuarSolicitud
          );

          if (continuarSolicitud === "false") {
            sessionStorage.setItem(sessionStorageIdSolicitud, data.solicitudId);
            sessionStorage.setItem(sessionStorageContinuarSolicitud, true);
          }
        } else {
          setLoading(false);
          if (MENSAJE_ERROR_TOKEN_INVALIDO == data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);
            sweetAlertError(mensajeError);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modeloPfae.idCliente) {
      const dependientesMajor23Opc = modeloPfae.generales
        ?.dependientesEconomicosMenores23
        ? axiosData?.generales?.dependientesMenores23Anios?.filter(
            (e) =>
              e.id === modeloPfae.generales?.dependientesEconomicosMenores23
          )
        : "";
      const dependientesEconomicoOpc = modeloPfae.generales
        ?.dependientesEconomicos
        ? axiosData?.generales?.dependientes?.filter(
            (e) => e.id === modeloPfae.generales?.dependientesEconomicos
          )
        : "";
      setTotalDependientesMenores23(
        dependientesMajor23Opc !== ""
          ? dependientesMajor23Opc[0].descripcion
          : 0
      );
      setTotalDependientes(
        dependientesEconomicoOpc !== ""
          ? dependientesEconomicoOpc[0].descripcion
          : 0
      );
    }
  }, []);

  return (
    <>
      <form name="form-grales" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Información general</h2>
          <FormLayout>
            <InputWithError
              name="generales.nombre"
              label="Nombre *"
              value={formik.values?.generales?.nombre}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.nombre
                ) {
                  delete newErrors?.generales?.nombre;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue("generales.nombre", e.target.value);
                }
              }}
              type="text"
              InputProps={{ maxLength: 30 }}
              showErrorMessage={formik.errors?.generales?.nombre}
              errorMessage={formik.errors?.generales?.nombre}
            />
            <InputWithError
              label="Apellido paterno *"
              name="generales.apellidoPaterno"
              value={formik.values?.generales?.apellidoPaterno}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.apellidoPaterno
                ) {
                  delete newErrors?.generales?.apellidoPaterno;
                  formik.setErrors(newErrors);
                }
                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "generales.apellidoPaterno",
                    e.target.value
                  );
                }
              }}
              type="text"
              InputProps={{ maxLength: 20 }}
              showErrorMessage={formik.errors?.generales?.apellidoPaterno}
              errorMessage={formik.errors?.generales?.apellidoPaterno}
            />
            <InputWithError
              label="Apellido materno"
              name="generales.apellidoMaterno"
              value={formik.values?.generales?.apellidoMaterno}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.apellidoMaterno
                ) {
                  delete newErrors?.generales?.apellidoMaterno;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "generales.apellidoMaterno",
                    e.target.value
                  );
                }
              }}
              type="text"
              InputProps={{ maxLength: 20 }}
              showErrorMessage={formik.errors?.generales?.apellidoMaterno}
              errorMessage={formik.errors?.generales?.apellidoMaterno}
            />
            <InputWithError
              type="date"
              label="Fecha de nacimiento *"
              name="generales.fechaNacimiento"
              locale="es"
              value={formik.values?.generales?.fechaNacimientoInput}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.fechaNacimiento
                ) {
                  delete newErrors?.generales?.fechaNacimiento;
                  formik.setErrors(newErrors);
                }
                if (validDate(e.target.value)) {
                  let array = e.target.value.split("-").reverse().join("-");
                  formik.setFieldValue("generales.fechaNacimiento", array);
                  formik.setFieldValue(
                    "generales.fechaNacimientoInput",
                    e.target.value
                  );
                }
              }}
              InputLabelProps={{ shrink: true }}
              showErrorMessage={formik.errors?.generales?.fechaNacimiento}
              errorMessage={formik.errors?.generales?.fechaNacimiento}
            />
            <SelectWithError
              label="País de nacimiento *"
              name="generales.paisNacimientoId"
              value={{
                label:
                  formik?.values?.generales?.paisNacimientoObj?.[0]?.nombre,
                value:
                  formik?.values?.generales?.paisNacimientoObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.paisNacimientoId
                ) {
                  delete newErrors?.generales?.paisNacimientoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("generales.paisNacimientoId", e.id);
                formik.setFieldValue("generales.paisNacimientoObj", [e]);
              }}
              options={axiosData?.generales?.paises?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.generales?.paisNacimientoId}
              errorMessage={formik.errors?.generales?.paisNacimientoId}
            />
            {formik.values?.generales?.paisNacimientoId !== "" &&
              parseInt(formik.values?.generales?.paisNacimientoId) === ID_PAIS_MEXICO && (
              <SelectWithError
                label="Estado de nacimiento *"
                name="generales.estadoNacimientoId"
                value={{
                  label:
                    formik?.values?.generales?.estadoNacimientoObj?.[0]?.nombre,
                  value:
                    formik?.values?.generales?.estadoNacimientoObj?.[0]?.nombre,
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.generales !== undefined &&
                    newErrors?.generales?.estadoNacimientoId
                  ) {
                    delete newErrors?.generales?.estadoNacimientoId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue("generales.estadoNacimientoId", e.id);
                  formik.setFieldValue("generales.estadoNacimientoObj", [e]);
                }}
                options={axiosData.generales.estados.map((item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                })}
                showErrorMessage={formik.errors?.generales?.estadoNacimientoId}
                errorMessage={formik.errors?.generales?.estadoNacimientoId}
              />
            )}

            <SelectWithError
              label="Genero *"
              name="generales.generoId"
              value={{
                label: formik.values.generales.generoId
                  ? formik.values.generales.generoId == GENERO_FEMENINO
                    ? "Femenino"
                    : "Masculino"
                  : "",
                value: formik.values.generales.generoId
                  ? formik.values.generales.generoId == GENERO_FEMENINO
                    ? "Femenino"
                    : "Masculino"
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.generoId
                ) {
                  delete newErrors?.generales?.generoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("generales.generoId", e.id);
              }}
              options={axiosData?.generales?.generoOpciones?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.generales?.generoId}
              errorMessage={formik.errors?.generales?.generoId}
            />
            <InputWithError
              label="RFC *"
              name="generales.rfc"
              value={formik.values?.generales?.rfc}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.rfc
                ) {
                  delete newErrors?.generales?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);
                  formik.setFieldValue("generales.rfc", newValue);
                }
              }}
              type="text"
              InputProps={{ maxLength: 13 }}
              showErrorMessage={formik.errors?.generales?.rfc}
              errorMessage={formik.errors?.generales?.rfc}
            />
            <InputWithError
              label="CURP *"
              name="generales.curp"
              value={formik.values?.generales?.curp}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.curp
                ) {
                  delete newErrors?.generales?.curp;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);
                  formik.setFieldValue("generales.curp", newValue);
                }
              }}
              type="text"
              InputProps={{ maxLength: 18 }}
              showErrorMessage={formik.errors?.generales?.curp}
              errorMessage={formik.errors?.generales?.curp}
            />
            <InputWithError
              label="Número de identificación oficial *"
              name="generales.folioVerificacionId"
              value={formik.values?.generales?.folioVerificacionId}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.folioVerificacionId
                ) {
                  delete newErrors?.generales?.folioVerificacionId;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "generales.folioVerificacionId",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              type="text"
              tooltip="Captura el número de folio de tu identificación, si es INE lo encontrarás en la parte posterior de la misma, después de las letras IDMEX"
              showErrorMessage={formik.errors?.generales?.folioVerificacionId}
              errorMessage={formik.errors?.generales?.folioVerificacionId}
            />
            <SelectWithError
              label="Nacionalidad *"
              name="generales.nacionalidadId"
              value={{
                label: formik?.values?.generales?.nacionalidadObj?.[0]?.nombre,
                value: formik?.values?.generales?.nacionalidadObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.nacionalidadId
                ) {
                  delete newErrors?.generales?.nacionalidadId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("generales.nacionalidadId", e.id);
                formik.setFieldValue("generales.nacionalidadObj", [e]);
              }}
              options={axiosData?.generales?.nacionalidades?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={formik.errors?.generales?.nacionalidadId}
              errorMessage={formik.errors?.generales?.nacionalidadId}
            />
            <InputWithError
              label="Correo electrónico *"
              name="generales.correoElectronico"
              value={formik.values?.generales?.correoElectronico}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.correoElectronico
                ) {
                  delete newErrors?.generales?.correoElectronico;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "generales.correoElectronico",
                  e.target.value
                );
              }}
              type="email"
              InputProps={{ maxLength: 50 }}
              showErrorMessage={formik.errors?.generales?.correoElectronico}
              errorMessage={formik.errors?.generales?.correoElectronico}
            />
            <InputWithError
              label="Teléfono *"
              name="generales.telefono"
              value={formik.values?.generales?.telefono}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.telefono
                ) {
                  delete newErrors?.generales?.telefono;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.generales?.telefono
                );
                formik.setFieldValue("generales.telefono", numTelefono);
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={formik.errors?.generales?.telefono}
              errorMessage={formik.errors?.generales?.telefono}
            />
            <InputWithError
              label="Celular *"
              name="generales.celular"
              value={formik.values?.generales?.celular}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.celular
                ) {
                  delete newErrors?.generales?.celular;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.generales?.celular
                );
                formik.setFieldValue("generales.celular", numTelefono);
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={formik.errors?.generales?.celular}
              errorMessage={formik.errors?.generales?.celular}
            />
            <SelectWithError
              label="Estado civil *"
              name="generales.estadoCivilId"
              value={{
                label: formik?.values?.generales?.estadoCivilObj?.[0]?.nombre,
                value: formik?.values?.generales?.estadoCivilObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.estadoCivilId
                ) {
                  delete newErrors?.generales?.estadoCivilId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("generales.estadoCivilId", e.id);
                formik.setFieldValue("generales.estadoCivilObj", [e]);
              }}
              options={axiosData?.generales?.estadoCivilOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={formik.errors?.generales?.estadoCivilId}
              errorMessage={formik.errors?.generales?.estadoCivilId}
            />
            <SelectWithError
              label="Cantidad de dependientes menores de 23 años *"
              name="generales.dependientesEconomicosMenores23"
              value={{
                label:
                  formik?.values?.generales
                    ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
                value:
                  formik?.values?.generales
                    ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.dependientesEconomicosMenores23
                ) {
                  delete newErrors?.generales?.dependientesEconomicosMenores23;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "generales.dependientesEconomicosMenores23",
                  e.id
                );
                formik.setFieldValue(
                  "generales.dependientesEconomicosMenores23Obj",
                  [e]
                );
                setTotalDependientesMenores23(e.descripcion);
              }}
              options={axiosData?.generales?.dependientesMenores23Anios?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.descripcion,
                    value: item.descripcion,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.generales?.dependientesEconomicosMenores23
              }
              errorMessage={
                formik.errors?.generales?.dependientesEconomicosMenores23
              }
            />
            <SelectWithError
              label="Cantidad total de dependientes *"
              name="generales.dependientesEconomicos"
              value={{
                label:
                  formik?.values?.generales?.dependientesEconomicosObj?.[0]
                    ?.descripcion,
                value:
                  formik?.values?.generales?.dependientesEconomicosObj?.[0]
                    ?.descripcion,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.dependientesEconomicos
                ) {
                  delete newErrors?.generales?.dependientesEconomicos;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("generales.dependientesEconomicos", e.id);
                formik.setFieldValue("generales.dependientesEconomicosObj", [
                  e,
                ]);
                setTotalDependientes(e.descripcion);
              }}
              options={axiosData?.generales?.dependientes?.map((item) => {
                return {
                  ...item,
                  label: item.descripcion,
                  value: item.descripcion,
                };
              })}
              showErrorMessage={
                formik.errors?.generales?.dependientesEconomicos
              }
              errorMessage={formik.errors?.generales?.dependientesEconomicos}
            />
            <SelectWithError
              label="Nivel educativo *"
              menuPlacement="top"
              name="generales.nivelEducativoId"
              value={{
                label:
                  formik?.values?.generales?.nivelEducativoObj?.[0]?.nombre,
                value:
                  formik?.values?.generales?.nivelEducativoObj?.[0]?.nombre,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.nivelEducativoId
                ) {
                  delete newErrors?.generales?.nivelEducativoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue("generales.nivelEducativoId", e.id);
                formik.setFieldValue("generales.nivelEducativoObj", [e]);
              }}
              options={axiosData?.generales?.nivelEducativoOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={formik.errors?.generales?.nivelEducativoId}
              errorMessage={formik.errors?.generales?.nivelEducativoId}
            />
          </FormLayout>
        </div>
        <div className="d-flex justify-content-center mb-2 gap-2 generales--buttons">
          <PrimaryButton type="submit">Siguiente</PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesPFAE;

function initialValues(modeloPfae, axiosData) {
  let generales = modeloPfae.generales;
  return {
    idCliente: modeloPfae.idCliente,
    idDatosOperacion: modeloPfae.idDatosOperacion,
    idDatosClienteTemporal: modeloPfae.idDatosClienteTemporal,
    idDatosTempAutoservicio: parseInt(
      sessionStorage.getItem(sessionStorageAutoServicio)
    ),
    generales: {
      nombre: generales.nombre ? generales.nombre : "",
      apellidoPaterno: generales.apellidoPaterno
        ? generales.apellidoPaterno
        : "",
      apellidoMaterno: generales.apellidoMaterno
        ? generales.apellidoMaterno
        : "",
      fechaNacimiento:
        modeloPfae?.idCliente == null && generales.fechaNacimiento
          ? generales.fechaNacimiento.split("-").reverse().join("-")
          : modeloPfae?.idCliente !== null && generales?.fechaNacimiento
          ? generales.fechaNacimiento
          : "",
      fechaNacimientoInput:
        modeloPfae?.idCliente == null && generales.fechaNacimiento
          ? generales.fechaNacimiento
          : modeloPfae?.idCliente !== null && generales?.fechaNacimiento
          ? generales.fechaNacimiento.split("-").reverse().join("-")
          : "",
      paisNacimientoId: generales.paisNacimientoId
        ? generales.paisNacimientoId
        : "",
      paisNacimientoObj: generales.paisNacimientoId
        ? axiosData?.generales?.paises?.filter(
            (e) => e.id === generales.paisNacimientoId
          )
        : [{ nombre: undefined }],
      estadoNacimientoId: generales.estadoNacimientoId
        ? generales.estadoNacimientoId
        : "",
      estadoNacimientoObj: axiosData?.generales?.estados?.filter(
        (e) => e.id === generales.estadoNacimientoId
      ),
      generoId: generales.generoId ? generales.generoId : "",
      rfc: generales.rfc ? generales.rfc : "",
      curp: generales.curp ? generales.curp : "",
      folioVerificacionId: generales.folioVerificacionId
        ? generales.folioVerificacionId
        : "",
      excepcionesRfc: generales.excepcionesRfc
        ? generales.excepcionesRfc
        : false,
      edad: generales.edad ? generales.edad : "",
      correoElectronico: generales.correoElectronico
        ? generales.correoElectronico
        : "",
      telefono: generales.telefono ? generales.telefono : "",
      celular: generales.celular ? generales.celular : "",
      estadoCivilId: generales.estadoCivilId ? generales.estadoCivilId : "",
      estadoCivilObj: axiosData?.generales?.estadoCivilOpciones?.filter(
        (e) => e.id === generales.estadoCivilId
      ),
      dependientesEconomicosMenores23: generales.dependientesEconomicosMenores23
        ? generales.dependientesEconomicosMenores23
        : "",
      dependientesEconomicosMenores23Obj:
        axiosData?.generales?.dependientesMenores23Anios?.filter(
          (e) => e.id === generales.dependientesEconomicosMenores23
        ),
      dependientesEconomicos: generales.dependientesEconomicos
        ? generales.dependientesEconomicos
        : "",
      dependientesEconomicosObj: axiosData?.generales?.dependientes?.filter(
        (e) => e.id === generales.dependientesEconomicos
      ),
      nacionalidadId: generales.nacionalidadId ? generales.nacionalidadId : "",
      nacionalidadObj: axiosData?.generales?.nacionalidades?.filter(
        (e) => e.id === generales.nacionalidadId
      ),
      nivelEducativoId: generales.nivelEducativoId
        ? generales.nivelEducativoId
        : "",
      nivelEducativoObj: generales.nivelEducativoId
        ? axiosData?.generales?.nivelEducativoOpciones?.filter(
            (e) => e.id === generales.nivelEducativoId
          )
        : "",
      relacionRamaGobierno: generales.relacionRamaGobierno
        ? generales.relacionRamaGobierno
        : false,
      paisResidenciaFiscal2: generales.paisResidenciaFiscal2
        ? generales.paisResidenciaFiscal2
        : false,
      sic: generales.sic ? generales.sic : "",
      residenciaUsa: generales.residenciaUsa ? generales.residenciaUsa : false,
      esAccionistaOtraEmpresa: generales.esAccionistaOtraEmpresa
        ? generales.esAccionistaOtraEmpresa
        : false,
      codigoSmsBanorteId: generales?.codigoSmsBanorteId
        ? generales.codigoSmsBanorteId
        : "",
    },
    banorteDatosDelCliente: {
      personaJuridica: modeloPfae.banorteDatosDelCliente.personaJuridica,
      consultaServicioCodigo:
        modeloPfae.banorteDatosDelCliente.consultaServicioCodigo,
      consultaServicioDetalle:
        modeloPfae.banorteDatosDelCliente.consultaServicioDetalle,
      numeroCliente: modeloPfae.banorteDatosDelCliente.numeroCliente,
      segmento: modeloPfae.banorteDatosDelCliente.segmento,
      indiceMigracion: modeloPfae.banorteDatosDelCliente.indiceMigracion,
      indiceBancario: modeloPfae.banorteDatosDelCliente.indiceBancario,
      firmaContratoMultiple:
        modeloPfae.banorteDatosDelCliente.firmaContratoMultiple,
    },
  };
}

function validationSchema(totalDependientesMenores23, totalDependientes) {
  return {
    generales: Yup.object().shape({
      nombre: Yup.string()
        .required("Dato requerido.")
        .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
      apellidoPaterno: Yup.string()
        .required("Dato requerido.")
        .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
      apellidoMaterno: Yup.string().matches(
        /^[aA-zZÑñ\s]+$/,
        "Solo se permiten letras "
      ),
      fechaNacimiento: Yup.string()
        .required("Dato requerido.")
        .test(
          "fechaNacimiento",
          "Debe ser mayor a 18 años y menor a 70 años.",
          (value) =>
            compareDatesOrigin(
              fechaMaxMayorEdadYYYMMDD(),
              fechaMinEdadYYYMMDD(),
              value
            )
        ),
      paisNacimientoId: Yup.number().required("Dato requerido."),
      nacionalidadId: Yup.number().required("Dato requerido."),
      estadoNacimientoId: Yup.string().when("paisNacimientoId", {
        is: (value) => value === ID_PAIS_MEXICO,
        then: Yup.string().required("Dato requerido."),
      }),
      generoId: Yup.number().required("Dato requerido."),
      rfc: Yup.string().required("Dato requerido."),
      curp: Yup.string().required("Dato requerido."),
      folioVerificacionId: Yup.string().required("Dato requerido."),
      correoElectronico: Yup.string()
        .email("Correo eléctronico inválido")
        .required("Dato requerido."),
      telefono: Yup.string()
        .required("Dato requerido.")
        .test(
          "telefono",
          "Teléfono capturado de forma incorrecta.",
          (item, testContext) => {
            return validarNumeroTelefono(testContext.parent.telefono);
          }
        ),
      celular: Yup.string()
        .required("Dato requerido.")
        .test(
          "celular",
          "Celular capturado de forma incorrecta.",
          (item, testContext) => {
            return validarNumeroTelefono(testContext.parent.celular);
          }
        ),
      estadoCivilId: Yup.number().required("Dato requerido."),
      dependientesEconomicosMenores23: Yup.number().required("Dato requerido."),
      dependientesEconomicos: Yup.number()
        .required("Dato requerido.")
        .test(
          "dependientesEconomicos",
          "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
          (item, testContext) => {
            return dependeintesMajor23(
              totalDependientesMenores23,
              totalDependientes
            );
          }
        ),
      nivelEducativoId: Yup.number().required("Dato requerido."),
    }),
  };
}
