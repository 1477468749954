import axios from "axios";
import {
  api_get_cat_simple,
  api_limites_monto_credito,
  api_save_datosOperacion,
} from "../../../api/api_servicios_rest_crediactivo";
import {
  sweetAlertError,
  sweetAlertWarning,
} from "../../../components/helpers/alertas/alertas";
import {
  sessionStorageDatosOperacion,
  sessionStorageMontoMaximoCredito,
  sessionStorageMontoMinimoCredito,
} from "../../../config/Variables";
import {
  STATUS_EXITO,
} from "../../../utils/constantes";

export const optionsRevolvente = [
  {
    meses: 12,
    mensualidad:
      "Por cada 100 mil pesos que dispongas de tu línea, al mes deberás pagar intereses por $1,657 pesos. /n *Interés calculado con una tasa de referencia del 19.76%",
    card: "1",
  },
];

export const obtenerLimiteMontoHelper = async () => {
  try {
    const objLimiteMonto = {
      tasaAnual: "",
      iva: 0,
      montoMinimoCredito: 0,
      montoMaximoCredito: 0,
      montoMinimoFormat: "",
      montoMaximoFormat: "",
      valorFactura: "",
    };

    const response = await api_limites_monto_credito();
    if (response.statusText === "OK" || response.status === STATUS_EXITO) {
      const data = response.data;
      if (data) {
        sessionStorage.setItem(
          sessionStorageMontoMinimoCredito,
          data.montoMinimoDeCredito
        );

        sessionStorage.setItem(
          sessionStorageMontoMaximoCredito,
          data.montoMaximoDeCredito
        );

        objLimiteMonto.tasaAnual = data?.tasaAnual;
        objLimiteMonto.iva = data?.iva;
        let montoMin = data.montoMinimoDeCredito;
        let montoMax = data.montoMaximoDeCredito;

        if (montoMin && montoMax) {
          let montoMinString =
            "$" + parseFloat(montoMin).toLocaleString("es-MX");
          let montoMaxString =
            "$" + parseFloat(montoMax).toLocaleString("es-MX");

          objLimiteMonto.montoMinimoCredito = parseFloat(montoMin);
          objLimiteMonto.montoMaximoCredito = parseFloat(montoMax);
          objLimiteMonto.montoMinimoFormat = montoMinString;
          objLimiteMonto.montoMaximoFormat = montoMaxString;
          objLimiteMonto.valorFactura = montoMinString;
        }

        return objLimiteMonto;
      }
    }
    sweetAlertError("Ocurrió un error al obtener límites del monto crédito");
  } catch (error) {
    console.error(error);
  }
};

export const obtenerCatCreditoSimple = async (datosCredito) => {
  try {
    const response = await api_get_cat_simple(datosCredito);
    if (response.statusText === "OK" || response.status === STATUS_EXITO) {
      const data = response?.data;
      const cat = data?.cat * 100;
      return cat;
    } else{
      sweetAlertWarning("Ocurrió un error al obtener el cat del crédito");
    }
    
  } catch (error) {
    console.error(error);
  }
};

export const obtenertablaAmortizacion = async (
  path,
  letPlazo,
  valorFactura
) => {
  try {
    const config = {
      method: "post",
      url: `${path}/rest/obtenerTablaAmortizacion?plazo=${letPlazo}&monto=${parseInt(
        valorFactura.split("$")[1].replace(/,/g, "")
      )}`,
      headers: {},
    };

    const response = await axios(config);
    if (response.data.success) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const handlePesosReturn = (value, plazo, tasaAnualAts) => {
  if (value) {
    value = (Number(value.replace(/\D/g, "")) || "")
      .toLocaleString()
      .replaceAll(".", ",");
    value = calculoMensualidad(value, plazo, tasaAnualAts);
    return value;
  } else {
    return "";
  }
};

const calculoMensualidad = (montoCredito, plazo, tasaAnualAts) => {
  if (montoCredito !== "") {
    let tasaConIva;
    let tasaAnual;
    let pagoCalculo;
    let interesAnual = tasaAnualAts;
    montoCredito = montoCredito.toString().replaceAll(",", "");
    if (Number.isNaN(montoCredito) === false) {
      montoCredito = parseFloat(montoCredito);
      tasaConIva = interesAnual * 1.16;
      tasaAnual = tasaConIva / 12;
      pagoCalculo =
        parseFloat(montoCredito) /
        ((1 - (1 + tasaAnual) ** -plazo) / tasaAnual);
      pagoCalculo = parseFloat(pagoCalculo.toFixed(2));
      pagoCalculo = pagoCalculo.toLocaleString("es-MX");
      return pagoCalculo;
    }
  } else {
    return 0.0;
  }
};

export const siguienteHelper = async (data) => {
  try {
    const response = await api_save_datosOperacion(data);
    if (response.statusText === "OK" || response.status === STATUS_EXITO) {
      const data = response.data;
      if (data.success) {
        sessionStorage.setItem(
          sessionStorageDatosOperacion,
          data.datosDeOperacionId
        );
        return "/prospeccionCrediactivo";
      }
      sweetAlertWarning(data?.mensaje);
      return;
    }
    sweetAlertWarning("Ocurrió un error al guardar datos cliente");
  } catch (error) {
    console.error(error);
  }
};
