export const KEY_PM = "PM";
export const KEY_PFAE = "PFAE";
export const KEY_PF = "PF";

export const ID_PFAE = 1;
export const ID_PM = 2;
export const ID_PF = 3;

export const SECTOR_AGROPECUARIO_CAZA_Y_PESCA = 1;
export const SECTOR_MINERIA = 2;
export const ANIOS_NEGOCIO_MENOR_2_ANIOS = 1;

//PFAE Y PM
export const SECCION_ZERO = 0;
export const SECCION_GENERALES = 1;
export const SECCION_ECONOMICOS = 5;
export const SECCION_OBLIGADO_SOLIDARIO = 8;
export const SECCION_CONYUGE_OS_UNO = 9;
export const SECCION_OS_DOS = 10;
export const SECCION_CONYUGE_OS_DOS = 11;
export const SECCION_OS_TRES = 12;
export const SECCION_CONYUGE_OS_TRES = 13;
export const SECCION_OS_CUATRO = 14;
export const SECCION_PATRIMONIO = 16;
export const SECCION_HISTORIAL_CREDITICIO = 17;
export const SECCION_CARGA_DOCUMENTOS = 18;

//PFAE
export const SECCION_DOMICILIO = 2;
export const SECCION_DOMICILIO_FISCAL = 3;
export const SECCION_DOMICILIO_COMERCIAL = 4;
export const SECCION_SOLICITANTE_CONYUGE = 20;
export const SECCION_CONYUGE_OS_CUATRO = 15;
export const SECCION_OS_CINCO = 22;

//PM
export const SECCION_REPRESENTANTE_LEGAL = 2;
export const SECCION_DOMICILIO_NEGOCIO = 3;
export const SECCION_INFORMACION_NEGOCIO = 4;
export const SECCION_SOCIOS = 6;
export const SECCION_PRINCIPAL_ACCIONISTA = 7;
export const SECCION_DOMICILIO_COMERCIAL_PM = 19;
export const SECCION_PA_CONYUGE_PM = 20;

//
export const SECCION_HISTORIAL_SOLICITANTE = 1;
export const SECCION_HISTORIAL_SOLICITANTE_COYUGE = 2;
export const SECCION_HISTORIAL_PA = 2;
export const SECCION_HISTORIAL_PA_COYUGE = 3;

//
export const SECCION_CARGA_DOCUMENTOS_SOLICITANTE = 1;
export const SECCION_CARGA_DOCUMENTOS_PA = 2;

export const SUB_MENU_CADENA_GRALES = "generales";
export const SUB_MENU_CADENA_OS = "oSolidario";
export const SUB_MENU_CADENA_SOLICITANTE_CONYUGE = "solicitanteConyuge";
export const SUB_MENU_CADENA_PA = "pAccionista";
export const SUB_MENU_CADENA_PA_CONYUGE = "pAccionistaConyuge";
export const SUB_MENU_CADENA_CARGA_DOCUMENTOS = "cargadocumentos";
export const SUB_MENU_CADENA_SOCCIOS = "socios";
export const SUB_MENU_CADENA_HISTORIAL = "historialcrediticio";
export const TIPO_DOMICILIO_RENTA = 3;
export const TIPO_LOCAL_RENTA = 10;

export const TIPO_DEPARTAMENTO_APARTAMENTO = "1";
export const TIPO_VIVIENDA_EDIFICIO = "3";
export const NACIONALIDAD_MEXICAN0 = 4;

export const OS_CERO = 0;
export const OS_UNO = 1;
export const OS_DOS = 2;
export const OS_TRES = 3;
export const OS_CUATRO = 4;
export const OS_CINCO = 5;

export const NAME_INPUT_DATO_DOMICILIO_TIPO_DOMICILIO = "tipoDomicilioId";
export const NAME_INPUT_DATO_DOMICILIO_TIPO_VIVIENDA = '"tipoViviendaId"';

export const INE_CERTIFICADA = true;
export const APLICA_INE_COMPROBANTE_OS_NO = false;
export const EXCEPCIONES_RFC = false;

export const REGRESAR_A_COTIZADOR = true;
export const PESTANIA_GENERAL = 1;
export const PESTANIA_REPRESENTANTE_LEGAL = 2;
export const PESTANIA_DOMICILIO_NEGOCIO = 3;
export const PESTANIA_INFO_NEGOCIO = 4;
export const PESTANIA_OS_1 = 8;
export const PESTANIA_OS_2 = 10;
export const PESTANIA_OS_3 = 12;
export const PESTANIA_OS_4 = 14;
export const PESTANIA_DOMICILIO_COMERCIAL = 19;
export const PESTANIA_SOLICITANTE_CONYUGE = 20;
export const PESTANIA_OS_UNO_CONYUGE_PM = 22;
export const PESTANIA_OS_UNO_CONYUGE_PFAE = 24;
export const PANTALLA_CONSULTA_AUTENTICADA = 26;
export const PANTALLA_CONSULTA_TRADICIONAL = 27;
export const PANTALLA_CONSULTA_POR_NIP = 28;

export const ESTADO_CIVIL_SOCIEDAD_CONYUGAL = 2;

export const OFERTA_PERSONALIZADA_1 = 1;
export const OFERTA_PERSONALIZADA_2 = 2;
export const OFERTA_PERSONALIZADA_3 = 3;
export const OFERTA_PERSONALIZADA_4 = 4;
export const OFERTA_PERSONALIZADA_5 = 5;

export const PANTALLA_INFORMACION_GRAL = 1;
export const PANTALLA_INFORMACION_EMPRESA= 2;
export const PANTALLA_VALIDACION_NUMERO_CORREO = 3;

export const TIPO_COMPROBANTE_INGRESOS_OTRO = 4;

export const TIPO_PARTICIPANTE_OS = 4;

export const OS_MAX_PFAE = 5;

export const OS_MAX_PM = 4;

export const CASADO_SOCIEDAD_CONYUGAL = 2;
export const PARTICIPANTE_SOLICITANTE = 1;
export const PARTICIPANTE_PRINCIPAL_ACCIONISTA = 2;
export const PARTICIPANTE_PRINCIPAL_ACCIONISTA_CONYUGE = 3;
export const PARTICIPANTE_OS1 = 4;

export const TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_FRENTE = "1";
export const TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL_REVERSO = "169";
export const TIPO_DOCUMENTO_AUTORIZACION_BURO = "51";
export const TIPO_DOCUMENTO_CONSTANCIA_SITUACION_FISCAL = "9";
export const TIPO_DOCUMENTO_ACTA_CONSTITUTIVA = "67";
export const TIPO_DOCUMENTO_AVISO_DE_PRIVACIDAD_REPRESENTANTE_LEGAL = "66";
export const TIPO_DOCUMENTO_COMPROBANTE_DOMICILIO = "2";
export const TIPO_DOCUMENTO_COMPROBANTE_DOMICILIO_FISCAL = "68";
export const TIPO_DOCUMENTO_IDENTIFICACION_OFICIAL = "identificacionOficial";
export const TIPO_DOCUMENTO_NOMBRE_AUTORIZACION_BURO = "autorizacionBC";

export const PASO_SOLICITUD_HISTORIAL_CREDITICIO = 2;
export const PASO_SOLICITUD_EVALUACION = 3;
export const PASO_SOLICITUD_RECABAR_DOCUMENTOS = 5;

export const PLAZO_CREDITO_REVOLVENTE = 12;

export const ID_CALCULO_RFC_NOMBRE = 1;
export const ID_CALCULO_RFC_APP = 2;
export const ID_CALCULO_RFC_APM = 3;
export const ID_CALCULO_RFC_FECHANAC = 4;
export const ID_CALCULO_RFC_GENERO = 5;
export const ID_CALCULO_RFC_ESTADONAC = 6;

export const GENERO_FEMENINO = 2;

export const EVALUACION_APROBADO = "DEC.APROBADA";
export const SOLICITUD_PRE_APROBADA = "SOLICITUD PRE-APROBADO";
export const SOLICITUD_NO_AUTORIZADA = "NO AUTORIZADO";

export const FORMATOS_PERMITIDOS = ['pdf','jpg','jpeg','png','PDF','JPG','JPEG','PNG'];
export const MENSAJE_FORMATOS_PERMITIDOS = "Solo se permite archivos con formato jpeg, jpg, png, pdf.";
export const MENSAJE_ERROR_TOKEN_INVALIDO = "Su sesión expiró. Debe volver a consultar la solicitud";

export const TIPO_CREDITO_SIMPLE = '1';
export const TIPO_CREDITO_REVOLVENTE = '2';

export const TIPO_TASA_VARIABLE = 2;
export const TIPO_DESTINO_CAPITAL_TRABAJO = 1;

export const ID_PAIS_MEXICO = 1;

export const MAXIMO_BYTES_DOCUMENTO = 10485760; //10MB máximo para documento en carga documento
export const LEYENDA_MAX_MB = "10 MB"

export const MONTO_VALIDO_EXPRESS_SIMPLE_PFAE = 3000000;
export const MONTO_VALIDO_EXPRESS_SIMPLE_PM = 2500000;
export const PLAZO_NO_VALIDO_EXPRESS_SIMPLE = 60;
export const PLAZO_MAX_VALIDO_EXPRESS_SIMPLE = 48;


export const STATUS_EXITO = 200;