import React, { useState, useEffect } from "react";
import {
  ID_PF,
  ID_PFAE,
  KEY_PF,
  KEY_PFAE,
  OS_MAX_PM,
} from "../../../../../utils/constantes";
import DatosObligadoSolidarioConyuge from "./DatosObligadoSolidarioConyuge";
import SelectWithErrorRepresentanteLegal from "../../../../basics/SelectWithError/SelectWithErrorRepresentanteLegal";
import RadioInput from "../../../../basics/radioButton";
import { sweetAlertError } from "../../../../helpers/alertas/alertas";

const ObligadoSolidarioConyuge = ({
  modeloPm,
  setModeloPm,
  axiosData,
  aniosDomicilio,
  mesesDomicilo,
  anios,
  Swal,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setObligadosSolidariosInformacionBasica,
  num_os,
  setLoading,
  seccionActual,
  datosOs,
  setDatosOs,
  setCantidadConyugeOS,
  cantidadConyugeOS,
  cantidadObligadosSolidarios,
  setUltimoPasoSolicitud,
  webView,
}) => {
  const [obligadoSolidarioConyuge, setObligadoSolidarioConyuge] = useState({
    tipoPersona: "",
    idTipoPersona: null,
  });
  const [esObligadoSolidarioConyuge, setEsObligadoSolidarioConyuge] =
    useState(true);
  const [esObligadoSolidarioConyugeBD, setEsObligadoSolidarioConyugeBD] =
    useState(false);

  const validarEsObligadoSolidarioConyuge = (e) => {
    let esOS = JSON.parse(e.target.value);
    if (esOS) {
      if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
        setEsObligadoSolidarioConyuge(JSON.parse(e.target.value));
      } else {
        sweetAlertError("Esta solicitud ya cuenta con el número máximo de obligados solidarios permitidos")
      }
    } else {
      setEsObligadoSolidarioConyuge(JSON.parse(e.target.value));
    }
  };

  useEffect(() => {
    if (
      datosOs.conyuge !== undefined &&
      datosOs.conyuge !== null &&
      datosOs.conyuge.idConyuge !== null &&
      datosOs.conyuge.length !== 0
    ) {
      setEsObligadoSolidarioConyuge(
        datosOs.conyuge.generales.esObligadoSolidario
      );
      setObligadoSolidarioConyuge({
        tipoPersona:
          datosOs.conyuge.generales.tipoPersonaId == ID_PF ? KEY_PF : KEY_PFAE,
        idTipoPersona: datosOs.conyuge.generales.tipoPersonaId,
      });
      setEsObligadoSolidarioConyugeBD(
        datosOs.conyuge.generales.esObligadoSolidario
      );
    }
  }, []);

  const personTypes = [
    { title: "Persona física", rfc: 13, id: ID_PF, type: "PF" },
    {
      title: "Persona física con actividad empresarial",
      rfc: 13,
      id: ID_PFAE,
      type: "PFAE",
    },
  ];

  return (
    <>
      <div className="container">
        <h2 style={{ margin: "2rem 0 1rem 0" }} className="f-s-3 text-center">
          Cónyuge del OS {num_os}
        </h2>
        {esObligadoSolidarioConyuge == true && (
          <p className="p--texto_justify">En esta sección deberás agregar la información de la persona que será tu aval para el crédito. Esta persona debe de ser diferente al solicitante, y en caso de que el solicitante no tenga un bien inmueble que respalde el crédito, el obligado solidario deberá presentarlo</p>
        )}
        {esObligadoSolidarioConyuge == true && (
          <>
            <h3 className="f-s-3 text-center mt-3">Tipo de persona</h3>
            <div className="d-flex justify-content-center gap-3 my-5">
              {personTypes.map((person, index) => {
                return (
                  <div className="d-flex gap-2" key={index}>
                    <RadioInput
                      style={{ padding: "0" }}
                      checked={
                        obligadoSolidarioConyuge.idTipoPersona === person.id
                      }
                      onChange={() => {
                        setObligadoSolidarioConyuge({
                          tipoPersona: person.type,
                          idTipoPersona: person.id,
                        });
                      }}
                    />
                    <label className="text_lightGray">{person.title}</label>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {esObligadoSolidarioConyuge !== "" && (
          <>
            {(esObligadoSolidarioConyuge == false ||
              (esObligadoSolidarioConyuge == true &&
                obligadoSolidarioConyuge.idTipoPersona != null)) && (
              <DatosObligadoSolidarioConyuge
                modeloPm={modeloPm}
                setModeloPm={setModeloPm}
                axiosData={axiosData}
                aniosDomicilio={aniosDomicilio}
                mesesDomicilo={mesesDomicilo}
                anios={anios}
                Swal={Swal}
                esObligadoSolidario={esObligadoSolidarioConyuge}
                esObligadoSolidarioConyugeBD={esObligadoSolidarioConyugeBD}
                setEsObligadoSolidarioConyugeBD={
                  setEsObligadoSolidarioConyugeBD
                }
                idTipoPersonaOSConyuge={obligadoSolidarioConyuge.idTipoPersona}
                setStyleHr={setStyleHr}
                handleSeccionPyme={handleSeccionPyme}
                handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                setObligadosSolidariosInformacionBasica={
                  setObligadosSolidariosInformacionBasica
                }
                num_os={num_os}
                setLoading={setLoading}
                seccionActual={seccionActual}
                datosOs={datosOs}
                setDatosOs={setDatosOs}
                setCantidadConyugeOS={setCantidadConyugeOS}
                cantidadConyugeOS={cantidadConyugeOS}
                setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                webView={webView}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ObligadoSolidarioConyuge;
