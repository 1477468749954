import { api_actualizar_datos_cliente_autoservicio_empresa } from "../../../../../api/api_servicios_rest";
import { sessionStorageAutoServicio } from "../../../../../config/Variables";
import {
  ANIOS_NEGOCIO_MENOR_2_ANIOS,
  ID_PFAE,
  PANTALLA_VALIDACION_NUMERO_CORREO,
  STATUS_EXITO
} from "../../../../../utils/constantes";
import { sweetAlertError } from "../../../../helpers/alertas/alertas";

export const actualizarDatosTemporalesAutoServicioEmpresa = async (
  formData,
  setSteps,
  setLoadingInicio
) => {
  try {
    const response = await api_actualizar_datos_cliente_autoservicio_empresa(
      formData
    );

    if (response.statusText === "OK" || response.status === STATUS_EXITO) {
      const data = response.data;
      if (data.success) {
        setSteps(PANTALLA_VALIDACION_NUMERO_CORREO);
      } else {
        sweetAlertError(data.mensaje);
      }
    } else {
      sweetAlertError("Error al actualizar datos del cliente");
    }
    setLoadingInicio(false);
  } catch (error) {
    console.log(error);
  }
};

export const validationSchemaEmp = (Yup) => {
  return {
    privacyCheck: Yup.boolean().oneOf([true], "Dato requerido."),
    idActividad: Yup.string().required("Dato requerido."),
    idAnioNegocio: Yup.string()
      .required("Dato requerido.")
      .test("idAnioNegocio", "Años de tu negocio no permitido.", (value) => {
        if (value !== undefined && value !== null && value !== "") {
          if (parseInt(value) !== ANIOS_NEGOCIO_MENOR_2_ANIOS) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }),
    edad: Yup.string(),
    curp: Yup.string().when(["idTipoPersona"], {
      is: (idTipoPersona) => parseInt(idTipoPersona) === ID_PFAE,
      then: Yup.string()
        .required("Dato requerido.")
        .min(18, "Por favor ingrese 18 caracteres.")
        .matches(
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          "El CURP es incorrecto."
        )
        .test(
          "curp",
          "La edad debe ser mayor a 25 y menor a 75 años.",
          (item, testContext) => {
            if (
              testContext.parent.edad !== undefined &&
              testContext.parent.edad !== null &&
              testContext.parent.edad !== ""
            ) {
              if (
                parseInt(testContext.parent.edad) <= 25 ||
                parseInt(testContext.parent.edad) > 75
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          }
        ),
    }),
    rfc: Yup.string().when(["idTipoPersona"], {
      is: (idTipoPersona) => parseInt(idTipoPersona) === ID_PFAE,
      then: Yup.string()
        .required("Dato requerido.")
        .test(
          "rfc",
          "Fecha de nacimiento diferente en RFC y CURP.",
          (item, testContext) => {
            if (
              testContext.parent.curp !== undefined &&
              testContext.parent.curp !== null &&
              testContext.parent.curp !== ""
            ) {
              let fechaCurp = testContext.parent.curp.substring(4, 10);
              let fechaRfc = testContext.parent.rfc.substring(4, 10);
              if (fechaCurp === fechaRfc) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        ),
    }),
  };
};

export const initialValuesEmp = (idTipoPersona, curp, rfc) => {
  let idDatosTemp = parseInt(
    sessionStorage.getItem(sessionStorageAutoServicio)
  );
  return {
    idDatosTemporalesAutoservicio: idDatosTemp,
    idTipoPersona: idTipoPersona,
    privacyCheck: false,
    idActividad: "",
    idTipoMotivoCredito: "",
    idAnioNegocio: "",
    curp: curp ? curp : "",
    edad: "",
    fechaNacimientoStr: "",
    idGenero: "",
    idTipoMotivoCreditoObj: [{ nombre: undefined }],
    anioNegocioObj: [{ nombre: undefined }],
    rfc: rfc ? rfc : "",
  };
};
