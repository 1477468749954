import React, { useEffect, useState } from "react";
import { animated } from "react-spring";
import { useFormik } from "formik";
import * as Yup from "yup";
import SocioPrincipalPM from "./SocioPrincipalPM";
import { api_save_socios_principales_PM } from "../../../../../api/api_servicios_rest";
import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import { validarRFCTipoPersona } from "../../../../../utils/validaciones";
import {
  ID_PF,
  ID_PFAE,
  SECCION_SOCIOS,
  ID_PM,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  STATUS_EXITO
} from "../../../../../utils/constantes";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../../helpers/alertas/alertas";

const SociosPrincipalesPM = ({
  cantidadSocios,
  setCantidadSocios,
  transitionSocioUno,
  transitionSocioDos,
  transitionSocioTres,
  transitionSocioCuatro,
  transitionSocioCinco,
  modeloPm,
  setModeloPm,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  Swal,
  setLoading,
  webView,
}) => {
  const formik = useFormik({
    initialValues: initialValues(modeloPm),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarSociosPrincipalesPM(formData).then((response) => setLoading(false));
    },
  });

  const salvarSociosPrincipalesPM = async (datosSocios) => {
    try {
      const response = await api_save_socios_principales_PM(datosSocios);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          if (data.sociosPrincipales !== null) {
            setModeloPm({
              ...modeloPm,
              idCliente: data.clienteId,
              sociosPrincipales: data.sociosPrincipales,
            });
          }

          setStyleHr("block");
          handleSeccionPyme(SECCION_SOCIOS);
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO == data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            sweetAlertError(data?.mensaje);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar socios principales PM");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let socios = modeloPm?.sociosPrincipales;
    if (socios !== null && socios?.length > 0) {
      for (var i = 0; i < socios?.length; i++) {
        if (socios[i]?.tipoDePersonaId === ID_PM) {
          formik.setFieldValue(`sociosPrincipales.${i}.apellidoPaterno`, "");
          formik.setFieldValue(`sociosPrincipales.${i}.apellidoMaterno`, "");
        }
      }
    }
  }, []);

  const [sociosObj, setSociosObj] = useState({
    label: cantidadSocios,
    value: cantidadSocios,
  });

  const eliminarSociosErrorsArray = (reset, nuevaCantidadSocios) => {
    if (reset) {
      switch (cantidadSocios) {
        case 5:
          eliminarArregloSocios(cantidadSocios, nuevaCantidadSocios);
          break;
        case 4:
          eliminarArregloSocios(cantidadSocios, nuevaCantidadSocios);
          break;
        case 3:
          eliminarArregloSocios(cantidadSocios, nuevaCantidadSocios);
          break;
        case 2:
          eliminarArregloSocios(cantidadSocios, nuevaCantidadSocios);
          break;
        default:
          break;
      }
    }
  };

  const eliminarArregloSocios = (cantidadSocios, nuevaCantidadSocios) => {
    let count = 0;
    let arregloSocios = formik?.values?.sociosPrincipales;
    let sociosAEliminar = cantidadSocios - nuevaCantidadSocios;
    formik.setErrors({});
    arregloSocios.map((socios, index) => {
      count += 1;
      if (count <= sociosAEliminar) {
        let values = formik?.values?.sociosPrincipales;
        values.pop();
      }
    });
  };

  return (
    <>
      <form name="form-socios" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Socios principales</h2>
          <FormLayout>
            <SelectWithError
              label="Número de socios con más del 10% de participación"
              value={sociosObj}
              onChange={(e) => {
                setCantidadSocios(e.label);
                setSociosObj(e);
                eliminarSociosErrorsArray(true, e.label);
              }}
              options={[
                {
                  label: 1,
                  value: 1,
                },
                {
                  label: 2,
                  value: 2,
                },
                {
                  label: 3,
                  value: 3,
                },
                {
                  label: 4,
                  value: 4,
                },
                {
                  label: 5,
                  value: 5,
                },
              ]}
            />
          </FormLayout>
          {transitionSocioUno(
            (style, visible) =>
              visible === true && (
                <animated.div style={style}>
                  <SocioPrincipalPM numSocio={0} formik={formik} />
                </animated.div>
              )
          )}
          {transitionSocioDos(
            (style, visible) =>
              visible === true && (
                <animated.div style={style}>
                  <SocioPrincipalPM numSocio={1} formik={formik} />
                </animated.div>
              )
          )}
          {transitionSocioTres(
            (style, visible) =>
              visible === true && (
                <animated.div style={style}>
                  <SocioPrincipalPM numSocio={2} formik={formik} />
                </animated.div>
              )
          )}
          {transitionSocioCuatro(
            (style, visible) =>
              visible === true && (
                <animated.div style={style}>
                  <SocioPrincipalPM numSocio={3} formik={formik} />
                </animated.div>
              )
          )}
          {transitionSocioCinco(
            (style, visible) =>
              visible === true && (
                <animated.div style={style}>
                  <SocioPrincipalPM numSocio={4} formik={formik} />
                </animated.div>
              )
          )}
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4">
          <SecondaryButton
            onClick={(e) => {
              e.preventDefault();
              handleRegresarSeccionPyme(SECCION_SOCIOS);
            }}
          >
            Atrás
          </SecondaryButton>
          <PrimaryButton type="submit">Siguiente</PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default SociosPrincipalesPM;

function initialValues(modeloPm) {
  let socios = modeloPm.sociosPrincipales;
  if (socios !== null && socios.length > 0) {
    return {
      idCliente: modeloPm.idCliente,
      sociosPrincipales: socios,
    };
  } else {
    return {
      idCliente: modeloPm.idCliente,
      sociosPrincipales: [
        {
          idSocioPrincipal: null,
          indice: 0,
          numeroEmpresa: 1,
          numeroSocio: 1,
          tipoDePersonaId: ID_PF,
          nombre: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          porcentajeAccionario: "",
          rfc: "",
          puesto: "",
        },
      ],
    };
  }
}

function validationSchema() {
  return {
    sociosPrincipales: Yup.array().of(
      Yup.object().shape({
        tipoDePersonaId: Yup.number().required("Dato requerido."),
        nombre: Yup.string().required("Dato requerido."),
        apellidoPaterno: Yup.string().when("tipoDePersonaId", {
          is: (tipoDePersonaId) =>
            tipoDePersonaId === ID_PF || tipoDePersonaId === ID_PFAE,
          then: Yup.string().required("Dato requerido."),
        }),
        porcentajeAccionario: Yup.number()
          .required("Dato requerido.")
          .min(1, "Por favor ingrese al menos 1%.")
          .max(100, "Por favor no ingresar más de 100%."),
        puesto: Yup.string().required("Dato requerido."),
        rfc: Yup.string()
          .required("Dato requerido.")
          .test("validaRfc", "El RFC es incorrecto.", (item, testContext) => {
            return validarRFCTipoPersona(
              testContext.parent.rfc,
              testContext.parent.tipoDePersonaId
            );
          }),
      })
    ),
  };
}
