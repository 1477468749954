import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";
import "./css/FormPm.css";
import { api_save_datos_generales_PM } from "../../../../api/api_servicios_rest";
import {
  REGRESAR_A_COTIZADOR,
  SECCION_GENERALES,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  STATUS_EXITO
} from "../../../../utils/constantes";
import {
  pmFechaAltaSHCPVsRfc,
  alphaNumericWithDash,
  fechaActualYYYMMDD,
  obtenerFechaShcpByRfcInput,
} from "../../../../utils/validaciones";
import { mensajeMostrarUsuario } from "../../../../utils/mensajesServicios";

import InputWithError from "../../../basics/InputWithError/InputWithError";
import SelectWithError from "../../../basics/SelectWithError/SelectWithError";
import {
  notSpecialCaracters,
  validateValue,
  textToUpperCase,
  validarTextoConPunto,
} from "../../../../functions/fieldFunctions";

import {
  sessionStorageContinuarSolicitud,
  sessionStorageIdSolicitud,
  sessionStorageAutoServicio,
} from "../../../../config/Variables";
import FormLayout from "../../../helpers/FormLayout/FormLayout";
import { PrimaryButton } from "../../../basics/Buttons/Buttons";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../helpers/alertas/alertas";

const DatosGeneralesPM = ({
  modeloPm,
  setModeloPm,
  handleSeccionPyme,
  anios,
  setFolioSolicitud,
  setStyleHr,
  setRegresarCotizador,
  regresarCotizador,
  setLoading,
  webView,
}) => {
  const [startDate, setStartDate] = useState("");
  const [rfcCliente, setRfcCliente] = useState("");

  const formik = useFormik({
    initialValues: initialValues(modeloPm),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema(rfcCliente)),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);

      if (modeloPm.idCliente !== null && formik.values.idCliente == null) {
        formik.setFieldValue("idCliente", modeloPm.idCliente);
      }

      salvarDatosGeneralesPM(formData);
    },
  });

  const salvarDatosGeneralesPM = async (formData) => {
    try {
      const response = await api_save_datos_generales_PM(formData);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setModeloPm({
            ...modeloPm,
            idSolicitud: data.solicitudId,
            tranNumeroFolio: data.solicitudFolio,
            idCliente: data.clienteId,
            direccion: {
              ...modeloPm.direccion,
              idDireccion: data.direccionClienteId,
            },
            datosDelNegocio: {
              ...modeloPm.datosDelNegocio,
              idDatosDelNegocio: data.datosNegocioId,
              actividadId: data.actividadId,
            },
            generales: data.generales,
          });
          setFolioSolicitud(data.solicitudFolio);
          setStyleHr("block");
          handleSeccionPyme(SECCION_GENERALES);
          formik.setFieldValue(
            "direccion.idDireccion",
            data.direccionClienteId
          );
          formik.setFieldValue(
            "datosDelNegocio.idDatosDelNegocio",
            data.datosNegocioId
          );

          const continuarSolicitud = sessionStorage.getItem(
            sessionStorageContinuarSolicitud
          );

          if (continuarSolicitud === "false") {
            sessionStorage.setItem(sessionStorageIdSolicitud, data.solicitudId);
            sessionStorage.setItem(sessionStorageContinuarSolicitud, true);
          }
        } else {
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);
            sweetAlertError(mensajeError);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar datos generales PM");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (regresarCotizador === REGRESAR_A_COTIZADOR) {
      setRegresarCotizador(REGRESAR_A_COTIZADOR);
    }
  }, [regresarCotizador]);

  useEffect(() => {
    if (modeloPm?.generales) {
      setRfcCliente(modeloPm?.generales?.rfc);
    }

    if (modeloPm?.datosDelNegocio?.idDatosDelNegocio !== null) {
      let fechaRecibida = "";
      if (
        modeloPm?.datosDelNegocio?.fechaAltaShcp === null ||
        modeloPm?.datosDelNegocio?.fechaAltaShcp === ""
      ) {
        fechaRecibida = obtenerFechaShcpByRfcInput(modeloPm?.generales?.rfc);
        formik.setFieldValue(
          "datosDelNegocio.fechaAltaShcp",
          fechaRecibida.split("-").reverse().join("-")
        );
      } else {
        fechaRecibida = modeloPm?.datosDelNegocio?.fechaAltaShcp
          .split("-")
          .reverse()
          .join("-");
      }
      setStartDate(fechaRecibida);
    } else {
      let fechaRecibida;
      if (modeloPm?.datosDelNegocio?.fechaAltaShcp) {
        fechaRecibida = modeloPm?.datosDelNegocio?.fechaAltaShcp;
        fechaRecibida = fechaRecibida.split("-").reverse().join("-");
      } else {
        if (modeloPm?.generales && modeloPm?.generales?.rfc) {
          fechaRecibida = obtenerFechaShcpByRfcInput(modeloPm?.generales?.rfc);
          formik.setFieldValue(
            "datosDelNegocio.fechaAltaShcp",
            fechaRecibida.split("-").reverse().join("-")
          );
        } else {
          fechaRecibida = "";
        }
      }

      setStartDate(fechaRecibida);
    }
  }, []);

  return (
    <>
      <form name="form-grales" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Información general</h2>
          <FormLayout>
            <InputWithError
              label="Razón social *"
              name="generales.razonSocial"
              value={formik.values?.generales?.razonSocial}
              onChange={(e) => {
                if (validarTextoConPunto(e.target.value, "alphanumeric")) {
                  formik.setFieldValue("generales.razonSocial", e.target.value);
                }
              }}
              InputProps={{ maxLength: 180 }}
              disabled
              showErrorMessage={formik.errors?.generales?.razonSocial}
              errorMessage={formik.errors?.generales?.razonSocial}
            />
            <InputWithError
              name="generales.rfc"
              value={formik.values?.generales?.rfc}
              onChange={(e) => {
                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue("generales.rfc", newValue);
                  setRfcCliente(e.target.value);
                }
              }}
              label="RFC *"
              InputProps={{ maxLength: 12, minLength: 12 }}
              disabled
              showErrorMessage={formik.errors?.generales?.rfc}
              errorMessage={formik.errors?.generales?.rfc}
            />
            <InputWithError
              label="Número de escritura *"
              name="generales.folioVerificacionId"
              value={formik.values?.generales?.folioVerificacionId}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.folioVerificacionId
                ) {
                  delete newErrors?.generales?.folioVerificacionId;
                  formik.setErrors(newErrors);
                }
                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "generales.folioVerificacionId",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              showErrorMessage={formik.errors?.generales?.folioVerificacionId}
              errorMessage={formik.errors?.generales?.folioVerificacionId}
            />
            <InputWithError
              name="datosDelNegocio.fechaAltaShcp"
              value={startDate}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.datosDelNegocio !== undefined &&
                  newErrors?.datosDelNegocio?.fechaAltaShcp
                ) {
                  delete newErrors?.datosDelNegocio?.fechaAltaShcp;
                  formik.setErrors(newErrors);
                }
                let array = e.target.value.split("-").reverse().join("-");
                formik.setFieldValue("datosDelNegocio.fechaAltaShcp", array);
                setStartDate(e.target.value);
              }}
              label="Fecha de alta en SHCP *"
              type="date"
              InputProps={{ max: fechaActualYYYMMDD() }}
              InputLabelProps={{shrink: true}}                        
              disabled
              showErrorMessage={formik.errors?.datosDelNegocio?.fechaAltaShcp}
              errorMessage={formik.errors?.datosDelNegocio?.fechaAltaShcp}
            />
            <SelectWithError
              label="Duración de la sociedad *"
              name="generales.duracion"
              menuPlacement="top"
              value={{
                label: formik?.values?.generales?.duracion,
                value: formik?.values?.generales?.duracion,
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.duracion
                ) {
                  delete newErrors?.generales?.duracion;
                  formik.setErrors(newErrors);
                }
                formik.setFieldValue("generales.duracion", e.label);
              }}
              options={anios.map((e, index) => {
                return {
                  ...e,
                  label: index,
                  value: index,
                };
              })}
              tooltip="Esta información se encuentra dentro del acta constitutiva de la empresa"
              showErrorMessage={formik.errors?.generales?.duracion}
              errorMessage={formik.errors?.generales?.duracion}
            />
            <InputWithError
              label="Folio mercantil *"
              name="generales.folioMercantil"
              value={formik.values?.generales?.folioMercantil}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.generales !== undefined &&
                  newErrors?.generales?.folioMercantil
                ) {
                  delete newErrors?.generales?.folioMercantil;
                  formik.setErrors(newErrors);
                }
                if (notSpecialCaracters(e.target.value)) {
                  formik.setFieldValue(
                    "generales.folioMercantil",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 5, minLength: 4 }}
              tooltip="Esta información se encuentra dentro del acta constitutiva de la empresa"
              showErrorMessage={formik.errors?.generales?.folioMercantil}
              errorMessage={formik.errors?.generales?.folioMercantil}
            />
            <PrimaryButton type="submit">Siguiente</PrimaryButton>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default DatosGeneralesPM;

function initialValues(modeloPm) {
  let generales = modeloPm.generales;

  return {
    idCliente: modeloPm.idCliente,
    idDatosOperacion: modeloPm.idDatosOperacion,
    idDatosClienteTemporal: modeloPm.idDatosClienteTemporal,
    idDatosTempAutoservicio: parseInt(
      sessionStorage.getItem(sessionStorageAutoServicio)
    ),
    generales: {
      rfc: generales.rfc ? generales.rfc : "",
      razonSocial: generales.razonSocial ? generales.razonSocial : "",
      correoElectronico: generales.correoElectronico
        ? generales.correoElectronico
        : "",
      sic: generales.sic ? generales.sic : "",
      folioVerificacionId: generales.folioVerificacionId
        ? generales.folioVerificacionId
        : "",
      folioMercantil: generales.folioMercantil ? generales.folioMercantil : "",
      duracion: generales.duracion ? generales.duracion : "",
    },
    direccion: {
      idDireccion: null,
    },
    datosDelNegocio: {
      idDatosDelNegocio: null,
      fechaAltaShcp: modeloPm.datosDelNegocio.fechaAltaShcp
        ? modeloPm.datosDelNegocio.fechaAltaShcp
        : "",
    },
    banorteDatosDelCliente: {
      personaJuridica: modeloPm.banorteDatosDelCliente.personaJuridica,
      consultaServicioCodigo:
        modeloPm.banorteDatosDelCliente.consultaServicioCodigo,
      consultaServicioDetalle:
        modeloPm.banorteDatosDelCliente.consultaServicioDetalle,
      numeroCliente: modeloPm.banorteDatosDelCliente.numeroCliente,
      segmento: modeloPm.banorteDatosDelCliente.segmento,
      indiceMigracion: modeloPm.banorteDatosDelCliente.indiceMigracion,
      indiceBancario: modeloPm.banorteDatosDelCliente.indiceBancario,
      firmaContratoMultiple:
        modeloPm.banorteDatosDelCliente.firmaContratoMultiple,
    },
  };
}

function validationSchema(rfcCliente) {
  return {
    generales: Yup.object().shape({
      razonSocial: Yup.string().required("Dato requerido."),
      rfc: Yup.string().required("Dato requerido."),
      folioVerificacionId: Yup.string().required("Dato requerido."),
      duracion: Yup.number().required("Dato requerido."),
      folioMercantil: Yup.string()
        .required("Dato requerido.")
        .test(
          "folioMercantil",
          "Por favor ingrese sólo caracteres alfanumericos y guiones.",
          (item, testContext) => {
            return alphaNumericWithDash(testContext.parent.folioMercantil);
          }
        ),
    }),
    datosDelNegocio: Yup.object().shape({
      fechaAltaShcp: Yup.string()
        .required("Dato requerido.")
        .test(
          "fechaAltaShcp",
          "La fecha no coincide con el RFC",
          (item, testContext) => {
            return pmFechaAltaSHCPVsRfc(
              rfcCliente,
              testContext.parent.fechaAltaShcp
            );
          }
        ),
    }),
  };
}
