//export const path = 'http://localhost:8080/banortepyme';
export const path = "https://validacionqa.pymebanorte.com/pymeautoservicio";
//export const path = "https://pymebanorte.com";

//Rutas documentos proyecto
export const pathAvisoPrivacidad = "/files/AVISO PRIVACIDAD PYME.pdf";
export const pathAvisoPrivacidadHistorial = "/files/AVISO PRIVACIDAD PYME.pdf";
export const pathAutorizacionBCHistorial =
  path+"/rest/downloadDocumentBuro";
export const pathAvisoPrivacidadCrediactivo = "/files/AVISO PRIVACIDAD.pdf";
