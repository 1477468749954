import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import "react-datetime/css/react-datetime.css";
import {
  NACIONALIDAD_MEXICAN0,
  INE_CERTIFICADA,
  APLICA_INE_COMPROBANTE_OS_NO,
  EXCEPCIONES_RFC,
  TIPO_VIVIENDA_EDIFICIO,
  TIPO_DEPARTAMENTO_APARTAMENTO,
  ID_CALCULO_RFC_NOMBRE,
  ID_CALCULO_RFC_APP,
  ID_CALCULO_RFC_APM,
  ID_CALCULO_RFC_FECHANAC,
  ID_CALCULO_RFC_GENERO,
  ID_CALCULO_RFC_ESTADONAC,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  ID_PAIS_MEXICO,
  ID_PF,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  OS_MAX_PFAE,
  STATUS_EXITO
} from "../../../../../utils/constantes";

import {
  api_direccion_by_cp,
  api_save_os_pfae_pfae,
} from "../../../../../api/api_servicios_rest";
import {
  dependeintesMajor23,
  tiempoViviendaAnterior,
  tiempoViviendaActual,
  generaCurp,
  generaRFC,
  formatoTelefonoXX_XXXX_XXXX,
  validarNumeroTelefono,
  fechaMaxMayorEdadYYYMMDD,
  fechaMinEdadYYYMMDD,
  compareDatesOrigin,
  validarRFCTipoPersona,
} from "../../../../../utils/validaciones";

import { mensajeMostrarUsuario } from "../../../../../utils/mensajesServicios";

import SelectWithError from "../../../../basics/SelectWithError/SelectWithError";
import Select from "react-select";
import { selectStylesDouble } from "../../../../../styles/select-styles";
import CheckBoxInput from "../../../../basics/checkBox";
import {
  validateValue,
  textToUpperCase,
  validDate,
  validarTextoConEnies,
  notSpecialCaractersConGuion,
} from "../../../../../functions/fieldFunctions";
import FormLayout from "../../../../helpers/FormLayout/FormLayout";
import InputWithError from "../../../../basics/InputWithError/InputWithError";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../basics/Buttons/Buttons";
import ErrorMessage from "../../../../helpers/ErrorMessage/ErrorMessage";
import {
  sweetAlertError,
  sweetAlertWithConfirmed,
} from "../../../../helpers/alertas/alertas";
import {
  limpiarDireccionByCp,
  obtenerRfcCurpPF_PFAE,
} from "./helpers/obligadoSolidarioHelper";

const ObligadoSolidarioPF_PFAE = ({
  modeloPfae,
  axiosData,
  aniosDomicilio,
  mesesDomicilo,
  NUM_OS,
  idTipoPersonaOS,
  setObligadosSolidariosInformacionBasica,
  setStyleHr,
  handleSeccionPyme,
  handleRegresarSeccionPyme,
  setModeloPfae,
  setLoading,
  seccionActual,
  datosOs,
  setDatosOs,
  setUltimoPasoSolicitud,
  webView,
}) => {
  const { innerWidth } = window;

  const [coloniasCP, setColoniasCP] = useState([]);
  const [startDateFechaNacimiento, setStartDateFechaNacimiento] = useState("");
  const [totalDependientes, setTotalDependientes] = useState("");
  const [totalDependientesMenores23, setTotalDependientesMenores23] =
    useState("");

  const formik = useFormik({
    initialValues: initialValues(modeloPfae, idTipoPersonaOS, NUM_OS, datosOs),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(
      validationSchema(totalDependientesMenores23, totalDependientes)
    ),
    onSubmit: (formData, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      salvarDatosOSPFAE_PFAE(formData)
        .then(() => setLoading(false))
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    },
  });

  const obtenerDireccionByCp = async (codigoPostal) => {
    try {
      const response = await api_direccion_by_cp(codigoPostal);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        if (response.data.municipio !== undefined) {
          setColoniasCP(response.data.asentamientos);
          formik.setFieldValue(
            "obligadoSolidario.direccion.municipioId",
            response.data.municipio.id
          );
          formik.setFieldValue(
            "obligadoSolidario.direccion.municipioObj",
            axiosData?.generales?.municipios?.filter(
              (e) => e.id === response.data.municipio.id
            )
          );
          formik.setFieldValue(
            "obligadoSolidario.direccion.estadoId",
            response.data.estado.id
          );
          formik.setFieldValue(
            "obligadoSolidario.direccion.estadoObj",
            axiosData?.generales?.estados?.filter(
              (e) => e.id === response.data.estado.id
            )
          );
          formik.setFieldValue(
            "obligadoSolidario.direccion.colonia",
            response.data.asentamientos[0]
          );

          let newErrors = formik.errors;
          delete newErrors?.obligadoSolidario?.direccion?.municipioId;
          delete newErrors?.obligadoSolidario?.direccion?.estadoId;
          delete newErrors?.obligadoSolidario?.direccion?.colonia;
          formik.setErrors(newErrors);
        } else {
          limpiarDireccionByCp(formik);
        }
      } else {
        sweetAlertError(
          "Ocurrió un error al obtener dirección por código postal"
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const generarRfcCurp = (idValorCalculoRFC, valueCalculoRFC) => {
    const {
      nombre,
      apellidoMaterno,
      apellidoPaterno,
      estadoNacimientoId,
      fechaNacimiento,
      generoId,
    } = obtenerRfcCurpPF_PFAE(idValorCalculoRFC, valueCalculoRFC, formik);

    if (
      nombre &&
      nombre.length > 0 &&
      apellidoPaterno &&
      apellidoPaterno.length > 0 &&
      generoId &&
      estadoNacimientoId &&
      fechaNacimiento &&
      fechaNacimiento.length > 0
    ) {
      let idEstado = Number(estadoNacimientoId);
      const persona = {
        nombre: nombre,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        genero: generoId === 1 ? "H" : "M",
        idEstado: idEstado - 1,
        fechaNacimiento: fechaNacimiento,
      };

      let curp = generaCurp(persona);
      if (curp && curp.length === 18) {
        formik.setFieldValue("obligadoSolidario.generales.curp", curp);
        let newErrors = formik.errors;
        delete newErrors?.obligadoSolidario?.generales?.curp;
        formik.setErrors(newErrors);
      }

      if (apellidoMaterno && apellidoMaterno.length > 0) {
        const rfc = generaRFC(persona);
        if (rfc) {
          formik.setFieldValue("obligadoSolidario.generales.rfc", rfc);
          let newErrors = formik.errors;
          delete newErrors?.obligadoSolidario?.generales?.rfc;
          formik.setErrors(newErrors);
        }
      }
    }
  };

  const salvarDatosOSPFAE_PFAE = async (datosOS) => {
    try {
      const response = await api_save_os_pfae_pfae(datosOS);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setModeloPfae({
            ...modeloPfae,
            idCliente: data.clienteId,
            obligadoSolidario: {
              ...modeloPfae.obligadoSolidario,
              idObligadoSolidario: data.obligadoSolidarioId,
            },
          });

          let conyuge = datosOs.conyuge;
          let showConyugeOS = false;

          if (
            data.obligadoSolidarioEstadoCivilId === ESTADO_CIVIL_SOCIEDAD_CONYUGAL && 
            data.obligadosSolidariosConteo < OS_MAX_PFAE
          ) {
            showConyugeOS = true;
          }

          setDatosOs({
            datosOS: data.datosOS,
            idTipoPersonaOs: data.obligadoSolidarioIdTipoPersona,
            idEstadoCivilOs: data.obligadoSolidarioEstadoCivilId,
            conyuge: conyuge,
            showConyugeOS: showConyugeOS,
          });

          setObligadosSolidariosInformacionBasica(
            data.obligadosSolidariosInformacionBasica
          );
          setUltimoPasoSolicitud(data.solicitudUltimoPaso);
          setStyleHr("block");
          setLoading(false);
          handleSeccionPyme(seccionActual);
        } else {
          setLoading(false);
          if (MENSAJE_ERROR_TOKEN_INVALIDO === data?.mensaje) {
            sweetAlertWithConfirmed(data?.mensaje, webView);
          } else {
            const mensajeError = mensajeMostrarUsuario(data.mensaje);
            sweetAlertError(mensajeError);
          }
        }
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al guardar OS PF");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      datosOs.datosOS.length !== 0 &&
      datosOs.datosOS.idObligadoSolidario !== null
    ) {
      let calidadMigratoria = datosOs.datosOS.generales.calidadMigratoria
        ? datosOs.datosOS.generales.calidadMigratoria
        : "";
      formik.setFieldValue(
        "obligadoSolidario.generales.calidadMigratoria",
        calidadMigratoria
      );

      if (
        datosOs.datosOS.generales?.fechaNacimiento !== undefined &&
        datosOs.datosOS.generales?.fechaNacimiento !== null
      ) {
        setStartDateFechaNacimiento(
          datosOs?.datosOS?.generales?.fechaNacimiento
            .split("-")
            .reverse()
            .join("-")
        );
      }

      formik.setFieldValue(
        "obligadoSolidario.generales.paisNacimientoObj",
        axiosData?.generales?.paises?.filter(
          (e) => e.id === datosOs.datosOS.generales.paisNacimientoId
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.generales.estadoNacimientoObj",
        axiosData?.generales?.estados?.filter(
          (e) => e.id === datosOs.datosOS.generales.estadoNacimientoId
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.generales.generoObj",
        axiosData?.generales?.generoOpciones?.filter(
          (e) => e.id === datosOs.datosOS.generales.generoId
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.generales.nacionalidadObj",
        axiosData?.generales?.nacionalidades?.filter(
          (e) => e.id === datosOs.datosOS.generales.nacionalidadId
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.generales.estadoCivilObj",
        axiosData?.generales?.estadoCivilOpciones?.filter(
          (e) => e.id === datosOs.datosOS.generales.estadoCivilId
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.generales.nivelEducativoObj",
        axiosData?.generales?.nivelEducativoOpciones?.filter(
          (e) => e.id === datosOs.datosOS.generales.nivelEducativoId
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.generales.dependientesEconomicosMenores23Obj",
        axiosData?.generales?.dependientesMenores23Anios?.filter(
          (e) =>
            e.valor ===
            datosOs.datosOS.generales.dependientesEconomicosMenores23
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.generales.dependientesEconomicosObj",
        axiosData?.generales?.dependientes?.filter(
          (e) => e.valor === datosOs.datosOS.generales.dependientesEconomicos
        )
      );

      if (datosOs.datosOS.generales.paisResidenciaFiscal2Id === null) {
        formik.setFieldValue(
          "obligadoSolidario.generales.paisResidenciaFiscal2Id",
          ""
        );
      }

      formik.setFieldValue(
        "obligadoSolidario.direccion.tipoDomicilioObj",
        axiosData?.domicilio?.tipoDeDomicilioOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoDomicilioId
        )
      );

      formik.setFieldValue(
        "obligadoSolidario.direccion.tipoViviendaObj",
        axiosData?.domicilio?.tipoDeViviendaOpciones?.filter(
          (e) => e.id === datosOs.datosOS.direccion.tipoViviendaId
        )
      );

      obtenerDireccionByCp(datosOs.datosOS.direccion.codigoPostal);

      let dependientesMajor23Opc = datosOs.datosOS.generales
        ?.dependientesEconomicosMenores23
        ? axiosData?.generales?.dependientesMenores23Anios?.filter(
            (e) =>
              e.id ===
              datosOs.datosOS.generales?.dependientesEconomicosMenores23
          )
        : "";
      let dependientesEconomicoOpc = datosOs.datosOS.generales
        ?.dependientesEconomicos
        ? axiosData?.generales?.dependientes?.filter(
            (e) => e.id === datosOs.datosOS.generales?.dependientesEconomicos
          )
        : "";
      setTotalDependientesMenores23(
        dependientesMajor23Opc !== ""
          ? dependientesMajor23Opc[0].descripcion
          : 0
      );
      setTotalDependientes(
        dependientesEconomicoOpc !== ""
          ? dependientesEconomicoOpc[0].descripcion
          : 0
      );

      if (datosOs.datosOS.direccion?.piso === null) {
        formik.setFieldValue("obligadoSolidario.direccion.piso", "");
      }

      if (datosOs?.datosOS?.direccion?.numeroInterior === null) {
        formik.setFieldValue("obligadoSolidario.direccion.numeroInterior", "");
      }

      if (datosOs?.datosOS?.generales?.nombreOtraEmpresaPfae === null) {
        formik.setFieldValue(
          "obligadoSolidario.generales.nombreOtraEmpresaPfae",
          ""
        );
      }

      if (datosOs?.datosOS?.generales?.nombreOtraEmpresa === null) {
        formik.setFieldValue(
          "obligadoSolidario.generales.nombreOtraEmpresa",
          ""
        );
      }

      if (datosOs?.datosOS?.generales?.numeroIdFiscal2 === null) {
        formik.setFieldValue("obligadoSolidario.generales.numeroIdFiscal2", "");
      }
    }
  }, []);

  useEffect(() => {
    if (idTipoPersonaOS !== null) {
      formik.setFieldValue(
        "obligadoSolidario.generales.tipoPersonaId",
        idTipoPersonaOS
      );
    }
  }, [idTipoPersonaOS]);

  return (
    <>
      <form name="form-os-pfea-pm" onSubmit={formik.handleSubmit}>
        <div className="container">
          <h2 className="f-s-3">Información general</h2>
          <FormLayout>
            <InputWithError
              name="obligadoSolidario.generales.nombre"
              label="Nombre *"
              value={formik.values?.obligadoSolidario?.generales?.nombre}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario.generales !== undefined &&
                  newErrors?.obligadoSolidario.generales.nombre
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.nombre;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "obligadoSolidario.generales.nombre",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_NOMBRE, e.target.value);
              }}
              InputProps={{ maxLength: 30 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.nombre
              }
              errorMessage={formik.errors?.obligadoSolidario?.generales?.nombre}
            />
            <InputWithError
              label="Apellido paterno *"
              name="obligadoSolidario.generales.apellidoPaterno"
              value={
                formik.values?.obligadoSolidario?.generales?.apellidoPaterno
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario.generales !== undefined &&
                  newErrors?.obligadoSolidario.generales.apellidoPaterno
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.apellidoPaterno;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "obligadoSolidario.generales.apellidoPaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APP, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.apellidoPaterno
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.apellidoPaterno
              }
            />
            <InputWithError
              label="Apellido materno"
              name="obligadoSolidario.generales.apellidoMaterno"
              value={
                formik.values?.obligadoSolidario?.generales?.apellidoMaterno
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario.generales !== undefined &&
                  newErrors?.obligadoSolidario.generales.apellidoMaterno
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.apellidoMaterno;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "obligadoSolidario.generales.apellidoMaterno",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                generarRfcCurp(ID_CALCULO_RFC_APM, e.target.value);
              }}
              InputProps={{ maxLength: 20 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.apellidoMaterno
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.apellidoMaterno
              }
            />
            <InputWithError
              name="obligadoSolidario.generales.fechaNacimiento"
              type="date"
              label="Fecha de nacimiento *"
              value={startDateFechaNacimiento}
              timeFormat={false}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario.generales !== undefined &&
                  newErrors?.obligadoSolidario.generales.fechaNacimiento
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.fechaNacimiento;
                  formik.setErrors(newErrors);
                }

                if (validDate(e.target.value)) {
                  let array = e.target.value.split("-").reverse().join("-");
                  formik.setFieldValue(
                    "obligadoSolidario.generales.fechaNacimiento",
                    array
                  );
                  setStartDateFechaNacimiento(e.target.value);
                  generarRfcCurp(ID_CALCULO_RFC_FECHANAC, array);
                }
              }}
              InputProps={{
                max: fechaMaxMayorEdadYYYMMDD(),
                min: fechaMinEdadYYYMMDD(),
              }}
              InputLabelProps={{shrink: true}}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.fechaNacimiento
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.fechaNacimiento
              }
            />
            <SelectWithError
              name="obligadoSolidario.generales.paisNacimientoId"
              label="País de nacimiento *"
              value={{
                label: formik?.values?.obligadoSolidario?.generales
                  ?.paisNacimientoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.paisNacimientoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.generales
                  ?.paisNacimientoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.paisNacimientoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario.generales !== undefined &&
                  newErrors?.obligadoSolidario.generales.paisNacimientoId
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.paisNacimientoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.generales.paisNacimientoId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.generales.paisNacimientoObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.paises?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.paisNacimientoId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.paisNacimientoId
              }
            />
            {formik.values?.obligadoSolidario?.generales?.paisNacimientoId !== "" &&
              parseInt(formik.values?.obligadoSolidario?.generales?.paisNacimientoId) === ID_PAIS_MEXICO && (
              <SelectWithError
                name="obligadoSolidario.generales.estadoNacimientoId"
                label="Estado de nacimiento *"
                value={{
                  label: formik?.values?.obligadoSolidario?.generales
                    ?.estadoNacimientoObj?.[0]?.nombre
                    ? formik?.values?.obligadoSolidario?.generales
                        ?.estadoNacimientoObj?.[0]?.nombre
                    : "",
                  value: formik?.values?.obligadoSolidario?.generales
                    ?.estadoNacimientoObj?.[0]?.nombre
                    ? formik?.values?.obligadoSolidario?.generales
                        ?.estadoNacimientoObj?.[0]?.nombre
                    : "",
                }}
                onChange={(e) => {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.obligadoSolidario !== undefined &&
                    newErrors?.obligadoSolidario.generales !== undefined &&
                    newErrors?.obligadoSolidario.generales.estadoNacimientoId
                  ) {
                    delete newErrors?.obligadoSolidario?.generales
                      ?.estadoNacimientoId;
                    formik.setErrors(newErrors);
                  }

                  formik.setFieldValue(
                    "obligadoSolidario.generales.estadoNacimientoId",
                    e.id
                  );
                  formik.setFieldValue(
                    "obligadoSolidario.generales.estadoNacimientoObj",
                    [e]
                  );
                  generarRfcCurp(ID_CALCULO_RFC_ESTADONAC, e.id);
                }}
                options={axiosData?.generales?.estados?.map((item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                })}
                showErrorMessage={
                  formik.errors?.obligadoSolidario?.generales?.estadoNacimientoId
                }
                errorMessage={
                  formik.errors?.obligadoSolidario?.generales?.estadoNacimientoId
                }
              />
            )}

            <SelectWithError
              label="Género *"
              name="obligadoSolidario.generales.generoId"
              value={{
                label: formik?.values?.obligadoSolidario?.generales
                  ?.generoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales?.generoObj?.[0]
                      ?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.generales
                  ?.generoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales?.generoObj?.[0]
                      ?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.generoId
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.generoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.generales.generoId",
                  e.id
                );
                formik.setFieldValue("obligadoSolidario.generales.generoObj", [
                  e,
                ]);
                generarRfcCurp(ID_CALCULO_RFC_GENERO, e.id);
              }}
              options={axiosData?.generales?.generoOpciones?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.generoId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.generoId
              }
            />
            <InputWithError
              name="obligadoSolidario.generales.rfc"
              label="RFC *"
              value={formik.values?.obligadoSolidario?.generales?.rfc}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.rfc
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.rfc;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "obligadoSolidario.generales.rfc",
                    newValue
                  );
                }
              }}
              InputProps={{ maxLength: 13 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.rfc
              }
              errorMessage={formik.errors?.obligadoSolidario?.generales?.rfc}
            />
            <InputWithError
              name="obligadoSolidario.generales.curp"
              label="CURP *"
              value={formik.values?.obligadoSolidario?.generales?.curp}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.curp
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.curp;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  const newValue = textToUpperCase(e.target.value);

                  formik.setFieldValue(
                    "obligadoSolidario.generales.curp",
                    newValue
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.curp
              }
              errorMessage={formik.errors?.obligadoSolidario?.generales?.curp}
            />
            <InputWithError
              name="obligadoSolidario.generales.folioVerificacionId"
              label="Número de identificación oficial *"
              value={
                formik.values?.obligadoSolidario?.generales?.folioVerificacionId
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.folioVerificacionId
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.folioVerificacionId;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidario.generales.folioVerificacionId",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 18 }}
              tooltip="Captura el número de folio de tu identificación, si es INE lo encontrarás en la parte posterior de la misma, después de las letras IDMEX"
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.folioVerificacionId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.folioVerificacionId
              }
            />
            <SelectWithError
              label="Nacionalidad *"
              name="obligadoSolidario.generales.nacionalidadId"
              value={{
                label: formik?.values?.obligadoSolidario?.generales
                  ?.nacionalidadObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.nacionalidadObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.generales
                  ?.nacionalidadObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.nacionalidadObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.nacionalidadId
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.nacionalidadId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.generales.nacionalidadId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.generales.nacionalidadObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.nacionalidades?.map((item) => {
                return {
                  ...item,
                  label: item.nombre,
                  value: item.nombre,
                };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.nacionalidadId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.nacionalidadId
              }
            />
            {formik.values?.obligadoSolidario?.generales?.nacionalidadId !==
              "" &&
              parseInt(
                formik.values?.obligadoSolidario?.generales?.nacionalidadId
              ) !== NACIONALIDAD_MEXICAN0 && (
                <InputWithError
                  label="Calidad migratoria *"
                  name="obligadoSolidario.generales.calidadMigratoria"
                  value={
                    formik.values?.obligadoSolidario?.generales
                      ?.calidadMigratoria
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.obligadoSolidario !== undefined &&
                      newErrors?.obligadoSolidario?.generales !== undefined &&
                      newErrors?.obligadoSolidario?.generales?.calidadMigratoria
                    ) {
                      delete newErrors?.obligadoSolidario?.generales
                        ?.calidadMigratoria;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "obligadoSolidario.generales.calidadMigratoria",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                />
              )}
            <SelectWithError
              name="obligadoSolidario.generales.estadoCivilId"
              label="Estado civil *"
              value={{
                label: formik?.values?.obligadoSolidario?.generales
                  ?.estadoCivilObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.estadoCivilObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.generales
                  ?.estadoCivilObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.estadoCivilObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.estadoCivilId
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.estadoCivilId;
                  formik.setErrors(newErrors);
                }

                {
                  formik.setFieldValue(
                    "obligadoSolidario.generales.estadoCivilId",
                    e.id
                  );
                  formik.setFieldValue(
                    "obligadoSolidario.generales.estadoCivilObj",
                    [e]
                  );

                  setDatosOs({
                    ...datosOs,
                    idEstadoCivilOs: e.id,
                  });
                }
              }}
              options={axiosData?.generales?.estadoCivilOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.estadoCivilId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.estadoCivilId
              }
            />
            <SelectWithError
              label="Nivel académico *"
              name="obligadoSolidario.generales.nivelEducativoId"
              value={{
                label: formik?.values?.obligadoSolidario?.generales
                  ?.nivelEducativoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.nivelEducativoObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.generales
                  ?.nivelEducativoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.nivelEducativoObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.nivelEducativoId
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.nivelEducativoId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.generales.nivelEducativoId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.generales.nivelEducativoObj",
                  [e]
                );
              }}
              options={axiosData?.generales?.nivelEducativoOpciones?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.nombre,
                    value: item.nombre,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.nivelEducativoId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.nivelEducativoId
              }
            />
            <InputWithError
              name="obligadoSolidario.generales.profesion"
              label="Ocupación o profesión *"
              value={formik.values?.obligadoSolidario?.generales?.profesion}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.profesion
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.profesion;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "obligadoSolidario.generales.profesion",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.profesion
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.profesion
              }
            />
            <InputWithError
              name="obligadoSolidario.generales.correoElectronico"
              label="Correo electrónico *"
              value={
                formik.values?.obligadoSolidario?.generales?.correoElectronico
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.correoElectronico
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.correoElectronico;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.generales.correoElectronico",
                  e.target.value
                );
              }}
              type="email"
              InputProps={{ maxLength: 50 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.correoElectronico
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.correoElectronico
              }
            />
            <InputWithError
              name="obligadoSolidario.generales.telefono"
              label="Teléfono *"
              value={formik.values?.obligadoSolidario?.generales?.telefono}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.telefono
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.telefono;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.obligadoSolidario?.generales?.telefono
                );
                formik.setFieldValue(
                  "obligadoSolidario.generales.telefono",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.telefono
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.telefono
              }
            />
            <InputWithError
              name="obligadoSolidario.generales.celular"
              label="Celular *"
              value={formik.values?.obligadoSolidario?.generales?.celular}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.celular
                ) {
                  delete newErrors?.obligadoSolidario?.generales?.celular;
                  formik.setErrors(newErrors);
                }

                let numTelefono = formatoTelefonoXX_XXXX_XXXX(
                  e.target.value,
                  formik.values?.obligadoSolidario?.generales?.celular
                );
                formik.setFieldValue(
                  "obligadoSolidario.generales.celular",
                  numTelefono
                );
              }}
              type="tel"
              InputProps={{ maxLength: 12 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.celular
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.celular
              }
            />
            <SelectWithError
              name="obligadoSolidario.generales.dependientesEconomicosMenores23"
              value={{
                label: formik?.values?.obligadoSolidario?.generales
                  ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion
                  : "",
                value: formik?.values?.obligadoSolidario?.generales
                  ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.dependientesEconomicosMenores23Obj?.[0]?.descripcion
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales
                    ?.dependientesEconomicosMenores23
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.dependientesEconomicosMenores23;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.generales.dependientesEconomicosMenores23",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.generales.dependientesEconomicosMenores23Obj",
                  [e]
                );
                setTotalDependientesMenores23(e.descripcion);
              }}
              label="Cantidad de dependientes menores de 23 años *"
              options={axiosData?.generales?.dependientesMenores23Anios?.map(
                (item) => {
                  return {
                    ...item,
                    label: item.descripcion,
                    value: item.descripcion,
                  };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales
                  ?.dependientesEconomicosMenores23
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales
                  ?.dependientesEconomicosMenores23
              }
            />
            <SelectWithError
              label="Cantidad total de dependientes *"
              name="obligadoSolidario.generales.dependientesEconomicos"
              value={{
                label: formik?.values?.obligadoSolidario?.generales
                  ?.dependientesEconomicosObj?.[0]?.descripcion
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.dependientesEconomicosObj?.[0]?.descripcion
                  : "",
                value: formik?.values?.obligadoSolidario?.generales
                  ?.dependientesEconomicosObj?.[0]?.descripcion
                  ? formik?.values?.obligadoSolidario?.generales
                      ?.dependientesEconomicosObj?.[0]?.descripcion
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales
                    ?.dependientesEconomicos
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.dependientesEconomicos;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.generales.dependientesEconomicos",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.generales.dependientesEconomicosObj",
                  [e]
                );
                setTotalDependientes(e.descripcion);
              }}
              options={axiosData?.generales?.dependientes?.map((item) => {
                return {
                  ...item,
                  label: item.descripcion,
                  value: item.descripcion,
                };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales
                  ?.dependientesEconomicos
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales
                  ?.dependientesEconomicos
              }
            />
            <InputWithError
              name="obligadoSolidario.generales.relacionSolicitante"
              label="Relación con el solicitante *"
              value={
                formik.values?.obligadoSolidario?.generales?.relacionSolicitante
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.generales !== undefined &&
                  newErrors?.obligadoSolidario?.generales?.relacionSolicitante
                ) {
                  delete newErrors?.obligadoSolidario?.generales
                    ?.relacionSolicitante;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "text")) {
                  formik.setFieldValue(
                    "obligadoSolidario.generales.relacionSolicitante",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 180 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.generales?.relacionSolicitante
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.generales?.relacionSolicitante
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <div style={{ display: window.innerWidth < 700 && "flex" }}>
                <CheckBoxInput
                  name="obligadoSolidario.generales.esAccionistaOtraEmpresa"
                  state=""
                  typeOfCheck="normal"
                  value={
                    formik.values?.obligadoSolidario?.generales
                      ?.esAccionistaOtraEmpresa
                  }
                  checked={
                    formik.values?.obligadoSolidario?.generales
                      ?.esAccionistaOtraEmpresa
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "obligadoSolidario.generales.esAccionistaOtraEmpresa",
                      e.target.checked
                    )
                  }
                  id="obligadoSolidarioOtraEmpresa"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label"
                  htmlFor="obligadoSolidarioOtraEmpresa"
                >
                  El obligado es accionista u obligado de otra empresa
                </label>
              </div>
              {formik.values?.obligadoSolidario?.generales
                ?.esAccionistaOtraEmpresa && (
                <InputWithError
                  label="Nombre de la empresa o PFAE *"
                  name="obligadoSolidario.generales.nombreOtraEmpresa"
                  state=""
                  value={
                    formik.values?.obligadoSolidario?.generales
                      ?.nombreOtraEmpresa
                      ? formik.values?.obligadoSolidario?.generales
                          ?.nombreOtraEmpresa
                      : ""
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.obligadoSolidario !== undefined &&
                      newErrors?.obligadoSolidario?.generales !== undefined &&
                      newErrors?.obligadoSolidario?.generales?.nombreOtraEmpresa
                    ) {
                      delete newErrors?.obligadoSolidario?.generales
                        ?.nombreOtraEmpresa;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "obligadoSolidario.generales.nombreOtraEmpresa",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  showErrorMessage={
                    formik.errors?.obligadoSolidario?.generales
                      ?.nombreOtraEmpresa
                  }
                  errorMessage={
                    formik.errors?.obligadoSolidario?.generales
                      ?.nombreOtraEmpresa
                  }
                />
              )}
              <div style={{ display: window.innerWidth < 700 && "flex" }}>
                <CheckBoxInput
                  name="obligadoSolidario.generales.esObligadoSolidarioOtraEmpresaPfae"
                  state=""
                  value={
                    formik.values?.obligadoSolidario?.generales
                      ?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  checked={
                    formik.values?.obligadoSolidario?.generales
                      ?.esObligadoSolidarioOtraEmpresaPfae
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "obligadoSolidario.generales.esObligadoSolidarioOtraEmpresaPfae",
                      !formik.values?.obligadoSolidario?.generales
                        ?.esObligadoSolidarioOtraEmpresaPfae
                    )
                  }
                  type="checkbox"
                  id="obligadoSolidarioOtraEmpresaPfae"
                  typeOfCheck="normal"
                />
                <label
                  style={{ marginLeft: "8px" }}
                  className="form-check-label"
                  htmlFor="obligadoSolidarioOtraEmpresaPfae"
                >
                  Es obligado o fiador de otra empresa o PFAE
                </label>
              </div>
              {formik.values?.obligadoSolidario?.generales
                ?.esObligadoSolidarioOtraEmpresaPfae && (
                <InputWithError
                  name="obligadoSolidario.generales.nombreOtraEmpresaPfae"
                  label="Nombre de la empresa o PFAE *"
                  value={
                    formik.values?.obligadoSolidario?.generales
                      ?.nombreOtraEmpresaPfae
                      ? formik.values?.obligadoSolidario?.generales
                          ?.nombreOtraEmpresaPfae
                      : ""
                  }
                  onChange={(e) => {
                    let newErrors = formik.errors;
                    if (
                      newErrors?.obligadoSolidario !== undefined &&
                      newErrors?.obligadoSolidario?.generales !== undefined &&
                      newErrors?.obligadoSolidario?.generales
                        ?.nombreOtraEmpresaPfae
                    ) {
                      delete newErrors?.obligadoSolidario?.generales
                        ?.nombreOtraEmpresaPfae;
                      formik.setErrors(newErrors);
                    }

                    if (validarTextoConEnies(e.target.value, "text")) {
                      formik.setFieldValue(
                        "obligadoSolidario.generales.nombreOtraEmpresaPfae",
                        e.target.value
                      );
                    }
                  }}
                  InputProps={{ maxLength: 180 }}
                  showErrorMessage={
                    formik.errors?.obligadoSolidario?.generales
                      ?.nombreOtraEmpresaPfae
                  }
                  errorMessage={
                    formik.errors?.obligadoSolidario?.generales
                      ?.nombreOtraEmpresaPfae
                  }
                />
              )}
            </div>

            <h2
              style={{
                textAlign: "left",
                width: "100%",
                margin: "1rem 0 ",
              }}
              className="f-s-3"
            >
              Información de domicilio
            </h2>
            <InputWithError
              name="obligadoSolidario.direccion.calle"
              label="Calle *"
              value={formik.values?.obligadoSolidario?.direccion?.calle}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.direccion !== undefined &&
                  newErrors?.obligadoSolidario?.direccion?.calle
                ) {
                  delete newErrors?.obligadoSolidario?.direccion?.calle;
                  formik.setErrors(newErrors);
                }

                if (validarTextoConEnies(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidario.direccion.calle",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 24 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.calle
              }
              errorMessage={formik.errors?.obligadoSolidario?.direccion?.calle}
            />
            <InputWithError
              label="Número exterior *"
              name="obligadoSolidario.direccion.numeroExterior"
              value={
                formik.values?.obligadoSolidario?.direccion?.numeroExterior
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.direccion !== undefined &&
                  newErrors?.obligadoSolidario?.direccion?.numeroExterior
                ) {
                  delete newErrors?.obligadoSolidario?.direccion
                    ?.numeroExterior;
                  formik.setErrors(newErrors);
                }

                if (notSpecialCaractersConGuion(e.target.value)) {
                  formik.setFieldValue(
                    "obligadoSolidario.direccion.numeroExterior",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 7 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.numeroExterior
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.numeroExterior
              }
            />
            <InputWithError
              name="obligadoSolidario.direccion.numeroInterior"
              label="Número interior"
              value={
                formik.values?.obligadoSolidario?.direccion?.numeroInterior
              }
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.direccion !== undefined &&
                  newErrors?.obligadoSolidario?.direccion?.numeroInterior
                ) {
                  delete newErrors?.obligadoSolidario?.direccion
                    ?.numeroInterior;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidario.direccion.numeroInterior",
                    e.target.value
                  );
                }
              }}
              required={
                formik.values.obligadoSolidario.direccion.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.obligadoSolidario.direccion.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
              InputProps={{ maxLength: 2 }}
              tooltip="En caso de que el número interior sea de más de dos caracteres, por favor captúralo junto con tu  “Número exterior” separados por un guion Ejemplo: 318-123B"
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.numeroInterior
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.numeroInterior
              }
            />
            <InputWithError
              name="obligadoSolidario.direccion.piso"
              label="Piso"
              required={
                formik.values.obligadoSolidario.direccion.tipoViviendaId ===
                  parseInt(TIPO_VIVIENDA_EDIFICIO) ||
                formik.values.obligadoSolidario.direccion.tipoViviendaId ===
                  parseInt(TIPO_DEPARTAMENTO_APARTAMENTO)
              }
              value={formik.values?.obligadoSolidario?.direccion?.piso}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.direccion !== undefined &&
                  newErrors?.obligadoSolidario?.direccion?.piso
                ) {
                  delete newErrors?.obligadoSolidario?.direccion?.piso;
                  formik.setErrors(newErrors);
                }

                if (validateValue(e.target.value, "alphanumeric")) {
                  formik.setFieldValue(
                    "obligadoSolidario.direccion.piso",
                    e.target.value
                  );
                }
              }}
              InputProps={{ maxLength: 2 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.piso
              }
              errorMessage={formik.errors?.obligadoSolidario?.direccion?.piso}
            />
            <InputWithError
              name="obligadoSolidario.direccion.codigoPostal"
              label="CP *"
              value={formik.values?.obligadoSolidario?.direccion?.codigoPostal}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.direccion !== undefined &&
                  newErrors?.obligadoSolidario?.direccion?.codigoPostal
                ) {
                  delete newErrors?.obligadoSolidario?.direccion?.codigoPostal;
                  formik.setErrors(newErrors);
                }

                if (
                  e.target.value.length <= 5 &&
                  validateValue(e.target.value, "number")
                ) {
                  formik.setFieldValue(
                    "obligadoSolidario.direccion.codigoPostal",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length === 5) {
                  let newErrors = formik.errors;
                  if (
                    newErrors?.obligadoSolidario !== undefined &&
                    newErrors?.obligadoSolidario?.direccion !== undefined
                  ) {
                    if (newErrors?.obligadoSolidario?.direccion?.colonia) {
                      delete newErrors?.obligadoSolidario?.direccion?.colonia;
                    }
                    if (newErrors?.obligadoSolidario?.direccion?.municipioId) {
                      delete newErrors?.obligadoSolidario?.direccion
                        ?.municipioId;
                    }
                    if (newErrors?.obligadoSolidario?.direccion?.estadoId) {
                      delete newErrors?.obligadoSolidario?.direccion?.estadoId;
                    }
                    formik.setErrors(newErrors);
                  }
                  obtenerDireccionByCp(e.target.value);
                } else {
                  limpiarDireccionByCp(formik);
                }
              }}
              InputProps={{ maxLength: 5 }}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.codigoPostal
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.codigoPostal
              }
            />
            <SelectWithError
              name="obligadoSolidario.direccion.colonia"
              label="Colonia *"
              value={{
                label: formik.values?.obligadoSolidario?.direccion?.colonia
                  ? formik.values?.obligadoSolidario?.direccion?.colonia
                  : "",
                value: formik.values?.obligadoSolidario?.direccion?.colonia
                  ? formik.values?.obligadoSolidario?.direccion?.colonia
                  : "",
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "obligadoSolidario.direccion.colonia",
                  e.label
                );
              }}
              options={coloniasCP?.map((item) => {
                return { ...item, label: item, value: item };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.colonia
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.colonia
              }
            />
            <SelectWithError
              label="Alcaldía / Municipio *"
              name="obligadoSolidario.direccion.municipioId"
              value={{
                label: formik?.values?.obligadoSolidario?.direccion
                  ?.municipioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion
                      ?.municipioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.direccion
                  ?.municipioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion
                      ?.municipioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "obligadoSolidario.direccion.municipioId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.direccion.municipioObj",
                  [e]
                );
              }}
              isDisabled={true}
              options={axiosData?.generales?.municipios?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.municipioId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.municipioId
              }
            />
            <SelectWithError
              label="Estado *"
              name="obligadoSolidario.direccion.estadoId"
              value={{
                label: formik?.values?.obligadoSolidario?.direccion
                  ?.estadoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion?.estadoObj?.[0]
                      ?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.direccion
                  ?.estadoObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion?.estadoObj?.[0]
                      ?.nombre
                  : "",
              }}
              onChange={(e) => {
                formik.setFieldValue(
                  "obligadoSolidario.direccion.estadoId",
                  e.id
                );
                formik.setFieldValue("obligadoSolidario.direccion.estadoObj", [
                  e,
                ]);
              }}
              isDisabled={true}
              options={axiosData?.generales?.estados?.map((item) => {
                return { ...item, label: item.nombre, value: item.nombre };
              })}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.estadoId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.estadoId
              }
            />
            <SelectWithError
              label="Tipo de domicilio *"
              name="obligadoSolidario.direccion.tipoDomicilioId"
              value={{
                label: formik?.values?.obligadoSolidario?.direccion
                  ?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion
                      ?.tipoDomicilioObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.direccion
                  ?.tipoDomicilioObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion
                      ?.tipoDomicilioObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.direccion !== undefined &&
                  newErrors?.obligadoSolidario?.direccion?.tipoDomicilioId
                ) {
                  delete newErrors?.obligadoSolidario?.direccion
                    ?.tipoDomicilioId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.direccion.tipoDomicilioId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.direccion.tipoDomicilioObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeDomicilioOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.tipoDomicilioId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.tipoDomicilioId
              }
            />
            <SelectWithError
              label="Tipo de vivienda *"
              name="obligadoSolidario.direccion.tipoViviendaId"
              value={{
                label: formik?.values?.obligadoSolidario?.direccion
                  ?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion
                      ?.tipoViviendaObj?.[0]?.nombre
                  : "",
                value: formik?.values?.obligadoSolidario?.direccion
                  ?.tipoViviendaObj?.[0]?.nombre
                  ? formik?.values?.obligadoSolidario?.direccion
                      ?.tipoViviendaObj?.[0]?.nombre
                  : "",
              }}
              onChange={(e) => {
                let newErrors = formik.errors;
                if (
                  newErrors?.obligadoSolidario !== undefined &&
                  newErrors?.obligadoSolidario?.direccion !== undefined &&
                  newErrors?.obligadoSolidario?.direccion?.tipoViviendaId
                ) {
                  delete newErrors?.obligadoSolidario?.direccion
                    ?.tipoViviendaId;
                  formik.setErrors(newErrors);
                }

                formik.setFieldValue(
                  "obligadoSolidario.direccion.tipoViviendaId",
                  e.id
                );
                formik.setFieldValue(
                  "obligadoSolidario.direccion.tipoViviendaObj",
                  [e]
                );
              }}
              options={axiosData?.domicilio?.tipoDeViviendaOpciones?.map(
                (item) => {
                  return { ...item, label: item.nombre, value: item.nombre };
                }
              )}
              showErrorMessage={
                formik.errors?.obligadoSolidario?.direccion?.tipoViviendaId
              }
              errorMessage={
                formik.errors?.obligadoSolidario?.direccion?.tipoViviendaId
              }
            />
            <div
              style={{
                width: innerWidth < 700 ? "100%" : "55%",
                minWidth: "290px",
                maxWidth: "425px",
              }}
            >
              <span>Antigüedad en el domicilio actual *</span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  flexDirection: innerWidth < 700 ? "column" : "row",
                }}
              >
                <div
                  className="selectInputCont"
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                >
                  {formik?.values?.obligadoSolidario?.direccion
                    ?.tiempoViviendaAnios >= 0 ? (
                    <span className="select-label-no-null">Años *</span>
                  ) : (
                    <span className="select-label">Años *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="obligadoSolidario.direccion.tiempoViviendaAnios"
                    placeholder="Años *"
                    value={{
                      label:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAnios,
                      value:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAnios,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.obligadoSolidario !== undefined &&
                        newErrors?.obligadoSolidario?.direccion !== undefined &&
                        newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAnios
                      ) {
                        delete newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAnios;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "obligadoSolidario.direccion.tiempoViviendaAnios",
                        e.label
                      );
                    }}
                    options={aniosDomicilio?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                    error={
                      formik.errors?.obligadoSolidario?.direccion
                        ?.tiempoViviendaAnios
                    }
                  />
                </div>
                <div
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.obligadoSolidario?.direccion
                    ?.tiempoViviendaMeses >= 0 ? (
                    <span className="select-label-no-null">Meses *</span>
                  ) : (
                    <span className="select-label">Meses *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="obligadoSolidario.direccion.tiempoViviendaMeses"
                    placeholder="Meses *"
                    value={{
                      label:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaMeses,
                      value:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaMeses,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.obligadoSolidario !== undefined &&
                        newErrors?.obligadoSolidario?.direccion !== undefined &&
                        newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaMeses
                      ) {
                        delete newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaMeses;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "obligadoSolidario.direccion.tiempoViviendaMeses",
                        e.label
                      );
                    }}
                    options={mesesDomicilo?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                    error={
                      formik.errors?.obligadoSolidario?.direccion
                        ?.tiempoViviendaMeses
                    }
                  />
                </div>
              </div>
              {formik.errors?.obligadoSolidario?.direccion
                ?.tiempoViviendaMeses ? (
                <ErrorMessage
                  message={
                    formik.errors?.obligadoSolidario?.direccion
                      ?.tiempoViviendaMeses
                  }
                  width={55}
                />
              ) : null}
            </div>
            <div
              style={{
                width: innerWidth < 700 ? "100%" : "55%",
                minWidth: "290px",
                maxWidth: "425px",
              }}
            >
              <label>Antigüedad en el domicilio anterior *</label>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  flexDirection: innerWidth < 700 ? "column" : "row",
                }}
              >
                <div
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.obligadoSolidario?.direccion
                    ?.tiempoViviendaAntAnios >= 0 ? (
                    <span className="select-label-no-null">Años *</span>
                  ) : (
                    <span className="select-label">Años *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="obligadoSolidario.direccion.tiempoViviendaAntAnios"
                    placeholder="Años *"
                    value={{
                      label:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntAnios,
                      value:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntAnios,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.obligadoSolidario !== undefined &&
                        newErrors?.obligadoSolidario?.direccion !== undefined &&
                        newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntAnios
                      ) {
                        delete newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntAnios;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "obligadoSolidario.direccion.tiempoViviendaAntAnios",
                        e.label
                      );
                    }}
                    options={aniosDomicilio?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                    error={
                      formik.errors?.obligadoSolidario?.direccion
                        ?.tiempoViviendaAntAnios
                    }
                  />
                </div>
                <div
                  style={{ minWidth: innerWidth > 700 ? "45%" : "100%" }}
                  className="selectInputCont"
                >
                  {formik?.values?.obligadoSolidario?.direccion
                    ?.tiempoViviendaAntMeses >= 0 ? (
                    <span className="select-label-no-null">Meses *</span>
                  ) : (
                    <span className="select-label">Meses *</span>
                  )}
                  <Select
                    isSearchable={false}
                    styles={selectStylesDouble}
                    menuPlacement="top"
                    name="obligadoSolidario.direccion.tiempoViviendaAntMeses"
                    placeholder="Meses *"
                    value={{
                      label:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntMeses,
                      value:
                        formik?.values?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntMeses,
                    }}
                    onChange={(e) => {
                      let newErrors = formik.errors;
                      if (
                        newErrors?.obligadoSolidario !== undefined &&
                        newErrors?.obligadoSolidario?.direccion !== undefined &&
                        newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntMeses
                      ) {
                        delete newErrors?.obligadoSolidario?.direccion
                          ?.tiempoViviendaAntMeses;
                        formik.setErrors(newErrors);
                      }

                      formik.setFieldValue(
                        "obligadoSolidario.direccion.tiempoViviendaAntMeses",
                        e.label
                      );
                    }}
                    options={mesesDomicilo?.map((el, index) => {
                      return { ...el, label: index, value: index };
                    })}
                    error={
                      formik.errors?.obligadoSolidario?.direccion
                        ?.tiempoViviendaAntMeses
                    }
                  />
                </div>
              </div>
              {formik?.errors?.obligadoSolidario?.direccion
                ?.tiempoViviendaAntMeses ? (
                <div>
                  <span className="text__center text_red">
                    {
                      formik.errors?.obligadoSolidario?.direccion
                        ?.tiempoViviendaAntMeses
                    }
                  </span>
                </div>
              ) : null}
            </div>
            <div className="button-siguiente my-3">
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleRegresarSeccionPyme(seccionActual);
                }}
              >
                Atrás
              </SecondaryButton>
              <PrimaryButton type="submit">Siguiente</PrimaryButton>
            </div>
          </FormLayout>
        </div>
      </form>
    </>
  );
};

export default ObligadoSolidarioPF_PFAE;

function initialValues(modeloPfae, idTipoPersonaOS, NUM_OS, datosOs) {
  if (datosOs.datosOS.length == 0) {
    return {
      idCliente: modeloPfae.idCliente,
      idSolicitud: modeloPfae.idSolicitud,
      obligadoSolidario: {
        generales: {
          nombre: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          fechaNacimiento: "",
          paisNacimientoId: "",
          estadoNacimientoId: "",
          generoId: "",
          rfc: "",
          curp: "",
          folioVerificacionId: "",
          nacionalidadId: "",
          calidadMigratoria: "",
          estadoCivilId: "",
          nivelEducativoId: "",
          profesion: "",
          correoElectronico: "",
          telefono: "",
          celular: "",
          dependientesEconomicosMenores23: "",
          dependientesEconomicos: "",
          tipoPersonaId: idTipoPersonaOS,
          numero: NUM_OS,
          relacionSolicitante: "",
          numeroIdFiscal2: "",
          paisResidenciaFiscal2Id: "",
          nombreOtraEmpresa: "",
          nombreOtraEmpresaPfae: "",
          relacionRamaGobierno: false,
          paisResidenciaFiscal2: false,
          residenciaUsa: false,
          esAccionistaOtraEmpresa: false,
          esObligadoSolidarioOtraEmpresaPfae: false,
          excepcionesRfc: EXCEPCIONES_RFC,
        },
        direccion: {
          calle: "",
          numeroExterior: "",
          numeroInterior: "",
          piso: "",
          paisId: ID_PAIS_MEXICO,
          codigoPostal: "",
          colonia: "",
          municipioId: "",
          estadoId: "",
          tipoDomicilioId: "",
          tipoViviendaId: "",
          tiempoViviendaAnios: "",
          tiempoViviendaMeses: "",
          tiempoViviendaAntAnios: "",
          tiempoViviendaAntMeses: "",
          ineCertificada: INE_CERTIFICADA,
          aplicaIneComprobante: APLICA_INE_COMPROBANTE_OS_NO,
        },
      },
    };
  } else {
    return {
      idCliente: modeloPfae.idCliente,
      idSolicitud: modeloPfae.idSolicitud,
      obligadoSolidario: datosOs.datosOS,
    };
  }
}

function validationSchema(totalDependientesMenores23, totalDependientes) {
  return {
    obligadoSolidario: Yup.object().shape({
      generales: Yup.object().shape({
        nombre: Yup.string()
          .required("Dato requerido.")
          .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
        apellidoPaterno: Yup.string()
          .required("Dato requerido.")
          .matches(/^[aA-zZÑñ\s]+$/, "Solo se permiten letras "),
        apellidoMaterno: Yup.string().matches(
          /^[aA-zZÑñ\s]+$/,
          "Solo se permiten letras "
        ),
        fechaNacimiento: Yup.string()
          .required("Dato requerido.")
          .test(
            "fechaNacimiento",
            "Debe ser mayor a 18 años y menor a 70 años.",
            (value) =>
              compareDatesOrigin(
                fechaMaxMayorEdadYYYMMDD(),
                fechaMinEdadYYYMMDD(),
                value
              )
          ),
        paisNacimientoId: Yup.number().required("Dato requerido."),
        estadoNacimientoId: Yup.string().when("paisNacimientoId", {
          is: (value) => value === ID_PAIS_MEXICO,
          then: Yup.string().required("Dato requerido."),
        }),
        generoId: Yup.number().required("Dato requerido."),
        rfc: Yup.string()
          .required("Dato requerido.")
          .test("rfc", "RFC inválido.", (item, testContext) => {
            return testContext.parent.rfc !== undefined
              ? testContext.parent.rfc.trim().length > 0
                ? validarRFCTipoPersona(testContext.parent.rfc.trim(), ID_PF)
                : false
              : false;
          }),
        curp: Yup.string().required("Dato requerido."),
        folioVerificacionId: Yup.string().required("Dato requerido."),
        nacionalidadId: Yup.number().required("Dato requerido."),
        calidadMigratoria: Yup.string().when("nacionalidadId", {
          is: (value) => value !== NACIONALIDAD_MEXICAN0,
          then: Yup.string().required("Dato requerido."),
        }),
        estadoCivilId: Yup.number().required("Dato requerido."),
        nivelEducativoId: Yup.number().required("Dato requerido."),
        profesion: Yup.string().required("Dato requerido."),
        correoElectronico: Yup.string()
          .email("Correo eléctronico inválido")
          .required("Dato requerido."),
        telefono: Yup.string()
          .required("Dato requerido.")
          .test(
            "telefono",
            "Teléfono capturado de forma incorrecta.",
            (item, testContext) => {
              return validarNumeroTelefono(testContext.parent.telefono);
            }
          ),
        celular: Yup.string()
          .required("Dato requerido.")
          .test(
            "celular",
            "Celular capturado de forma incorrecta.",
            (item, testContext) => {
              return validarNumeroTelefono(testContext.parent.celular);
            }
          ),
        dependientesEconomicosMenores23:
          Yup.number().required("Dato requerido."),
        dependientesEconomicos: Yup.number()
          .required("Dato requerido.")
          .test(
            "dependientesEconomicos",
            "Debe ser Mayor o igual al número de dependientes menores de 23 años.",
            (item, testContext) => {
              return dependeintesMajor23(
                totalDependientesMenores23,
                totalDependientes
              );
            }
          ),
        relacionSolicitante: Yup.string().required("Dato requerido."),
        nombreOtraEmpresa: Yup.string().when("esAccionistaOtraEmpresa", {
          is: true,
          then: Yup.string().required("Este campo es obligatorio."),
        }),
        nombreOtraEmpresaPfae: Yup.string().when(
          "esObligadoSolidarioOtraEmpresaPfae",
          {
            is: true,
            then: Yup.string().required("Este campo es obligatorio."),
          }
        ),
      }),
      direccion: Yup.object().shape({
        calle: Yup.string().required("Dato requerido."),
        numeroExterior: Yup.string().required("Dato requerido."),
        numeroInterior: Yup.string().when("tipoViviendaId", {
          is: TIPO_VIVIENDA_EDIFICIO,
          then: Yup.string().required("Dato requerido."),
          otherwise: Yup.string().when("tipoViviendaId", {
            is: TIPO_DEPARTAMENTO_APARTAMENTO,
            then: Yup.string().required("Dato requerido."),
          }),
        }),
        piso: Yup.string().when("tipoViviendaId", {
          is: TIPO_VIVIENDA_EDIFICIO,
          then: Yup.string().required("Dato requerido."),
          otherwise: Yup.string().when("tipoViviendaId", {
            is: TIPO_DEPARTAMENTO_APARTAMENTO,
            then: Yup.string().required("Dato requerido."),
          }),
        }),
        paisId: Yup.string().required("Dato requerido."),
        codigoPostal: Yup.string().required("Dato requerido."),
        colonia: Yup.string().required("Dato requerido."),
        municipioId: Yup.string().required("Dato requerido."),
        estadoId: Yup.string().required("Dato requerido."),
        tipoDomicilioId: Yup.string().required("Dato requerido."),
        tipoViviendaId: Yup.string().required("Dato requerido."),
        tiempoViviendaAnios: Yup.number().required("Dato requerido."),
        tiempoViviendaMeses: Yup.number()
          .required("Dato requerido.")
          .test(
            "tiempoViviendaMeses",
            "Antigüedad en el Domicilio Actual, debe ser mayor a cero.",
            (item, testContext) => {
              return tiempoViviendaActual(
                testContext.parent.tiempoViviendaMeses,
                testContext.parent.tiempoViviendaAnios
              );
            }
          ),
        tiempoViviendaAntAnios: Yup.number().required("Dato requerido."),
        tiempoViviendaAntMeses: Yup.number()
          .required("Dato requerido.")
          .test(
            "tiempoViviendaAntMeses",
            "Dato requerido.",
            (item, testContext) => {
              return tiempoViviendaAnterior(
                testContext.parent.tiempoViviendaAntMeses,
                testContext.parent.tiempoViviendaAntAnios
              );
            }
          ),
      }),
    }),
  };
}
