import React, { useEffect, useRef, useState, Fragment } from "react";
import {useNavigate} from "react-router-dom"
import { animated, useTransition } from "react-spring";
import FormularioPersonaMoral from "./components/PM/FormularioPersonaMoral";
import FormularioPFAE from "./components/PFAE/FormularioPFAE";
import ResumenCrediticio from "./components/ResumenCrediticio/ResumenCrediticio.js";
import {
  api_obtener_detalle_cliente,
  api_load_tablaAmortizacion,
  api_obtener_detalle_solicitud,
  api_eliminar_os_solicitud_pm,
  api_eliminar_os_solicitud_pfae,
  api_actualizar_datos_resumen,
  api_catalogos_personales,
  api_limites_monto_credito,
  api_anios_negocio,
  api_actualizar_email_cliente,
  api_envio_email_cliente,
} from "../../api/api_servicios_rest";
import {
  ID_PFAE,
  ID_PM,
  ID_PF,
  KEY_PFAE,
  KEY_PM,
  KEY_PF,
  OS_CERO,
  OS_UNO,
  OS_DOS,
  OS_TRES,
  OS_CUATRO,
  OS_CINCO,
  ESTADO_CIVIL_SOCIEDAD_CONYUGAL,
  PESTANIA_SOLICITANTE_CONYUGE,
  SECCION_ZERO,
  SECCION_GENERALES,
  SECCION_DOMICILIO,
  SECCION_DOMICILIO_FISCAL,
  SECCION_DOMICILIO_COMERCIAL,
  SECCION_ECONOMICOS,
  SECCION_PRINCIPAL_ACCIONISTA,
  SECCION_SOLICITANTE_CONYUGE,
  SECCION_PATRIMONIO,
  SECCION_HISTORIAL_CREDITICIO,
  SECCION_CARGA_DOCUMENTOS,
  SECCION_REPRESENTANTE_LEGAL,
  SECCION_DOMICILIO_NEGOCIO,
  SECCION_DOMICILIO_COMERCIAL_PM,
  SECCION_INFORMACION_NEGOCIO,
  SECCION_SOCIOS,
  SECCION_PA_CONYUGE_PM,
  SECCION_OBLIGADO_SOLIDARIO,
  SECCION_OS_DOS,
  SECCION_OS_TRES,
  SECCION_OS_CUATRO,
  SECCION_OS_CINCO,
  SECCION_CONYUGE_OS_UNO,
  SECCION_CONYUGE_OS_DOS,
  SECCION_CONYUGE_OS_TRES,
  SECCION_CONYUGE_OS_CUATRO,
  SUB_MENU_CADENA_OS,
  SUB_MENU_CADENA_GRALES,
  SUB_MENU_CADENA_HISTORIAL,
  TIPO_PARTICIPANTE_OS,
  OS_MAX_PM,
  PASO_SOLICITUD_HISTORIAL_CREDITICIO,
  MENSAJE_ERROR_TOKEN_INVALIDO,
  MONTO_VALIDO_EXPRESS_SIMPLE_PFAE,
  MONTO_VALIDO_EXPRESS_SIMPLE_PM,
  PLAZO_NO_VALIDO_EXPRESS_SIMPLE,
  PLAZO_MAX_VALIDO_EXPRESS_SIMPLE,
  STATUS_EXITO
} from "../../utils/constantes";
import Swal from "sweetalert2";
import Popup from "../tools/Popup";
import NavBarHistorialCrediticio from "./components/NavBarHistorialCrediticio";
import {
  sessionStorageAutoServicio,
  sessionStorageDatosOperacion,
  sessionStorageIdSolicitud,
  sessionStorageContinuarSolicitud,
  sessionTasaAnualAts,
} from "../../config/Variables";
import StepProgressBar from "../helpers/ProgressBar/ProgressBar";
import "./Formulario.css";
import FormMenu from "./components/FormMenu/FormMenu";
import { PrimaryButton, DisabledButton } from "../basics/Buttons/Buttons";
import IconClose from "./../../icons/crossIcon.png";
import {
  sweetAlertError,
  sweetAlertObligadoSolidario,
  sweetAlertSuccess,
  sweetAlertWarning,
  sweetAlertWithConfirmed,
} from "../helpers/alertas/alertas";
import {
  bancosTransitionConfig,
  cambiarEstatusHistorialCrediticioHelper,
  mostrarPestaniasPymePfaeHelper,
} from "./formularioHelper";
import { mensajeMostrarUsuario } from "../../utils/mensajesServicios";
import InputWithError from "../basics/InputWithError/InputWithError";
import {
  validarFormatoEmail,
} from "../../utils/validaciones";

const Formulario = ({ webView }) => {
  const navigate = useNavigate()

  const [cantidadObligadosSolidarios, setCantidadObligadosSolidarios] =
    useState(0);
  const [cantidadConyugeOS, setCantidadConyugeOS] = useState(0);
  const [, setStatePAConyuge] = useState(false);
  const [regresarCotizador, setRegresarCotizador] = useState(false);
  const [formSection, setFormSection] = useState("Generales");
  const [pago, setPago] = useState("0.0");
  const [emailCliente, setEmailCliente] = useState("");
  const [emailValido, setEmailValido] = useState(false);
  const [emailClienteError, setEmailClienteError] = useState("");
  

  // MENU
  const [subMenuSelected, setSubMenuSelected] = useState("generales");

  // tabla amortizacion
  const [plazo, setPlazo] = useState();
  const [showTabla, setShowTabla] = useState(false);
  const [tablaData, setTablaData] = useState([]);

  //Tabla simulador
  const [showBotonActualizar, setShowBotonActualizar] = useState(false);

  // POPUP
  const [showResult, setShowResult] = useState(false);
  const [showFinAts, setShowFinAts] = useState(false);
  const [loading, setLoading] = useState(false);

  // AXIOS DATA
  const [axiosData, setAxiosData] = useState(undefined);
  const [aniosSector, setAniosSector] = useState();

  //VARIABLES GLOBALES
  const [tipoPersona, setTipoPersona] = useState("");
  const [idTipoPersona, setIdTipoPersona] = useState();
  const [folioSolicitud, setFolioSolicitud] = useState();
  const [ultimoPasoSolicitud, setUltimoPasoSolicitud] = useState(1);
  const [, setAvanzarSolicitud] = useState(false);
  const [, setObligadosSolidariosInformacionBasica] = useState([]);
  const [domicilioFiscalIgual, setDomicilioFiscalIgual] = useState(false);
  const [, esObligadoSolidario] = useState("");
  const aniosDomicilio = Array.apply(null, Array(81));
  const mesesDomicilo = Array.apply(null, Array(12));
  const anios = Array.apply(null, Array(99));

  const [, setStyleHr] = useState("none");
  const [ultimoPasoUsuario, setUltimoPasoUsuario] = useState(1);

  const [conyugeOS, setConyugeOS] = useState(false);
  const [disabledBtnAgregarOs, setDisabledBtnAgregarOs] = useState(false);

  const [mostrarSeccionPfae, setMostrarSeccionPfae] = useState({
    general: false,
    domicilio: false,
    domicilioFiscal: false,
    domicilioComercial: false,
    economicos: false,
    obligadosSolidario: false,
    historialCrediticio: false,
    solicitanteConyuge: false,
  });

  const [mostrarSeccionPm, setMostrarSeccionPm] = useState({
    general: false,
    representanteLegal: false,
    domicilioNegocio: false,
    domicilioComercial: false,
    informacionNegocio: false,
    economicos: false,
    socios: false,
    principalAccionista: false,
    principalAccionistaConyuge: false,
    obligadosSolidario: false,
    historialCrediticio: false,
  });

  const [resultadoEvaluacionRiesgos, setResultadoEvaluacionRiesgos] = useState({
    evaluacionRiesgos: true,
    folioSolicitud: "",
    mensajeEvaluacion: "",
    domicilioComercial: "",
    tasa: "",
    valorTasa: "",
  });

  const [datosOSUno, setDatosOSUno] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSDos, setDatosOSDos] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSTres, setDatosOSTres] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSCuatro, setDatosOSCuatro] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
    showConyugeOS: false,
  });

  const [datosOSCinco, setDatosOSCinco] = useState({
    datosOS: [],
    conyuge: [],
    idTipoPersonaOs: "",
    idEstadoCivilOs: "",
  });

  const [modeloPfae, setModeloPfae] = useState({});

  const [modeloPm, setModeloPm] = useState({});

  useEffect(() => {
    const obtenerLimitesMonto = async () => {
      try {
        const response = await api_limites_monto_credito();
        sessionStorage.setItem(sessionTasaAnualAts, response?.data?.tasaAnual);
        document.getElementById("root").scrollIntoView(true);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerLimitesMonto();
  }, []);

  // REQUEST
  useEffect(() => {
    const obtenerInfo = async () => {
      try {
        if (axiosData === undefined) {
          setLoading(true);
          const responseCatalogosPersonales = await api_catalogos_personales();
          setAxiosData(responseCatalogosPersonales.data);

          const response = await api_anios_negocio();
          setAniosSector(response.data);

          obtenerDatosSolicitud();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    obtenerInfo();
  }, [axiosData]);

  useEffect(() => {
    if (showTabla) {
      obtenerTablaAmortizacion().then((response) => response);
    }
  }, [showTabla]);

  useEffect(() => {
    setCantidadObligadosSolidarios(cantidadObligadosSolidarios);
  }, [cantidadObligadosSolidarios]);

  const obtenerTablaAmortizacion = async () => {
    const data = {
      plazo: plazo,
      monto: creditoFloat,
    };

    try {
      const response = await api_load_tablaAmortizacion(data);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setTablaData(data.resumenSimulacion);
        } else {
          sweetAlertError("Ocurrió un error al obtener tabla amortización");
        }
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const obtenerDatosSolicitud = () => {
    const continuarSolicitud = sessionStorage.getItem(
      sessionStorageContinuarSolicitud
    );

    if (continuarSolicitud === "true") {
      let idSolicitud = sessionStorage.getItem(sessionStorageIdSolicitud);
      if (idSolicitud !== undefined && idSolicitud !== null) {
        obtenerDetalleSolicitud(idSolicitud).then((response) => response);
      }
    } else {
      obtenerDetalleCliente().then((response) => response);
    }
    setLoading(false);
  };

  const obtenerDetalleCliente = async () => {
    const data = {
      idDatosTemporalesAutoservicio: sessionStorage.getItem(
        sessionStorageAutoServicio
      ),
      idDatosOperacion: sessionStorage.getItem(sessionStorageDatosOperacion),
    };
    try {
      if (
        data?.idDatosTemporalesAutoservicio !== null &&
        data?.idDatosOperacion !== null
      ) {
        const response = await api_obtener_detalle_cliente(data);
        if (response.statusText === "OK" || response.status === STATUS_EXITO) {
          const data = response.data;
          if (data.success) {
            const datosSolicitud = data.datosCliente;
            setPlazo(datosSolicitud.datosOperacion.plazo);
            setCredito(datosSolicitud.datosOperacion.montoCredito);
            setCreditoFloat(datosSolicitud.datosOperacion.montoCreditoFloat);
            setProductoPyme(datosSolicitud.datosOperacion.tipoDeCreditoNombre);
            setIdTipoPersona(data.idTipoPersona);
            calculoMensualidad(
              datosSolicitud?.datosOperacion?.montoCredito,
              datosSolicitud?.datosOperacion?.plazo
            );
            if (data.idTipoPersona === ID_PFAE) {
              setearDatosSolicitudPFAE(datosSolicitud, 1);
            } else {
              setearDatosSolicitudPM(datosSolicitud, 1);
            }
            handleSeccionPyme(SECCION_ZERO);
            setEmailCliente(datosSolicitud.generales.correoElectronico);
            if(datosSolicitud.generales.correoElectronico != null && datosSolicitud.generales.correoElectronico.length > 0){
              setEmailValido(true);
            }
          } else {
            sweetAlertError("Ocurrió un error al obtener datos cliente");
          }
        } else {
          return false;
        }
        setLoading(false);
      } else {
        if (data.idDatosOperacion === null) {
          sweetAlertWithConfirmed(
            "No puede acceder a esta página, si no ha seleccionado un plazo y monto de crédito",
            webView
          );
        } else {
          sweetAlertWithConfirmed(
            "No puede acceder a esta página, si no ha solicitado su crédito",
            webView
          );
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const setearDatosSolicitudPFAE = (datosSolicitud, solicitudUltimoPaso) => {
    if (
      datosSolicitud?.conyuge &&
      datosSolicitud?.conyuge?.idConyuge !== null
    ) {
      if (datosSolicitud?.conyuge?.generales) {
        if (datosSolicitud?.conyuge?.generales?.nivelEducativoId == null) {
          datosSolicitud.conyuge.generales.nivelEducativoId = "";
        }
        if (datosSolicitud?.conyuge?.generales?.estadoCivilId == null) {
          datosSolicitud.conyuge.generales.estadoCivilId = "";
        }
        if (
          datosSolicitud?.conyuge?.generales?.dependientesEconomicos == null
        ) {
          datosSolicitud.conyuge.generales.dependientesEconomicos = "";
        }
        if (
          datosSolicitud?.conyuge?.generales?.dependientesEconomicosMenores23 ==
          null
        ) {
          datosSolicitud.conyuge.generales.dependientesEconomicosMenores23 = "";
        }
      }

      if (datosSolicitud?.conyuge?.direccion) {
        if (datosSolicitud?.conyuge?.direccion?.paisId === null) {
          datosSolicitud.conyuge.direccion.paisId = "";
        }
        if (datosSolicitud?.conyuge?.direccion?.tipoDomicilioId === null) {
          datosSolicitud.conyuge.direccion.tipoDomicilioId = "";
        }
        if (datosSolicitud?.conyuge?.direccion?.tipoViviendaId === null) {
          datosSolicitud.conyuge.direccion.tipoViviendaId = "";
        }
        if (datosSolicitud?.conyuge?.direccion?.tipoViviendaId === null) {
          datosSolicitud.conyuge.direccion.tipoViviendaId = "";
        }
      }

      if (datosSolicitud?.conyuge?.datosDelNegocio) {
        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.importaExportaId === null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.importaExportaId = "";
        }

        if (datosSolicitud?.conyuge?.datosDelNegocio?.fechaAltaShcp === null) {
          datosSolicitud.conyuge.datosDelNegocio.fechaAltaShcp = "";
        }

        if (datosSolicitud?.conyuge?.datosDelNegocio?.sectorId === null) {
          datosSolicitud.conyuge.datosDelNegocio.sectorId = "";
        }

        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.giroEmpresarialId === null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.giroEmpresarialId = "";
        }

        if (datosSolicitud?.conyuge?.datosDelNegocio?.actividadId === null) {
          datosSolicitud.conyuge.datosDelNegocio.actividadId = "";
        }

        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.aniosExperienciaSector ===
          null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.aniosExperienciaSector = "";
        }

        if (
          datosSolicitud?.conyuge?.datosDelNegocio?.numeroSucursales === null
        ) {
          datosSolicitud.conyuge.datosDelNegocio.numeroSucursales = "";
        }
      }
    }

    if (datosSolicitud?.idDatosTempAutoservicio === null) {
      const idDatosTemp = parseInt(
        sessionStorage.getItem(sessionStorageAutoServicio)
      );
      datosSolicitud.idDatosTempAutoservicio = idDatosTemp;
    }

    if (datosSolicitud.idDatosOperacion === null) {
      datosSolicitud.idDatosOperacion =
        datosSolicitud.datosOperacion.idDatosOperacion;
    }

    setModeloPfae(datosSolicitud);

    setTipoPersona("PFAE");
    let cantidadOsSolicitudConyuge = cantidadConyugeOS;
    if (
      datosSolicitud?.conyuge != null &&
      datosSolicitud?.conyuge?.idConyuge != null &&
      datosSolicitud?.conyuge?.generales?.esObligadoSolidario
    ) {
      cantidadOsSolicitudConyuge = cantidadOsSolicitudConyuge + 1;
    }

    if (
      datosSolicitud?.obligadosSolidarios !== undefined &&
      datosSolicitud?.obligadosSolidarios !== null &&
      datosSolicitud?.obligadosSolidarios?.length > 0
    ) {
      setCantidadObligadosSolidarios(
        datosSolicitud?.obligadosSolidarios?.length
      );
      obtenerDatosOblidadoSolidario(
        datosSolicitud?.obligadosSolidarios,
        cantidadOsSolicitudConyuge
      );
    } else {
      setCantidadConyugeOS(cantidadOsSolicitudConyuge);
    }

    verificarSeccionesSolicitud(datosSolicitud, ID_PFAE, solicitudUltimoPaso);
  };

  const setearDatosSolicitudPM = (datosSolicitud, solicitudUltimoPaso) => {
    if (datosSolicitud?.principalAccionista) {
      if (
        datosSolicitud?.principalAccionista?.conyuge &&
        datosSolicitud?.principalAccionista?.conyuge?.idConyuge !== null
      ) {
        if (datosSolicitud?.principalAccionista?.conyuge?.generales) {
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.nivelEducativoId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.nivelEducativoId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.estadoCivilId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.estadoCivilId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.dependientesEconomicos === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.dependientesEconomicos =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.generales
              ?.dependientesEconomicosMenores23 === null
          ) {
            datosSolicitud.principalAccionista.conyuge.generales.dependientesEconomicosMenores23 =
              "";
          }
        }

        if (datosSolicitud?.principalAccionista?.conyuge?.direccion) {
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion?.paisId ===
            null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.paisId = "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tipoDomicilioId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tipoDomicilioId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tipoViviendaId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tipoViviendaId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tipoViviendaId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tipoViviendaId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tiempoViviendaMeses === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tiempoViviendaMeses =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.direccion
              ?.tiempoViviendaAntMeses === null
          ) {
            datosSolicitud.principalAccionista.conyuge.direccion.tiempoViviendaAntMeses =
              "";
          }
        }

        if (datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio) {
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.importaExportaId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.importaExportaId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.sectorId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.sectorId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.giroEmpresarialId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.giroEmpresarialId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.actividadId === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.actividadId =
              "";
          }
          if (
            datosSolicitud?.principalAccionista?.conyuge?.datosDelNegocio
              ?.aniosExperienciaSector === null
          ) {
            datosSolicitud.principalAccionista.conyuge.datosDelNegocio.aniosExperienciaSector =
              "";
          }
        }
      }
    }

    if (datosSolicitud?.idDatosTempAutoservicio === null) {
      const idDatosTemp = parseInt(
        sessionStorage.getItem(sessionStorageAutoServicio)
      );
      datosSolicitud.idDatosTempAutoservicio = idDatosTemp;
    }

    if (datosSolicitud.idDatosOperacion === null) {
      datosSolicitud.idDatosOperacion =
        datosSolicitud.datosOperacion.idDatosOperacion;
    }

    setModeloPm(datosSolicitud);

    let cantidadOsSolicitudConyuge = cantidadConyugeOS;
    if (datosSolicitud?.principalAccionista?.idPrincipalAccionista !== null) {
      if (
        datosSolicitud?.principalAccionista?.conyuge !== null &&
        datosSolicitud?.principalAccionista?.conyuge?.generales
          ?.esObligadoSolidario
      ) {
        cantidadOsSolicitudConyuge = cantidadOsSolicitudConyuge + 1;
      }
    }

    if (
      datosSolicitud?.obligadosSolidarios !== undefined &&
      datosSolicitud?.obligadosSolidarios !== null &&
      datosSolicitud?.obligadosSolidarios.length > 0
    ) {
      setCantidadObligadosSolidarios(
        datosSolicitud?.obligadosSolidarios.length
      );
      obtenerDatosOblidadoSolidario(
        datosSolicitud?.obligadosSolidarios,
        cantidadOsSolicitudConyuge
      );
    } else {
      setCantidadConyugeOS(cantidadOsSolicitudConyuge);
    }
    verificarSeccionesSolicitud(datosSolicitud, ID_PM, solicitudUltimoPaso);
    setTipoPersona("PM");
  };

  const obtenerDatosOblidadoSolidario = (
    listObligadosSolidarios,
    cantidadOsSolicitudConyuge
  ) => {
    for (let i = 0; i < listObligadosSolidarios.length; i++) {
      let datosOs = [];
      let tipoPersonaOs = "";
      let conyuge = [];
      let estadoCivilId = "";
      switch (listObligadosSolidarios[i].tipoPersonaKey) {
        case KEY_PM:
          tipoPersonaOs = ID_PM;
          datosOs = listObligadosSolidarios[i].obligadoSolidarioPM;
          break;
        case KEY_PFAE:
          tipoPersonaOs = ID_PFAE;
          datosOs = listObligadosSolidarios[i].obligadoSolidario;
          conyuge = listObligadosSolidarios[i].obligadoSolidario.conyuge;
          estadoCivilId =
            listObligadosSolidarios[i].obligadoSolidario?.generales
              ?.estadoCivilId;
          break;
        case KEY_PF:
          tipoPersonaOs = ID_PF;
          datosOs = listObligadosSolidarios[i].obligadoSolidario;
          conyuge = listObligadosSolidarios[i].obligadoSolidario.conyuge;
          estadoCivilId =
            listObligadosSolidarios[i].obligadoSolidario?.generales
              ?.estadoCivilId;
          break;
      }

      switch (listObligadosSolidarios[i].numero) {
        case OS_UNO:
          setDatosOSUno({
            datosOS: datosOs,
            idTipoPersonaOs: tipoPersonaOs,
            conyuge: conyuge,
            idEstadoCivilOs: estadoCivilId,
          });
          break;
        case OS_DOS:
          setDatosOSDos({
            datosOS: datosOs,
            idTipoPersonaOs: tipoPersonaOs,
            conyuge: conyuge,
            idEstadoCivilOs: estadoCivilId,
          });
          break;
        case OS_TRES:
          setDatosOSTres({
            datosOS: datosOs,
            idTipoPersonaOs: tipoPersonaOs,
            conyuge: conyuge,
            idEstadoCivilOs: estadoCivilId,
          });
          break;
        case OS_CUATRO:
          setDatosOSCuatro({
            datosOS: datosOs,
            idTipoPersonaOs: tipoPersonaOs,
            conyuge: conyuge,
            idEstadoCivilOs: estadoCivilId,
          });
          break;
        case OS_CINCO:
          setDatosOSCinco({
            datosOS: datosOs,
            idTipoPersonaOs: tipoPersonaOs,
            conyuge: conyuge,
            idEstadoCivilOs: estadoCivilId,
          });
          break;
      }

      if (listObligadosSolidarios[i]?.tipoPersonaKey !== KEY_PM) {
        if (
          listObligadosSolidarios[i]?.obligadoSolidario?.conyuge !== null &&
          listObligadosSolidarios[i]?.obligadoSolidario?.conyuge?.generales
            ?.esObligadoSolidario
        ) {
          cantidadOsSolicitudConyuge = cantidadOsSolicitudConyuge + 1;
        }
      }
    }

    setCantidadConyugeOS(cantidadOsSolicitudConyuge);
  };

  const verificarSeccionesSolicitud = (
    datosSolicitud,
    idTipoPersona,
    ultimoPasoSolicitud
  ) => {
    let ultimoPaso;

    let general = true;
    let representanteLegal = false;
    let domicilioNegocio = false;
    let domicilioComercial = false;
    let informacionNegocio = false;
    let economicos = false;
    let socios = false;
    let principalAccionista = false;
    let principalAccionistaConyuge = false;
    let obligadosSolidario = false;
    let historialCrediticio = false;
    let domicilio = false;
    let domicilioFiscal = false;
    let solicitanteConyuge = false;

    if (idTipoPersona === ID_PFAE) {
      if (datosSolicitud.idSolicitud == null) {
        general = true;
      } else {
        general = true;
        //Se quedo en la pantalla de Generales
        ultimoPaso = SECCION_GENERALES;
        setSubMenuSelected("generales");
        setFormSection("Generales");
        setSeccion(SECCION_GENERALES);

        if (datosSolicitud?.direccion?.idDireccion !== null &&
            datosSolicitud?.direccion?.tiempoViviendaAntMeses !== null
        ) {
          domicilio = true;
          setSeccion(SECCION_DOMICILIO);

          if (
            datosSolicitud?.domicilioFiscal?.idDatosDelNegocio !== null &&
            datosSolicitud?.domicilioFiscal?.calle !== null &&
            datosSolicitud?.domicilioFiscal?.codigoPostal !== null
          ) {
            domicilioFiscal = true;
            setSeccion(SECCION_DOMICILIO_FISCAL);

            if (
              datosSolicitud?.domicilioComercial?.idDatosDelNegocio !== null
            ) {
              domicilioComercial = true;
              setSeccion(SECCION_DOMICILIO_COMERCIAL);

              if (
                datosSolicitud?.datosEconomicos?.idDatosEconomicosDelNegocio !==
                null
              ) {
                economicos = true;
                setSeccion(SECCION_ECONOMICOS);

                if (
                  datosSolicitud.conyuge !== null &&
                  datosSolicitud.conyuge.idConyuge !== null
                ) {
                  solicitanteConyuge = true;
                  //Se quedo en la pantalla de Solicitante conyuge
                  ultimoPaso = SECCION_SOLICITANTE_CONYUGE;
                  setConyugeOS(
                    datosSolicitud.conyuge.generales.esObligadoSolidario
                  );
                  setSeccion(SECCION_SOLICITANTE_CONYUGE);
                  if (datosSolicitud.conyuge.generales.esObligadoSolidario) {
                    setSubMenuSelected("oSolidario");
                    setFormSection("Obligados Solidarios");
                  }
                }

                if (
                  datosSolicitud?.obligadosSolidarios !== undefined &&
                  datosSolicitud?.obligadosSolidarios !== null &&
                  datosSolicitud?.obligadosSolidarios?.length > 0
                ) {
                  obligadosSolidario = true;

                  //Se quedo en la pantalla de Obligados Solidarios
                  ultimoPaso = SECCION_OBLIGADO_SOLIDARIO;
                  setSubMenuSelected("oSolidario");
                  setFormSection("Obligados Solidarios");
                  setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                }

                if (
                  ultimoPasoSolicitud >= PASO_SOLICITUD_HISTORIAL_CREDITICIO
                ) {
                  historialCrediticio = true;

                  //Se quedo en la pantalla de Historial Crediticio
                  ultimoPaso = SECCION_HISTORIAL_CREDITICIO;
                  setSubMenuSelected("historialcrediticio");
                  setFormSection("Historial Crediticio");
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                }
              }
            }
          }
        }

        if (solicitanteConyuge === false) {
          if (obligadosSolidario === false) {
            if (domicilio === false) {
              setSeccion(SECCION_DOMICILIO);
              domicilio = true;
            } else {
              if (domicilioFiscal === false) {
                setSeccion(SECCION_DOMICILIO_FISCAL);
                domicilioFiscal = true;
              } else {
                if (domicilioComercial === false) {
                  setSeccion(SECCION_DOMICILIO_COMERCIAL);
                  domicilioComercial = true;
                } else {
                  setSeccion(SECCION_ECONOMICOS);
                  economicos = true;
                }
              }
            }
          }
        }
      }

      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        general: general,
        domicilio: domicilio,
        domicilioFiscal: domicilioFiscal,
        domicilioComercial: domicilioComercial,
        economicos: economicos,
        obligadosSolidario: obligadosSolidario,
        historialCrediticio: historialCrediticio,
        solicitanteConyuge: solicitanteConyuge,
      });
    } else {
      if (datosSolicitud.idSolicitud === null) {
        general = true;
      } else {
        general = true;

        //Se quedo en la pantalla de Generales
        ultimoPaso = SECCION_GENERALES;
        setSubMenuSelected("generales");
        setFormSection("Generales");
        setSeccion(SECCION_GENERALES);

        if (datosSolicitud?.representanteLegal?.idContactoDeLaEmpresa) {
          representanteLegal = true;
          setSeccion(SECCION_REPRESENTANTE_LEGAL);

          if (datosSolicitud.direccion?.idDireccion) {
            domicilioNegocio = true;
            setSeccion(SECCION_DOMICILIO_NEGOCIO);

            if (
              datosSolicitud?.domicilioComercial?.idDatosDelNegocio !== null &&
              datosSolicitud?.domicilioComercial?.calle !== null &&
              datosSolicitud?.domicilioComercial?.codigoPostal !== null
            ) {
              domicilioComercial = true;
              setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);

              if (
                datosSolicitud?.datosDelNegocio?.idDatosDelNegocio &&
                datosSolicitud?.datosDelNegocio?.actividadId &&
                datosSolicitud?.datosDelNegocio?.sectorId &&
                datosSolicitud?.datosDelNegocio?.tipoLocalId
              ) {
                informacionNegocio = true;
                setSeccion(SECCION_INFORMACION_NEGOCIO);

                if (
                  datosSolicitud.datosEconomicos?.idDatosEconomicosDelNegocio
                ) {
                  economicos = true;
                  setSeccion(SECCION_ECONOMICOS);

                  if (
                    datosSolicitud?.sociosPrincipales !== null &&
                    datosSolicitud?.sociosPrincipales.length
                  ) {
                    socios = true;

                    //Se quedo en la pantalla de Socios
                    ultimoPaso = SECCION_SOCIOS;
                    setSeccion(SECCION_SOCIOS);

                    if (
                      datosSolicitud?.principalAccionista?.idPrincipalAccionista
                    ) {
                      principalAccionista = true;

                      //Se quedo en la pantalla de Principal Accionista
                      ultimoPaso = SECCION_PRINCIPAL_ACCIONISTA;
                      setSubMenuSelected("oSolidario");
                      setFormSection("Obligados Solidarios");
                      setSeccion(SECCION_PRINCIPAL_ACCIONISTA);

                      if (
                        datosSolicitud?.principalAccionista?.conyuge &&
                        datosSolicitud?.principalAccionista?.conyuge?.idConyuge
                      ) {
                        principalAccionistaConyuge = true;

                        //Se quédo en la pantalla de Principal Accionista conyuge
                        ultimoPaso = SECCION_PA_CONYUGE_PM;
                        setSeccion(SECCION_PA_CONYUGE_PM);
                      }
                    }

                    if (
                      datosSolicitud?.obligadosSolidarios !== undefined &&
                      datosSolicitud?.obligadosSolidarios !== null &&
                      datosSolicitud?.obligadosSolidarios.length > 0
                    ) {
                      obligadosSolidario = true;

                      //Se quedo en la pantalla de Obligados Solidarios
                      ultimoPaso = SECCION_OBLIGADO_SOLIDARIO;
                      setSeccion(SECCION_OBLIGADO_SOLIDARIO);
                    }

                    if (
                      ultimoPasoSolicitud >= PASO_SOLICITUD_HISTORIAL_CREDITICIO
                    ) {
                      historialCrediticio = true;

                      //Se quedo en la pantalla de Historial Crediticio
                      ultimoPaso = SECCION_HISTORIAL_CREDITICIO;
                      setSubMenuSelected("historialcrediticio");
                      setFormSection("Historial Crediticio");
                      setSeccion(SECCION_HISTORIAL_CREDITICIO);
                    }
                  }
                }
              }
            }
          }
        }

        if (principalAccionista === false) {
          if (representanteLegal === false) {
            setSeccion(SECCION_REPRESENTANTE_LEGAL);
            representanteLegal = true;
          } else {
            if (domicilioNegocio === false) {
              setSeccion(SECCION_DOMICILIO_NEGOCIO);
              domicilioNegocio = true;
            } else {
              if (domicilioComercial === false) {
                setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);
                domicilioComercial = true;
              } else {
                if (informacionNegocio === false) {
                  setSeccion(SECCION_INFORMACION_NEGOCIO);
                  informacionNegocio = true;
                } else {
                  if (economicos === false) {
                    setSeccion(SECCION_ECONOMICOS);
                    economicos = true;
                  } else {
                    setSeccion(SECCION_SOCIOS);
                    socios = true;
                  }
                }
              }
            }
          }
        }
      }

      setMostrarSeccionPm({
        ...mostrarSeccionPm,
        general: general,
        representanteLegal: representanteLegal,
        domicilioNegocio: domicilioNegocio,
        domicilioComercial: domicilioComercial,
        informacionNegocio: informacionNegocio,
        economicos: economicos,
        socios: socios,
        principalAccionista: principalAccionista,
        principalAccionistaConyuge: principalAccionistaConyuge,
        obligadosSolidario: obligadosSolidario,
        historialCrediticio: historialCrediticio,
      });
    }

    setUltimoPasoUsuario(ultimoPaso);
  };

  const obtenerDetalleSolicitud = async (idSolicitud) => {
    const data = {
      idSolicitud: idSolicitud,
    };

    try {
      if (idSolicitud !== null) {
        const response = await api_obtener_detalle_solicitud(data);
        if (response.statusText === "OK" || response.status === STATUS_EXITO) {
          const data = response.data;
          if (data.success) {
            const datosSolicitud = data.datosSolicitudCreditoPyME;
            setPlazo(datosSolicitud.datosOperacion.plazo);
            setCredito(datosSolicitud.datosOperacion.montoCredito);
            setCreditoFloat(datosSolicitud.datosOperacion.montoCreditoFloat);
            setProductoPyme(datosSolicitud.datosOperacion.tipoDeCreditoNombre);
            setIdTipoPersona(data.idTipoPersona);
            setUltimoPasoSolicitud(data.solicitudUltimoPaso);
            calculoMensualidad(
              datosSolicitud?.datosOperacion?.montoCredito,
              datosSolicitud?.datosOperacion?.plazo
            );
            if (data.solicitudFolio) setFolioSolicitud(data.solicitudFolio);
            if (datosSolicitud?.datosEconomicos?.bancos !== null) {
              setCantidadBancos(datosSolicitud.datosEconomicos.bancos.length);
            }
  
            if (data.idTipoPersona === ID_PFAE) {
              setTipoPersona("PFAE");
              setearDatosSolicitudPFAE(datosSolicitud, data.solicitudUltimoPaso);
              setEmailCliente(datosSolicitud.generales.correoElectronico);
              if(datosSolicitud.generales.correoElectronico != null && datosSolicitud.generales.correoElectronico.length > 0){
                setEmailValido(true);
              }
            } else {
              setTipoPersona("PM");
              if (
                datosSolicitud?.sociosPrincipales != null &&
                datosSolicitud?.sociosPrincipales?.length > 0
              ) {
                setCantidadSocios(datosSolicitud.sociosPrincipales.length);
              }
  
              setearDatosSolicitudPM(datosSolicitud, data.solicitudUltimoPaso);
              if(datosSolicitud?.principalAccionista && 
                (datosSolicitud?.principalAccionista.generales.correoElectronico != null && datosSolicitud?.principalAccionista?.generales.correoElectronico.length > 0)){
                setEmailValido(true);
                setEmailCliente(datosSolicitud?.principalAccionista.generales.correoElectronico);
              }
            }
            
          } else {
            sweetAlertError("Ocurrió un error al obtener datos de la solicitud");
          }
        } else {
          sweetAlertError("Ocurrió un error al obtener datos de la solicitud");
        }
        setLoading(false);
      } else {
        sweetAlertWithConfirmed(
          "No puede acceder a esta página, si no ha seleccionado un plazo y monto de crédito",
          webView
        );
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  // SOCIOS PRINCIPALES
  const [cantidadSocios, setCantidadSocios] = useState(1);

  // REFS
  const refScrollUp = useRef();

  const refScrollUpBancos = useRef();

  const animatedHeightBoxUnoRef = useRef();
  const animatedHeightBoxDosRef = useRef();
  const animatedHeightBoxTresRef = useRef();

  // SECCION ROJA
  const [credito, setCredito] = useState("0");
  const [creditoFloat, setCreditoFloat] = useState("0");
  const [, setProductoPyme] = useState();

  // SECCION HANDLERS
  const [seccion, setSeccion] = useState(0);

  const [importeRentaFiscal, setImporteRentaFiscal] = useState("$");
  const [totalNominaMensual, setTotalNominaMensual] = useState("$");
  const [, setVentasAnuales] = useState("$");

  // DATOS ECONOMICOS
  const [cantidadBancos, setCantidadBancos] = useState(1);
  const [, setSelectedBanco] = useState(1);

  // TRANSITION TOGGLE
  const [transitionZeroToggle, setTransitionZeroToggle] = useState(true);
  const [transitionOneToggle, setTransitionOneToggle] = useState(true);
  const [transitionTwoToggle, setTransitionTwoToggle] = useState(false);
  const [transitionThreeToggle, setTransitionThreeToggle] = useState(false);
  const [transitionFourToggle, setTransitionFourToggle] = useState(false);
  const [transitionFiveToggle, setTransitionFiveToggle] = useState(false);
  const [transitionSixToggle, setTransitionSixToggle] = useState(false);
  const [transitionSevenToggle, setTransitionSevenToggle] = useState(false);
  const [transitionEightToggle, setTransitionEightToggle] = useState(false);
  const [transitionNineToggle, setTransitionNineToggle] = useState(false);
  const [transitionTenToggle, setTransitionTenToggle] = useState(false);
  const [transitionElevenToggle, setTransitionElevenToggle] = useState(false);
  const [transitionTwelveToggle, setTransitionTwelveToggle] = useState(false);
  const [transitionThirteenToggle, setTransitionThirteenToggle] =
    useState(false);
  const [transitionFourtheenToggle, setTransitionFourtheenToggle] =
    useState(false);
  const [transitionFivetheenToggle, setTransitionFivetheenToggle] =
    useState(false);
  const [transitionDiesiseisToggle, setTransitionDiesiseisToggle] =
    useState(false);
  const [transitionDiesisieteToggle, setTransitionDiesisieteToggle] =
    useState(false);
  const [transitionDiesiochoToggle, setTransitionDiesiochoToggle] =
    useState(false);
  const [transitionDiecinueveToggle, setTransitionDiecinueveToggle] =
    useState(false);
  const [transitionVeinteToggle, setTransitionVeinteToggle] = useState(false);
  const [transitionVeintidosToggle, setTransitionVeintidosToggle] =
    useState(false);
  const [transitionVeintitresToggle] = useState(false);

  const [movingBack, setMovingBack] = useState(false);

  // BANCOS TRANSITION TOGGLE
  const [transitionBancoOneToggle, setTransitionBancoOneToggle] =
    useState(true);
  const [transitionBancoTwoToggle, setTransitionBancoTwoToggle] =
    useState(false);
  const [transitionBancoThreeToggle, setTransitionBancoThreeToggle] =
    useState(false);

  const handleAddBancos = (operation) => {
    if (operation) {
      if (cantidadBancos <= 2 && cantidadBancos >= 1) {
        setSelectedBanco(1);
        setCantidadBancos(cantidadBancos + 1);
      }
    } else {
      if (cantidadBancos >= 2 && cantidadBancos <= 3) {
        setSelectedBanco(1);
        setCantidadBancos(cantidadBancos - 1);
      }
    }
  };

  const handlePesos = (e, handleChange) => {
    let { value } = e.target;

    if (value) {
      value = (Number(value.replace(/\D/g, "")) || "")
        .toLocaleString()
        .replaceAll(".", ",");
      handleChange(value);
    } else {
      handleChange("");
    }

    value = "$" + value;
    handleChange(value);
  };

  const handlePesosCotizador = (e, handleChange) => {
    let { value } = e.target;
    if (value) {
      value = value.replaceAll("$", "");
      if (value !== "") {
        value = value.replaceAll(",", "");
        if (Number.isNaN(value) === false) {
          value = parseFloat(value);
          calculoMensualidad(value, plazo);
          handleChange(value);
        }

        if (Number.isNaN(value) === false) {
          handleChange(value);
        }
      } else {
        handleChange("");
      }
    } else {
      handleChange("");
    }
  };

  const handleMensualidadCotizador = (e, handleChange) => {
    let { value } = e.target;
    if (value) {
      handleChange(value);
    } else {
      handleChange("");
    }
    handleChange(value);
  };

  const validarLimiteHandler = (e) => {
    let { value, min, max } = e.target;
    if (value) {
      value = value.replaceAll("$", "");
      if (value !== "") {
        value = value.replaceAll(",", "");
        if (Number.isNaN(value) === false) {
          value = parseFloat(value);
          let monto = value;
          value = Math.max(Number(min), Math.min(Number(max), Number(value)));
          value = value.toLocaleString("es-MX");
          let plazoCalculo = plazo;

          if(idTipoPersona === ID_PFAE && parseFloat(monto) < MONTO_VALIDO_EXPRESS_SIMPLE_PFAE){
            if(plazo === PLAZO_NO_VALIDO_EXPRESS_SIMPLE){
              setPlazo(PLAZO_MAX_VALIDO_EXPRESS_SIMPLE);
              plazoCalculo = PLAZO_MAX_VALIDO_EXPRESS_SIMPLE;
            }     
          } else{
            if(idTipoPersona === ID_PM && parseFloat(monto) < MONTO_VALIDO_EXPRESS_SIMPLE_PM){
              if(plazo === PLAZO_NO_VALIDO_EXPRESS_SIMPLE){
                setPlazo(PLAZO_MAX_VALIDO_EXPRESS_SIMPLE);
                plazoCalculo = PLAZO_MAX_VALIDO_EXPRESS_SIMPLE;
              }
            }
          }

          calculoMensualidad(value, plazoCalculo);
        }
        if (Number.isNaN(value) === false) {
          setCredito(value);
        }
      } else {
        value = parseFloat(min);
        value = value.toLocaleString("es-MX");
        calculoMensualidad(value, plazo);
        setCredito(value);
      }
    }
  };

  const validarLimiteMesesHandler = (e) => {
    let value;
    if (e.target !== undefined) {
      value = e.target;
    } else {
      value = e.value;
    }

    calculoMensualidad(credito, value);
  };

  const calculoMensualidad = (montoCredito, plazo) => {
    if (montoCredito !== "") {
      let tasaConIva;
      let tasaAnual;
      let pagoCalculo;
      montoCredito = montoCredito.toString().replaceAll(",", "");
      if (Number.isNaN(montoCredito) === false) {
        montoCredito = parseFloat(montoCredito);
        tasaConIva = sessionStorage.getItem(sessionTasaAnualAts) * 1.16;
        tasaAnual = tasaConIva / 12;
        pagoCalculo =
          parseFloat(montoCredito) /
          ((1 - (1 + tasaAnual) ** -plazo) / tasaAnual);
        pagoCalculo = parseFloat(pagoCalculo.toFixed(2));
        pagoCalculo = pagoCalculo.toLocaleString("es-MX");
        setPago(pagoCalculo);
      }
    } else {
      setPago(0.0);
    }
  };

  const actualizarDatosResumen = async () => {
    const datosResumenCredito = {
      idSolicitud:
        tipoPersona === KEY_PFAE
          ? modeloPfae?.idSolicitud
          : modeloPm?.idSolicitud,
      plazo: plazo,
      montoCredito: credito,
      idDatosOperacion: sessionStorage.getItem(sessionStorageDatosOperacion),
    };
    setLoading(true);
   try {
    const response = await api_actualizar_datos_resumen(datosResumenCredito);
    if (response?.data?.success) {
      setPlazo(response?.data?.datosOperacion?.plazo);
      setCredito(response?.data?.montoCredito);
      setCreditoFloat(response?.data?.montoCreditoFloat);
      setShowBotonActualizar(false);
      setLoading(false);
      sweetAlertSuccess("Operación exitosa.");
    } else {
      setLoading(false);
      sweetAlertWarning(response?.data?.mensaje);
    }
   } catch (error) {
    console.log(error);
   }
  };

  const handleSeccionPyme = (valor) => {
    let maxSecciones = 26;
    setMovingBack(false);

    if (valor <= maxSecciones - 1 && valor >= 0) {
      if (idTipoPersona === ID_PFAE) {
        mostrarPestaniasPymePfae(valor);
      } else {
        mostrarPestaniasPymePm(valor);
      }
    }
    const element = document.getElementById("root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const mostrarPestaniasPymePfae = (seccionActual) => {
    mostrarPestaniasPymePfaeHelper(
      seccionActual,
      setAvanzarSolicitud,
      modeloPfae,
      setSeccion,
      setSubMenuSelected,
      setFormSection,
      setMostrarSeccionPfae,
      mostrarSeccionPfae,
      setUltimoPasoUsuario,
      ultimoPasoUsuario,
      setDisabledBtnAgregarOs,
      datosOSUno,
      cantidadObligadosSolidarios,
      setCantidadObligadosSolidarios,
      cantidadConyugeOS,
      setLoading,
      ultimoPasoSolicitud,
      datosOSDos,
      datosOSTres,
      datosOSCuatro,
      datosOSCinco,
      setUltimoPasoSolicitud
    );
  };

  const mostrarPestaniasPymePm = async (seccionActual) => {
    let idSolicitud = modeloPm.idSolicitud;
    switch (seccionActual) {
      case SECCION_GENERALES:
        if (ultimoPasoUsuario == null) {
          setUltimoPasoUsuario(SECCION_GENERALES);
        }

        setSeccion(SECCION_REPRESENTANTE_LEGAL);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          representanteLegal: true,
        });
        break;
      case SECCION_REPRESENTANTE_LEGAL:
        setSeccion(SECCION_DOMICILIO_NEGOCIO);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          domicilioNegocio: true,
        });
        break;
      case SECCION_DOMICILIO_NEGOCIO:
        setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          domicilioComercial: true,
        });
        break;
      case SECCION_DOMICILIO_COMERCIAL_PM:
        setSeccion(SECCION_INFORMACION_NEGOCIO);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          informacionNegocio: true,
        });
        break;
      case SECCION_INFORMACION_NEGOCIO:
        setSeccion(SECCION_ECONOMICOS);
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          economicos: true,
        });
        break;
      case SECCION_ECONOMICOS:
        setSeccion(SECCION_SOCIOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          socios: true,
        });
        break;
      case SECCION_SOCIOS:
        setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        setMostrarSeccionPm({
          ...mostrarSeccionPm,
          principalAccionista: true,
        });
        if (modeloPm.principalAccionista.idPrincipalAccionista == null) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(true);
          }
        } else {
          setDisabledBtnAgregarOs(false);
        }
        break;
      case SECCION_PRINCIPAL_ACCIONISTA:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          ultimoPasoUsuario === SECCION_GENERALES
        ) {
          setUltimoPasoUsuario(SECCION_PRINCIPAL_ACCIONISTA);
        }
        if (
          parseInt(modeloPm.principalAccionista.generales.estadoCivilId) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL 
        ) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM || (modeloPm.principalAccionista.conyuge !== undefined && modeloPm.principalAccionista.conyuge && modeloPm.principalAccionista.conyuge.idConyuge !== null) ) {
            setSeccion(SECCION_PA_CONYUGE_PM);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            setMostrarSeccionPm({
              ...mostrarSeccionPm,
              principalAccionistaConyuge: true,
            });
            if (
              modeloPm.principalAccionista.conyuge !== undefined &&
              modeloPm.principalAccionista.conyuge &&
              modeloPm.principalAccionista.conyuge.idConyuge !== null
            ) {
              setDisabledBtnAgregarOs(false);
            } else {
              if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
                setDisabledBtnAgregarOs(true);
              }
            }
          } else{
            mostrarSeccionPm.principalAccionistaConyuge = false;

            setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            setMostrarSeccionPm({
              ...mostrarSeccionPm,
              obligadosSolidario: true,
            });
          }
        } else {
          mostrarSeccionPm.principalAccionistaConyuge = false;

          if (datosOSUno.datosOS.length === 0) {
            const response = await sweetAlertObligadoSolidario();
            if(response){
              setSeccion(SECCION_OBLIGADO_SOLIDARIO);
              setSubMenuSelected(SUB_MENU_CADENA_OS);
              setFormSection("Obligados Solidarios");
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setMostrarSeccionPm({
                ...mostrarSeccionPm,
                obligadosSolidario: true,
              });
              setDisabledBtnAgregarOs(true);
            }else{
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            setMostrarSeccionPm({
              ...mostrarSeccionPm,
              obligadosSolidario: true,
            });
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        break;
      case SECCION_PA_CONYUGE_PM:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          ultimoPasoUsuario === SECCION_PRINCIPAL_ACCIONISTA
        ) {
          setUltimoPasoUsuario(SECCION_PA_CONYUGE_PM);
        }
        if (datosOSUno?.datosOS?.length === 0) {

          const response = await sweetAlertObligadoSolidario()

          if(response){
            setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
            setMostrarSeccionPm({
              ...mostrarSeccionPm,
              obligadosSolidario: true,
            });
            setDisabledBtnAgregarOs(true);
          }else{
            setLoading(true);
            setDisabledBtnAgregarOs(false);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          }
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
          setFormSection("Obligados Solidarios");
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setMostrarSeccionPm({
            ...mostrarSeccionPm,
            obligadosSolidario: true,
          });
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_OBLIGADO_SOLIDARIO:
        if (
          ultimoPasoUsuario !== SECCION_HISTORIAL_CREDITICIO &&
          (ultimoPasoUsuario === SECCION_PRINCIPAL_ACCIONISTA ||
            ultimoPasoUsuario === SECCION_PA_CONYUGE_PM)
        ) {
          setUltimoPasoUsuario(SECCION_OBLIGADO_SOLIDARIO);
        }
        if (
          parseInt(datosOSUno.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setSeccion(SECCION_CONYUGE_OS_UNO);
            if (datosOSUno?.conyuge?.length === 0) {
              setDisabledBtnAgregarOs(true);
            } else {
              if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
                setDisabledBtnAgregarOs(false);
              }
            }
          } else{
            if (datosOSUno?.conyuge?.length === 0) {
              setSeccion(SECCION_OS_DOS);
            } else{
              setSeccion(SECCION_CONYUGE_OS_UNO);
            }
          }
        } else {
          if (datosOSDos?.datosOS?.length === 0) {

            const response = await sweetAlertObligadoSolidario();

            if(response){
              setSeccion(SECCION_OS_DOS);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            }else{
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OS_DOS);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        break;
      case SECCION_OS_DOS:
        if (
          parseInt(datosOSDos.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setSeccion(SECCION_CONYUGE_OS_DOS);
            setSubMenuSelected(SUB_MENU_CADENA_OS);

            if (datosOSDos?.conyuge?.length === 0) {
              setDisabledBtnAgregarOs(true);
            } else {
              if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
                setDisabledBtnAgregarOs(false);
              }
            }
          }else{
            if (datosOSTres?.datosOS?.length === 0) {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            } else{
              setSeccion(SECCION_OS_TRES);
            }
          }    
        } else {
          if (datosOSTres?.datosOS?.length === 0) {
            const response = await sweetAlertObligadoSolidario()

            if(response){
              setSeccion(SECCION_OS_TRES);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
              setSubMenuSelected(SUB_MENU_CADENA_OS);
            }else{
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          } else {
            setSeccion(SECCION_OS_TRES);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
       
        break;
      case SECCION_OS_TRES:
        if (
          parseInt(datosOSTres.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setSeccion(SECCION_CONYUGE_OS_TRES);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            
            if (datosOSTres?.conyuge?.length === 0) {
              setDisabledBtnAgregarOs(true);
            } else {
              if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
                setDisabledBtnAgregarOs(false);
              }
            }
          } else{
            if (datosOSCuatro.datosOS.length === 0) {
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            } else{
              setSeccion(SECCION_OS_CUATRO);
            }
            
          }
        } else {
          if (datosOSCuatro?.datosOS?.length === 0) {
            if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
              setLoading(true);
              if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            } else {

              const response = await sweetAlertObligadoSolidario();

              if(response){
                setSeccion(SECCION_OS_CUATRO);
                setCantidadObligadosSolidarios(
                  cantidadObligadosSolidarios + 1
                );
                setDisabledBtnAgregarOs(true);
              }else{
                setLoading(true);
                setDisabledBtnAgregarOs(false);
                if (
                  ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
                ) {
                  cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
                } else {
                  setSeccion(SECCION_HISTORIAL_CREDITICIO);
                  setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                  setFormSection("Historial Crediticio");
                  setMostrarSeccionPfae({
                    ...mostrarSeccionPfae,
                    historialCrediticio: true,
                  });
                  setLoading(false);
                }
              }

            }
          } else {
            setSeccion(SECCION_OS_CUATRO);
            if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
              setDisabledBtnAgregarOs(false);
            }
          }
        }
        break;
      case SECCION_OS_CUATRO:
        setLoading(true);
        if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
          cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
        } else {
          setSeccion(SECCION_HISTORIAL_CREDITICIO);
          setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
          setFormSection("Historial Crediticio");
          setMostrarSeccionPfae({
            ...mostrarSeccionPfae,
            historialCrediticio: true,
          });
          setLoading(false);
        }
        break;
      case SECCION_CONYUGE_OS_UNO:
        if (datosOSDos?.datosOS?.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {

            const response = await sweetAlertObligadoSolidario()
            if(response){
              setSeccion(SECCION_OS_DOS);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            }else{
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_DOS);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_CONYUGE_OS_DOS:
        if (datosOSTres?.datosOS?.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {
            const response = await sweetAlertObligadoSolidario()

            if(response){
              setSeccion(SECCION_OS_TRES);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            }else{
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }
          }
        } else {
          setSeccion(SECCION_OS_TRES);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      case SECCION_CONYUGE_OS_TRES:
        if (datosOSCuatro?.datosOS?.length === 0) {
          if (cantidadObligadosSolidarios + cantidadConyugeOS >= OS_MAX_PM) {
            setLoading(true);
            if (ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO) {
              cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
            } else {
              setSeccion(SECCION_HISTORIAL_CREDITICIO);
              setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
              setFormSection("Historial Crediticio");
              setMostrarSeccionPfae({
                ...mostrarSeccionPfae,
                historialCrediticio: true,
              });
              setLoading(false);
            }
          } else {

            const response = await sweetAlertObligadoSolidario();
            if(response){
              setSeccion(SECCION_OS_CUATRO);
              setCantidadObligadosSolidarios(cantidadObligadosSolidarios + 1);
              setDisabledBtnAgregarOs(true);
            }else{
              setLoading(true);
              setDisabledBtnAgregarOs(false);
              if (
                ultimoPasoSolicitud !== PASO_SOLICITUD_HISTORIAL_CREDITICIO
              ) {
                cambiarEstatusHistorialCrediticio(idSolicitud).then((r) => r);
              } else {
                setSeccion(SECCION_HISTORIAL_CREDITICIO);
                setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
                setFormSection("Historial Crediticio");
                setMostrarSeccionPfae({
                  ...mostrarSeccionPfae,
                  historialCrediticio: true,
                });
                setLoading(false);
              }
            }

          }
        } else {
          setSeccion(SECCION_OS_CUATRO);
          if (cantidadObligadosSolidarios + cantidadConyugeOS < OS_MAX_PM) {
            setDisabledBtnAgregarOs(false);
          }
        }
        break;
      default:
        setSeccion(ultimoPasoUsuario ? ultimoPasoUsuario : SECCION_GENERALES);
        break;
    }
  };

  const handleRegresarSeccionPyme = (valor) => {
    if (valor > 1) {
      if (idTipoPersona === ID_PFAE) {
        mostrarPestaniasAtrasPymePfae(valor);
      } else {
        mostrarPestaniasAtrasPymePm(valor);
      }
    }
    const element = document.getElementById("root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const mostrarPestaniasAtrasPymePfae = (seccionActual) => {
    switch (seccionActual) {
      case SECCION_DOMICILIO:
        setSeccion(SECCION_GENERALES);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_DOMICILIO_FISCAL:
        setSeccion(SECCION_DOMICILIO);
        setFormSection("Generales");
        break;
      case SECCION_DOMICILIO_COMERCIAL:
        setSeccion(SECCION_DOMICILIO_FISCAL);
        setFormSection("Generales");
        break;
      case SECCION_ECONOMICOS:
        setSeccion(SECCION_DOMICILIO_COMERCIAL);
        setFormSection("Generales");
        break;
      case SECCION_SOLICITANTE_CONYUGE:
        setSeccion(SECCION_ECONOMICOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_OBLIGADO_SOLIDARIO:
        if (
          parseInt(modeloPfae.generales.estadoCivilId) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(PESTANIA_SOLICITANTE_CONYUGE);
          if (modeloPfae?.conyuge?.generales?.esObligadoSolidario) {
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
          } else {
            setSubMenuSelected(SUB_MENU_CADENA_GRALES);
            setFormSection("Generales");
          }
        } else {
          setSeccion(SECCION_ECONOMICOS);
          setSubMenuSelected(SUB_MENU_CADENA_GRALES);
          setFormSection("Generales");
        }
        break;
      case SECCION_OS_DOS:
        if (
          parseInt(datosOSUno.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_UNO);
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_TRES:
        if (
          parseInt(datosOSDos.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_DOS);
        } else {
          setSeccion(SECCION_OS_DOS);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_CUATRO:
        if (
          parseInt(datosOSTres.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_TRES);
        } else {
          setSeccion(SECCION_OS_TRES);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_CINCO:
        if (
          parseInt(datosOSCuatro.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_CUATRO);
        } else {
          setSeccion(SECCION_OS_CUATRO);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_UNO:
        setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_DOS:
        setSeccion(SECCION_OS_DOS);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_TRES:
        setSeccion(SECCION_OS_TRES);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_CUATRO:
        setSeccion(SECCION_OS_CUATRO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_HISTORIAL_CREDITICIO:
        switch (cantidadObligadosSolidarios) {
          case OS_CERO:
            setSeccion(PESTANIA_SOLICITANTE_CONYUGE);
            if (modeloPfae?.conyuge?.generales?.esObligadoSolidario) {
              setSubMenuSelected(SUB_MENU_CADENA_OS);
              setFormSection("Obligados Solidarios");
            } else {
              setSubMenuSelected(SUB_MENU_CADENA_GRALES);
              setFormSection("Generales");
            }
            break;
          case OS_UNO:
            if (
              parseInt(datosOSUno.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_UNO);
            } else {
              setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_DOS:
            if (
              parseInt(datosOSDos.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_DOS);
            } else {
              setSeccion(SECCION_OS_DOS);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_TRES:
            if (
              parseInt(datosOSTres.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_TRES);
            } else {
              setSeccion(SECCION_OS_TRES);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_CUATRO:
            if (
              parseInt(datosOSCuatro.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_CUATRO);
            } else {
              setSeccion(SECCION_OS_CUATRO);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_CINCO:
            setSeccion(SECCION_OS_CINCO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;

            default:
              break
        }
        break;
    }
  };

  const mostrarPestaniasAtrasPymePm = (seccionActual) => {
    switch (seccionActual) {
      case SECCION_REPRESENTANTE_LEGAL:
        setSeccion(SECCION_GENERALES);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        break;
      case SECCION_DOMICILIO_NEGOCIO:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_REPRESENTANTE_LEGAL);
        break;
      case SECCION_DOMICILIO_COMERCIAL_PM:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_DOMICILIO_NEGOCIO);
        break;
      case SECCION_INFORMACION_NEGOCIO:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_DOMICILIO_COMERCIAL_PM);
        break;
      case SECCION_ECONOMICOS:
        setSeccion(SECCION_GENERALES);
        setSeccion(SECCION_INFORMACION_NEGOCIO);
        break;
      case SECCION_SOCIOS:
        setSeccion(SECCION_ECONOMICOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_PRINCIPAL_ACCIONISTA:
        setSeccion(SECCION_SOCIOS);
        setSubMenuSelected(SUB_MENU_CADENA_GRALES);
        setFormSection("Generales");
        break;
      case SECCION_PA_CONYUGE_PM:
        setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OBLIGADO_SOLIDARIO:
        if (
          parseInt(modeloPm.principalAccionista.generales.estadoCivilId) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_PA_CONYUGE_PM);
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setFormSection("Obligados Solidarios");
        } else {
          setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
          setSubMenuSelected(SUB_MENU_CADENA_OS);
          setFormSection("Obligados Solidarios");
        }
        break;
      case SECCION_OS_DOS:
        if (
          parseInt(datosOSUno.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_UNO);
        } else {
          setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_TRES:
        if (
          parseInt(datosOSDos.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_DOS);
        } else {
          setSeccion(SECCION_OS_DOS);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_OS_CUATRO:
        if (
          parseInt(datosOSTres.idEstadoCivilOs) ===
          ESTADO_CIVIL_SOCIEDAD_CONYUGAL
        ) {
          setSeccion(SECCION_CONYUGE_OS_TRES);
        } else {
          setSeccion(SECCION_OS_TRES);
        }
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_UNO:
        setSeccion(SECCION_OBLIGADO_SOLIDARIO);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_DOS:
        setSeccion(SECCION_OS_DOS);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_CONYUGE_OS_TRES:
        setSeccion(SECCION_OS_TRES);
        setSubMenuSelected(SUB_MENU_CADENA_OS);
        setFormSection("Obligados Solidarios");
        break;
      case SECCION_HISTORIAL_CREDITICIO:
        switch (cantidadObligadosSolidarios) {
          case OS_CERO:
            if (
              parseInt(modeloPm.principalAccionista.generales.estadoCivilId) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_PA_CONYUGE_PM);
              setSubMenuSelected(SUB_MENU_CADENA_OS);
              setFormSection("Obligados Solidarios");
            } else {
              setSeccion(SECCION_PRINCIPAL_ACCIONISTA);
              setSubMenuSelected(SUB_MENU_CADENA_OS);
              setFormSection("Obligados Solidarios");
            }
            break;
          case OS_UNO:
            if (
              parseInt(datosOSUno.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_UNO);
            } else {
              setSeccion(SECCION_OBLIGADO_SOLIDARIO);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_DOS:
            if (
              parseInt(datosOSDos.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_DOS);
            } else {
              setSeccion(SECCION_OS_DOS);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_TRES:
            if (
              parseInt(datosOSTres.idEstadoCivilOs) ===
              ESTADO_CIVIL_SOCIEDAD_CONYUGAL
            ) {
              setSeccion(SECCION_CONYUGE_OS_TRES);
            } else {
              setSeccion(SECCION_OS_TRES);
            }
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
          case OS_CUATRO:
            setSeccion(SECCION_OS_CUATRO);
            setSubMenuSelected(SUB_MENU_CADENA_OS);
            setFormSection("Obligados Solidarios");
            break;
        }
        break;
    }
  };

  const cambiarEstatusHistorialCrediticio = async (idSolicitud) => {
    try {
      const response = await cambiarEstatusHistorialCrediticioHelper(idSolicitud);
    if (response?.success) {
      setAvanzarSolicitud(true);
      setUltimoPasoSolicitud(PASO_SOLICITUD_HISTORIAL_CREDITICIO);
      setUltimoPasoUsuario(SECCION_HISTORIAL_CREDITICIO);
      setSeccion(SECCION_HISTORIAL_CREDITICIO);
      setSubMenuSelected(SUB_MENU_CADENA_HISTORIAL);
      setFormSection("Historial Crediticio");
      setMostrarSeccionPfae({
        ...mostrarSeccionPfae,
        historialCrediticio: true,
      });
      setLoading(false);
    } else {
      setLoading(false);
      sweetAlertWarning("Ocurrió un error al avanzar a historial crediticio");
    }
    } catch (error) {
     console.log(error);
     setLoading(false) 
    }
  };

  useEffect(() => {
    if (esObligadoSolidario) {
      setStatePAConyuge(true);
    } else {
      setStatePAConyuge(false);
    }
  }, [esObligadoSolidario]);

  useEffect(() => {
    if (seccion === SECCION_ZERO) setTransitionZeroToggle(true);
    else setTransitionZeroToggle(false);
    if (seccion === SECCION_GENERALES) setTransitionOneToggle(true);
    else setTransitionOneToggle(false);
    if (
      seccion === SECCION_DOMICILIO ||
      seccion === SECCION_REPRESENTANTE_LEGAL
    )
      setTransitionTwoToggle(true);
    else setTransitionTwoToggle(false);
    if (
      seccion === SECCION_DOMICILIO_FISCAL ||
      seccion === SECCION_DOMICILIO_NEGOCIO
    )
      setTransitionThreeToggle(true);
    else setTransitionThreeToggle(false);
    if (
      seccion === SECCION_DOMICILIO_COMERCIAL ||
      seccion === SECCION_INFORMACION_NEGOCIO
    )
      setTransitionFourToggle(true);
    else setTransitionFourToggle(false);
    if (seccion === SECCION_ECONOMICOS) setTransitionFiveToggle(true);
    else setTransitionFiveToggle(false);
    if (seccion === SECCION_SOCIOS) setTransitionSixToggle(true);
    else setTransitionSixToggle(false);
    if (seccion === SECCION_PRINCIPAL_ACCIONISTA)
      setTransitionSevenToggle(true);
    else setTransitionSevenToggle(false);
    if (seccion === SECCION_OBLIGADO_SOLIDARIO) setTransitionEightToggle(true);
    else setTransitionEightToggle(false);
    if (seccion === SECCION_CONYUGE_OS_UNO) setTransitionNineToggle(true);
    else setTransitionNineToggle(false);
    if (seccion === SECCION_OS_DOS) setTransitionTenToggle(true);
    else setTransitionTenToggle(false);
    if (seccion === SECCION_CONYUGE_OS_DOS) setTransitionElevenToggle(true);
    else setTransitionElevenToggle(false);
    if (seccion === SECCION_OS_TRES) setTransitionTwelveToggle(true);
    else setTransitionTwelveToggle(false);
    if (seccion === SECCION_CONYUGE_OS_TRES) setTransitionThirteenToggle(true);
    else setTransitionThirteenToggle(false);
    if (seccion === SECCION_OS_CUATRO) setTransitionFourtheenToggle(true);
    else setTransitionFourtheenToggle(false);
    if (seccion === SECCION_CONYUGE_OS_CUATRO)
      setTransitionFivetheenToggle(true);
    else setTransitionFivetheenToggle(false);
    if (seccion === SECCION_OS_CINCO) setTransitionVeintidosToggle(true);
    else setTransitionVeintidosToggle(false);
    if (seccion === SECCION_PATRIMONIO) setTransitionDiesiseisToggle(true);
    else setTransitionDiesiseisToggle(false);
    if (seccion === SECCION_HISTORIAL_CREDITICIO)
      setTransitionDiesisieteToggle(true);
    else setTransitionDiesisieteToggle(false);
    if (seccion === SECCION_CARGA_DOCUMENTOS)
      setTransitionDiesiochoToggle(true);
    else setTransitionDiesiochoToggle(false);
    if (seccion === SECCION_DOMICILIO_COMERCIAL_PM)
      setTransitionDiecinueveToggle(true);
    else setTransitionDiecinueveToggle(false);
    if (
      seccion === SECCION_SOLICITANTE_CONYUGE ||
      seccion === SECCION_PA_CONYUGE_PM
    )
      setTransitionVeinteToggle(true);
    else setTransitionVeinteToggle(false);
  }, [seccion]);

  useEffect(() => {
    if (regresarCotizador) {
      if (webView) {
        window.location.replace("/cotizadorBanorte");
        navigate("/cotizadorBanorte")
      } else {
        navigate("/cotizador")
      }
    }
  }, [regresarCotizador]);

  const transitionConfig =
    movingBack === false
      ? {
          from: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(100%)",
            pointerEvents: "all",
          },
          enter: {
            opacity: 1,
            position: "relative",
            transform: "translateX(0%)",
            top: "0px",
            pointerEvents: "all",
          },
          leave: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(-100%)",
            top: "0px",
            pointerEvents: "none",
          },
        }
      : {
          from: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(-100%)",
            pointerEvents: "all",
          },
          enter: {
            opacity: 1,
            position: "relative",
            transform: "translateX(0%)",
            top: "0px",
            pointerEvents: "all",
          },
          leave: {
            opacity: 0,
            position: "absolute",
            transform: "translateX(100%)",
            top: "0px",
            pointerEvents: "none",
          },
        };

  const transitionZero = useTransition(transitionZeroToggle, transitionConfig);
  const transitionOne = useTransition(transitionOneToggle, transitionConfig);
  const transitionTwo = useTransition(transitionTwoToggle, transitionConfig);
  const transitionThree = useTransition(
    transitionThreeToggle,
    transitionConfig
  );
  const transitionFour = useTransition(transitionFourToggle, transitionConfig);
  const transitionFive = useTransition(transitionFiveToggle, transitionConfig);
  const transitionSix = useTransition(transitionSixToggle, transitionConfig);
  const transitionSeven = useTransition(
    transitionSevenToggle,
    transitionConfig
  );
  const transitionEight = useTransition(
    transitionEightToggle,
    transitionConfig
  );
  const transitionNine = useTransition(transitionNineToggle, transitionConfig);
  const transitionTen = useTransition(transitionTenToggle, transitionConfig);
  const transitionEleven = useTransition(
    transitionElevenToggle,
    transitionConfig
  );
  const transitionTwelve = useTransition(
    transitionTwelveToggle,
    transitionConfig
  );
  const transitionThirteen = useTransition(
    transitionThirteenToggle,
    transitionConfig
  );
  const transitionFourtheen = useTransition(
    transitionFourtheenToggle,
    transitionConfig
  );
  const transitionFivetheen = useTransition(
    transitionFivetheenToggle,
    transitionConfig
  );
  const transitionDiesiseis = useTransition(
    transitionDiesiseisToggle,
    transitionConfig
  );
  const transitionDiesisiete = useTransition(
    transitionDiesisieteToggle,
    transitionConfig
  );
  const transitionDiesiocho = useTransition(
    transitionDiesiochoToggle,
    transitionConfig
  );
  const transitionDiecinueve = useTransition(
    transitionDiecinueveToggle,
    transitionConfig
  );
  const transitionVeinte = useTransition(
    transitionVeinteToggle,
    transitionConfig
  );
  const transitionVeintidos = useTransition(
    transitionVeintidosToggle,
    transitionConfig
  );
  const transitionVeintitres = useTransition(
    transitionVeintitresToggle,
    transitionConfig
  );


  const transitionBancoOne = useTransition(
    transitionBancoOneToggle,
    bancosTransitionConfig
  );

  const transitionBancoTwo = useTransition(
    transitionBancoTwoToggle,
    bancosTransitionConfig
  );

  const transitionBancoThree = useTransition(
    transitionBancoThreeToggle,
    bancosTransitionConfig
  );

  useEffect(() => {
    if (cantidadBancos >= 1) setTransitionBancoOneToggle(true);
    else setTransitionBancoOneToggle(false);
    if (cantidadBancos >= 2) setTransitionBancoTwoToggle(true);
    else setTransitionBancoTwoToggle(false);
    if (cantidadBancos >= 3) setTransitionBancoThreeToggle(true);
    else setTransitionBancoThreeToggle(false);
  }, [cantidadBancos]);

  const animateHeight = (e, currentPosition) => {
    if (animatedHeightBoxUnoRef.current !== undefined) {
      if (currentPosition !== 1) {
        animatedHeightBoxUnoRef.current.style.height = "0px";
        animatedHeightBoxUnoRef.current.parentElement.children[0].children[0].className =
          "ri-add-line";
      }
      refScrollUpBancos.current.scrollIntoView({ behavior: "smooth" });
    }
    if (animatedHeightBoxDosRef.current !== undefined) {
      if (cantidadBancos >= 2) {
        if (currentPosition !== 2) {
          animatedHeightBoxDosRef.current.style.height = "0px";
          animatedHeightBoxDosRef.current.parentElement.children[0].children[0].className =
            "ri-add-line";
        }
        refScrollUpBancos.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (animatedHeightBoxTresRef.current !== undefined) {
      if (cantidadBancos >= 3) {
        if (currentPosition !== 3) {
          animatedHeightBoxTresRef.current.style.height = "0px";
          animatedHeightBoxTresRef.current.parentElement.children[0].children[0].className =
            "ri-add-line";
        }
        refScrollUpBancos.current.scrollIntoView({ behavior: "smooth" });
      }
    }

    // EDIT ICON AT LEFT
    let element = e.target.parentElement.nextSibling;
    let newHeight =
      e.target.parentElement.nextSibling.children[0].children[0].clientHeight;

    if (element.clientHeight === 0) {
      element.style.height = newHeight + "px";
      e.target.parentElement.children[0].className = "ri-subtract-line";

      // ANIMATION TIME TO SET 100%
      setTimeout(() => {
        element.style.height = "100%";
      }, 1000);
    } else {
      element.style.height = "0px";
      e.target.parentElement.children[0].className = "ri-add-line";
    }
  };

  const eliminarOSPm = async (numOsEliminar) => {
    let idParticipante = null;
    let conyugeOsEliminar = false;
    let seccionEliminada = null;
    setLoading(true);
    switch (numOsEliminar) {
      case OS_UNO:
        if (datosOSUno?.datosOS?.length !== 0) {
          idParticipante = datosOSUno.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OBLIGADO_SOLIDARIO;
        break;
      case OS_DOS:
        if (datosOSDos?.datosOS?.length !== 0) {
          idParticipante = datosOSDos.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_DOS;
        break;
      case OS_TRES:
        if (datosOSCuatro?.datosOS?.length !== 0) {
          idParticipante = datosOSCuatro.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_CUATRO;
        break;
    }

    if (idParticipante != null) {
      let dataOs = {
        idCliente: modeloPm.idCliente,
        idPrincipalAccionista:
          modeloPm.principalAccionista.idPrincipalAccionista,
        idParticipante: idParticipante,
        tipoDeParticipanteId: TIPO_PARTICIPANTE_OS,
        numeroParticipante: numOsEliminar,
      };

      const response = await api_eliminar_os_solicitud_pm(dataOs);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        switch (numOsEliminar) {
          case OS_UNO:
            if (
              datosOSUno?.conyuge !== null &&
              datosOSUno?.conyuge?.idConyuge !== null &&
              datosOSUno?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSUno({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_DOS:
            if (
              datosOSDos?.conyuge != null &&
              datosOSDos?.conyuge?.idConyuge != null &&
              datosOSDos?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSDos({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_TRES:
            if (
              datosOSTres?.conyuge !== null &&
              datosOSTres?.conyuge?.idConyuge !== null &&
              datosOSTres?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSTres({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_CUATRO:
            if (
              datosOSCuatro?.conyuge !== null &&
              datosOSCuatro?.conyuge?.idConyuge !== null &&
              datosOSCuatro?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSCuatro({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
        }
        if (conyugeOsEliminar) {
          setCantidadConyugeOS(cantidadConyugeOS - 1);
        }
        setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Obligado solidario eliminado correctamente",
          confirmButtonText: "Si",
          confirmButtonColor: "#d33",
          showCloseButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            mostrarPestaniasAtrasPymePm(seccionEliminada);
          }
        });
      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al eliminar obligado solidario");
      }
    } else {
      setLoading(false);
      setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
      sweetAlertSuccess("Obligado solidario eliminado correctamente");
      mostrarPestaniasAtrasPymePm(seccionEliminada);
    }
  };

  const eliminarOSPfae = async (numOsEliminar) => {
    let idParticipante = null;
    let seccionEliminada = null;
    let conyugeOsEliminar = false;
    setLoading(true);
    switch (numOsEliminar) {
      case OS_UNO:
        if (datosOSUno?.datosOS?.length !== 0) {
          idParticipante = datosOSUno.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OBLIGADO_SOLIDARIO;
        break;
      case OS_DOS:
        if (datosOSDos?.datosOS?.length !== 0) {
          idParticipante = datosOSDos.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_DOS;
        break;
      case OS_TRES:
        if (datosOSTres?.datosOS?.length !== 0) {
          idParticipante = datosOSTres.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_TRES;
        break;
      case OS_CUATRO:
        if (datosOSCuatro?.datosOS?.length !== 0) {
          idParticipante = datosOSCuatro.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_CUATRO;
        break;
      case OS_CINCO:
        if (datosOSCinco?.datosOS?.length !== 0) {
          idParticipante = datosOSCinco.datosOS.idObligadoSolidario;
        }
        seccionEliminada = SECCION_OS_CINCO;
        break;
    }

    if (idParticipante != null) {
      let dataOs = {
        idCliente: modeloPfae.idCliente,
        idParticipante: idParticipante,
        tipoDeParticipanteId: TIPO_PARTICIPANTE_OS,
        numeroParticipante: numOsEliminar,
      };

      const response = await api_eliminar_os_solicitud_pfae(dataOs);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        switch (numOsEliminar) {
          case OS_UNO:
            if (
              datosOSUno?.conyuge !== null &&
              datosOSUno?.conyuge?.idConyuge !== null &&
              datosOSUno?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSUno({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_DOS:
            if (
              datosOSDos?.conyuge !== null &&
              datosOSDos?.conyuge?.idConyuge !== null &&
              datosOSDos?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSDos({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_TRES:
            if (
              datosOSTres?.conyuge !== null &&
              datosOSTres?.conyuge?.idConyuge !== null &&
              datosOSTres?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSTres({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_CUATRO:
            if (
              datosOSCuatro?.conyuge !== null &&
              datosOSCuatro?.conyuge?.idConyuge !== null &&
              datosOSCuatro?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSCuatro({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
          case OS_CINCO:
            if (
              datosOSCinco?.conyuge !== null &&
              datosOSCinco?.conyuge?.idConyuge !== null &&
              datosOSCinco?.conyuge?.generales?.esObligadoSolidario === true
            ) {
              conyugeOsEliminar = true;
            }
            setDatosOSCinco({
              datosOS: [],
              idTipoPersonaOs: "",
              conyuge: [],
              idEstadoCivilOs: "",
            });
            break;
        }
        if (conyugeOsEliminar) {
          setCantidadConyugeOS(cantidadConyugeOS - 1);
        }
        setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
        setLoading(false);
        const response = await sweetAlertObligadoSolidario()
        if(response){
          mostrarPestaniasAtrasPymePfae(seccionEliminada);

        }

      } else {
        setLoading(false);
        sweetAlertError("Ocurrió un error al eliminar obligado solidario");
      }
    } else {
      setLoading(false);
      setCantidadObligadosSolidarios(cantidadObligadosSolidarios - 1);
      sweetAlertSuccess("Obligado solidario eliminado correctamente");
      mostrarPestaniasAtrasPymePfae(seccionEliminada);
    }
  };

  const actualizarEmailCliente = async () => {
    try {
      setLoading(true);
      var emailClienteActual = tipoPersona === "PFAE" ? modeloPfae.generales?.correoElectronico : modeloPm?.generales?.correoElectronico;
      if (validarFormatoEmail(emailCliente)) {
        var actualizarEmail = false;
        if (emailCliente !== emailClienteActual) {
          actualizarEmail = true;
        }
        
        const datosCliente = {
          idCliente: tipoPersona === "PFAE" ? modeloPfae?.idCliente : modeloPm?.idCliente,
          idParticipante: tipoPersona === "PFAE" ? modeloPfae?.idCliente : modeloPm?.principalAccionista?.idPrincipalAccionista,
          correoElectronico: emailCliente,
          actualizarEmail: actualizarEmail,
        };
        const response = await api_actualizar_email_cliente(datosCliente);
        if (response.statusText === "OK" || response.status === STATUS_EXITO) {
          const data = response.data;
          if (data.success) {
            setShowResult(false);
            setShowFinAts(true);
          } else {
            setLoading(false);
            if (MENSAJE_ERROR_TOKEN_INVALIDO == data?.mensaje) {
              sweetAlertWithConfirmed(data?.mensaje, webView);
            } else {
              const mensajeError = mensajeMostrarUsuario(data.mensaje);
              sweetAlertError(mensajeError);
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
          sweetAlertError('Ocurrió un error al editar correo.');
          return false;
        }

      } else {
        setLoading(false);
        setEmailClienteError('Correo eléctronico inválido.');
      }

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const envioEmailCliente = async () => {
    try {
      const datosCliente = {
        idCliente: tipoPersona === "PFAE" ? modeloPfae?.idCliente : modeloPm?.idCliente
      };
      const response = await api_envio_email_cliente(datosCliente);
      if (response.statusText === "OK" || response.status === STATUS_EXITO) {
        const data = response.data;
        if (data.success) {
          setLoading(false);
          setShowResult(false);  
          setShowFinAts(true); 
        } else {
          setLoading(false);
          setShowResult(false);       
          const mensajeError = mensajeMostrarUsuario(data.mensaje);
          sweetAlertError(mensajeError);
        }
      } else {
        setLoading(false);
        setShowResult(false);  
        sweetAlertError('Ocurrió un error al enviar correo.');
        return false;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div ref={refScrollUp} className="pt-3 brian" />
      <Popup show={showFinAts}>
        <div className="bgc-gray-opacity popup--container">
          <div className="my-shadow radius bgc-white black popup--evaluacion_container__scroll">
            <center>
              <img src="/img/aprobado.png" className="evaluacion--logo" />             
              <br />
              <div className="evaluacion--texto_div">
                <p className="evaluacion--texto_aclaracion">
                  Si tienes alguna duda sobre tu solicitud por favor
                  comunícate al 800 8902580 con tu folio{" "}
                  <span className="evaluacion--texto_folio">
                    {resultadoEvaluacionRiesgos.folioSolicitud}
                  </span>
                </p>
              </div>
              <div className="evaluacion--button">
                <PrimaryButton
                  onClick={() => {
                    if (webView == true) {
                      window.location.replace("/cotizadorBanorte");
                    } else {
                      window.location.replace("/cotizador");
                    }
                  }}
                >
                  Siguiente
                </PrimaryButton>
              </div>
            </center>
          </div>
        </div>
        <div className="popup--shadow" />
      </Popup>

      <Popup show={showResult}>
        <div className="bgc-gray-opacity popup--container">
          <div className="my-shadow radius bgc-white black popup--evaluacion_container__scroll">
            <center>
              <div className="mensaje-evaluacion-usario-div">
                <p className="evaluacion--texto_agradecimientoAll">
                  <span className="evaluacion--texto_agradecimiento">Banorte agradece tu preferencia.</span> Nos encontramos evaluando tu solicitud para darte respuesta dentro de las próximas 24 horas hábiles.
                </p>
                <p className="evaluacion--texto_agradecimientoAll">
                  Te pedimos nos confirmes que tu correo sea correcto:
                </p> 
              </div>            
              <InputWithError
                label="Correo electrónico *"
                name="correoElectronicoCliente"
                value={emailCliente}
                onChange={(e) => {
                  setEmailCliente(e.target.value);
                  if(e.target.value.length > 0){
                    setEmailValido(true);
                  }else{
                    setEmailValido(false);
                  }          
                  if (emailCliente !== undefined) {
                    setEmailClienteError('');
                  }        
                }}
                type="email"
                InputProps={{ maxLength: 50 }}
                showErrorMessage={emailClienteError}
                errorMessage={emailClienteError}
              />
              <div className="evaluacion--button">
                {emailValido ? (
                  <PrimaryButton
                    onClick={(e) => {
                      actualizarEmailCliente();      
                    }}
                  >
                    Aceptar
                  </PrimaryButton>
                ) : (
                  <DisabledButton>Aceptar</DisabledButton>
                )}
              </div>
            </center>
          </div>
        </div>
        <div className="popup--shadow" />        
      </Popup>

      <Popup show={loading}>
        <div className="bgc-gray-opacity popup--container">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div className="popup--shadow" />
      </Popup>

      <Popup show={showTabla}>
        <div className="bgc-gray-opacity popup--container">
          <div className="my-shadow radius bgc-white black popup--container__scroll">
            <div className="d-flex justify-content-end gap-2">
              <img
                onClick={() => setShowTabla(false)}
                className="tbl-amortizacion--close"
                src={IconClose}
              ></img>
            </div>
            <h1>Tabla de amortización estimada</h1>
            <h4 className="text-start f-s-2">
              Calculada con una tasa de interés anual de referencia de 15.2%
            </h4>
            <hr />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Periodo</th>
                  <th scope="col">Saldo Capital</th>
                  <th scope="col">Intereses</th>
                  <th scope="col">Capital</th>
                  <th scope="col">Iva</th>
                  <th scope="col">Pago Total</th>
                </tr>
              </thead>
              <tbody>
                {tablaData.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <tr>
                        <td>{item.periodo}</td>
                        <td>{item.saldoCap}</td>
                        <td>{item.intereses}</td>
                        <td>{item.capital}</td>
                        <td>{item.iva}</td>
                        <td>{item.pagoTotal}</td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-end gap-2 my-3">
              <PrimaryButton
                onClick={() => {
                  setShowTabla(false);
                }}
              >
                Cerrar
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Popup>
      <StepProgressBar
        subMenuSelected={subMenuSelected}
        setSubMenuSelected={setSubMenuSelected}
        setFormSection={setFormSection}
        setSeccion={setSeccion}
        ultimoPasoUsuario={ultimoPasoUsuario}
        conyugeOS={conyugeOS}
        idTipoPersona={idTipoPersona}
      />

      {formSection !== null && (
        <h1 className="text-center form--title">{formSection}</h1>
      )}

      {seccion === SECCION_HISTORIAL_CREDITICIO && (
        <NavBarHistorialCrediticio folioSolicitud={folioSolicitud} />
      )}
      <div className="container form-resumen--container">
        <div className="formulario-layout layout--form">
          <>
            {seccion !== SECCION_ZERO && (
              <div className="resumenCrediticio--container">
                {seccion < 26 &&
                  plazo !== "" &&
                  plazo !== undefined &&
                  pago !== "0.0" && (
                    <ResumenCrediticio
                      folioSolicitud={folioSolicitud}
                      showBotonActualizar={showBotonActualizar}
                      credito={credito}
                      setCredito={setCredito}
                      validarLimiteHandler={validarLimiteHandler}
                      validarLimiteMesesHandler={validarLimiteMesesHandler}
                      handlePesosCotizador={handlePesosCotizador}
                      plazo={plazo}
                      pago={pago}
                      handleMensualidadCotizador={handleMensualidadCotizador}
                      setPlazo={setPlazo}
                      setShowBotonActualizar={setShowBotonActualizar}
                      setShowTabla={setShowTabla}
                      actualizarDatosResumen={actualizarDatosResumen}
                      idTipoPersona={idTipoPersona}
                    />
                  )}
                {seccion >= 23 && (
                  <div className="felicidades resumenCrediticio--felicidades" />
                )}
              </div>
            )}
            <div
              className={`${"formulario"} ${
                tipoPersona ? "outlined-formulario" : "non-outlined-formulario"
              } formulario-container`}
            >
              <FormMenu
                tipoPersona={tipoPersona}
                subMenuSelected={subMenuSelected}
                mostrarSeccionPfae={mostrarSeccionPfae}
                mostrarSeccionPm={mostrarSeccionPm}
                cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                cantidadConyugeOS={cantidadConyugeOS}
                datosOSCuatro={datosOSCuatro}
                datosOSDos={datosOSDos}
                datosOSTres={datosOSTres}
                datosOSUno={datosOSUno}
                seccion={seccion}
                setSeccion={setSeccion}
                eliminarOSPfae={eliminarOSPfae}
                eliminarOSPm={eliminarOSPm}
                setSubMenuSelected={setSubMenuSelected}
                setCantidadObligadosSolidarios={setCantidadObligadosSolidarios}
                conyugeOS={conyugeOS}
                disabledBtnAgregarOs={disabledBtnAgregarOs}
                setDisabledBtnAgregarOs={setDisabledBtnAgregarOs}
              />
              {transitionZero(
                (style, visible) =>
                  visible && (
                    <animated.div style={style}>
                      <div>
                        <h2 className="f-s-4 text-center">
                          Un momento, estamos cargando tu información.
                        </h2>
                      </div>
                    </animated.div>
                  )
              )}
              {tipoPersona === "PM" && (
                <FormularioPersonaMoral
                  animated={animated}
                  transitionOne={transitionOne}
                  transitionTwo={transitionTwo}
                  transitionThree={transitionThree}
                  transitionFour={transitionFour}
                  transitionFive={transitionFive}
                  transitionSix={transitionSix}
                  transitionSeven={transitionSeven}
                  transitionEight={transitionEight}
                  transitionNine={transitionNine}
                  transitionTen={transitionTen}
                  transitionEleven={transitionEleven}
                  transitionTwelve={transitionTwelve}
                  transitionThirteen={transitionThirteen}
                  transitionFourtheen={transitionFourtheen}
                  transitionDiesiseis={transitionDiesiseis}
                  transitionDiesisiete={transitionDiesisiete}
                  transitionDiesiocho={transitionDiesiocho}
                  transitionDiecinueve={transitionDiecinueve}
                  transitionVeinte={transitionVeinte}
                  transitionVeintidos={transitionVeintidos}
                  transitionVeintitres={transitionVeintitres}
                  transitionBancoOne={transitionBancoOne}
                  transitionBancoTwo={transitionBancoTwo}
                  transitionBancoThree={transitionBancoThree}
                  animateHeight={animateHeight}
                  cantidadBancos={cantidadBancos}
                  handleAddBancos={handleAddBancos}
                  animatedHeightBoxUnoRef={animatedHeightBoxUnoRef}
                  animatedHeightBoxDosRef={animatedHeightBoxDosRef}
                  animatedHeightBoxTresRef={animatedHeightBoxTresRef}
                  refScrollUpBancos={refScrollUpBancos}
                  axiosData={axiosData}
                  modeloPm={modeloPm}
                  anios={anios}
                  cantidadSocios={cantidadSocios}
                  setCantidadSocios={setCantidadSocios}
                  aniosDomicilio={aniosDomicilio}
                  mesesDomicilo={mesesDomicilo}
                  handlePesos={handlePesos}
                  setShowResult={setShowResult}
                  tipoPersona={tipoPersona}
                  cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                  setCantidadObligadosSolidarios={
                    setCantidadObligadosSolidarios
                  }
                  setModeloPm={setModeloPm}
                  setTotalNominaMensual={setTotalNominaMensual}
                  setFolioSolicitud={setFolioSolicitud}
                  setStyleHr={setStyleHr}
                  setObligadosSolidariosInformacionBasica={
                    setObligadosSolidariosInformacionBasica
                  }
                  setImporteRentaFiscal={setImporteRentaFiscal}
                  setVentasAnuales={setVentasAnuales}
                  domicilioFiscalIgual={domicilioFiscalIgual}
                  setDomicilioFiscalIgual={setDomicilioFiscalIgual}
                  setRegresarCotizador={setRegresarCotizador}
                  regresarCotizador={regresarCotizador}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  datosOSUno={datosOSUno}
                  setDatosOSUno={setDatosOSUno}
                  datosOSDos={datosOSDos}
                  setDatosOSDos={setDatosOSDos}
                  datosOSTres={datosOSTres}
                  setDatosOSTres={setDatosOSTres}
                  datosOSCuatro={datosOSCuatro}
                  setDatosOSCuatro={setDatosOSCuatro}
                  setCantidadConyugeOS={setCantidadConyugeOS}
                  cantidadConyugeOS={cantidadConyugeOS}
                  setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                  setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
                  webView={webView}
                />
              )}
              {tipoPersona === "PFAE" && (
                <FormularioPFAE
                  animated={animated}
                  importeRentaFiscal={importeRentaFiscal}
                  totalNominaMensual={totalNominaMensual}
                  transitionOne={transitionOne}
                  transitionTwo={transitionTwo}
                  transitionThree={transitionThree}
                  transitionFour={transitionFour}
                  transitionFive={transitionFive}
                  transitionEight={transitionEight}
                  transitionNine={transitionNine}
                  transitionTen={transitionTen}
                  transitionEleven={transitionEleven}
                  transitionTwelve={transitionTwelve}
                  transitionThirteen={transitionThirteen}
                  transitionFourtheen={transitionFourtheen}
                  transitionFivetheen={transitionFivetheen}
                  transitionDiesiseis={transitionDiesiseis}
                  transitionDiesisiete={transitionDiesisiete}
                  transitionDiesiocho={transitionDiesiocho}
                  transitionVeinte={transitionVeinte}
                  transitionVeintidos={transitionVeintidos}
                  transitionBancoOne={transitionBancoOne}
                  transitionBancoTwo={transitionBancoTwo}
                  transitionBancoThree={transitionBancoThree}
                  animateHeight={animateHeight}
                  cantidadBancos={cantidadBancos}
                  handleAddBancos={handleAddBancos}
                  animatedHeightBoxUnoRef={animatedHeightBoxUnoRef}
                  animatedHeightBoxDosRef={animatedHeightBoxDosRef}
                  animatedHeightBoxTresRef={animatedHeightBoxTresRef}
                  refScrollUpBancos={refScrollUpBancos}
                  modeloPfae={modeloPfae}
                  axiosData={axiosData}
                  aniosSector={aniosSector}
                  anios={anios}
                  aniosDomicilio={aniosDomicilio}
                  mesesDomicilo={mesesDomicilo}
                  Swal={Swal}
                  setShowResult={setShowResult}
                  tipoPersona={tipoPersona}
                  cantidadObligadosSolidarios={cantidadObligadosSolidarios}
                  setModeloPfae={setModeloPfae}
                  setObligadosSolidariosInformacionBasica={
                    setObligadosSolidariosInformacionBasica
                  }
                  setStyleHr={setStyleHr}
                  setFolioSolicitud={setFolioSolicitud}
                  domicilioFiscalIgual={domicilioFiscalIgual}
                  setDomicilioFiscalIgual={setDomicilioFiscalIgual}
                  setLoading={setLoading}
                  handleSeccionPyme={handleSeccionPyme}
                  handleRegresarSeccionPyme={handleRegresarSeccionPyme}
                  datosOSUno={datosOSUno}
                  setDatosOSUno={setDatosOSUno}
                  datosOSDos={datosOSDos}
                  setDatosOSDos={setDatosOSDos}
                  datosOSTres={datosOSTres}
                  setDatosOSTres={setDatosOSTres}
                  datosOSCuatro={datosOSCuatro}
                  setDatosOSCuatro={setDatosOSCuatro}
                  datosOSCinco={datosOSCinco}
                  setDatosOSCinco={setDatosOSCinco}
                  setCantidadObligadosSolidarios={
                    setCantidadObligadosSolidarios
                  }
                  setCantidadConyugeOS={setCantidadConyugeOS}
                  cantidadConyugeOS={cantidadConyugeOS}
                  setUltimoPasoSolicitud={setUltimoPasoSolicitud}
                  setResultadoEvaluacionRiesgos={setResultadoEvaluacionRiesgos}
                  setConyugeOS={setConyugeOS}
                  conyugeOS={conyugeOS}
                  setSeccion={setSeccion}
                  webView={webView}
                />
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Formulario;
